import { ABILITY, ABILITY_NAMES, AttackType, Class } from '../Common/Constants'
import { Dice, DiceCollection } from './Dice'
import { Range } from './CharacterJSON/Range'
import { Character } from './Character'
import { CustomizationValues, CustomizationValueMap } from './CustomizationValues'
import { WeaponAttributes } from './WeaponAttributes'
import { Inventory, WeaponBehavior, InventoryProperties } from './CharacterJSON/Inventory'
import { WeaponProperty, knownMasteries } from './WeaponAttributes'

export class Weapon {
  id: number
  name: string

  attributes: WeaponAttributes = new WeaponAttributes()
  type: string
  customizedName?: string
  dealsDamage: boolean = true
  rarityString?: string

  damageType?: string

  diceCount?: number
  diceValue?: number
  baseDice: Dice
  bonusDamageDice?: DiceCollection | undefined
  bonusDamageCondition?: string

  versatileDice?: string

  magicBonus: number = 0
  toHitBonus: number = 0
  toHitOverride: number = 0
  damageMod: number = 0
  critDamageDice?: Dice
  attackStatIndex: number = 0
  range: Range
  canBeUsedAsMonkWeapon: boolean = false
  mastery?: WeaponProperty
  propertyNames?: string[]
  simulated: boolean = false

  constructor(character: Character, itemData: Inventory, customizations?: CustomizationValueMap) {
    const data = itemData.definition

    // TODO - then use the constructor to load attrs?
    this.name = data.name
    this.type = data.type
    this.attributes.isMonkWeapon = data.isMonkWeapon

    if (!customizations) customizations = {}
    // 2024 - not all weapons are marked properly
    // All Simple Melee weapons are now considered Monk weapons, as well as Martial Melee weapons with the Light property.
    // I don't see how to get this from the data, so I'm just going to hardcode Scimitar which seems to be wrong
    if (data.type === 'Scimitar') {
      this.attributes.isMonkWeapon = true
    }

    this.canBeUsedAsMonkWeapon = character.classLevel(Class.MONK) > 0 && this.attributes.isMonkWeapon

    // TODO custom name and rarity string code shared with ammunition
    this.id = itemData.id
    const customizationId = String(itemData.id)

    if (customizationId in customizations) {
      const customizationValues: CustomizationValues = customizations[customizationId]
      if (customizationValues.name) {
        this.customizedName = customizationValues.name
      }

      this.attributes.isCustomized = true
    }

    this.rarityString = data.rarity.toLowerCase().replace(' ', '')

    const weaponBehaviorsList: WeaponBehavior[] = data.weaponBehaviors
    const foundWeaponBehaviors = weaponBehaviorsList.find((behavior) => 'damage' in behavior)

    const weaponBehavior = (foundWeaponBehaviors ? foundWeaponBehaviors : data) as WeaponBehaviorOrInventoryCommon // Staff of power stores it somewhere different for some reason

    const damageData = weaponBehavior.damage
    this.damageType = weaponBehavior.damageType

    this.baseDice = Dice.flatAmountDie(0)
    if (damageData) {
      this.baseDice = new Dice(damageData)
    }

    if (this.name === 'Net') {
      this.dealsDamage = false
    }

    this.attributes.isMagical = data.magic
    this.attributes.isAttuned = itemData.isAttuned

    this.range = {
      origin: 'Weapon',
      range: weaponBehavior.range,
      longRange: weaponBehavior.longRange
    }

    if (this.attributes.isAttuned) {
      // Hack - assume the artificer made this for themself, and any weapon infusion is +1.
      // TODO - haven't dealt with enhanced weapon infusion yet, but this works for repeating shot
      const artificerLevel = character.classLevel(Class.ARTIFICER)
      if (artificerLevel >= 2) {
        this.magicBonus += 1
      }
    }

    if (data.attackType === 2) {
      // 2024 Ranged is no longer a weapon behavior property, it's just the attack type
      this.attributes.isRanged = true
    }

    for (const propData of weaponBehavior.properties) {
      const propName = propData.name as WeaponProperty
      this.attributes.properties.push(propName)

      if (knownMasteries.includes(propName)) this.attributes.weaponMastery = propName

      if (propName === WeaponProperty.Versatile) this.versatileDice = propData.notes
    }

    this.propertyNames = weaponBehavior.properties
      .filter((prop) => !knownMasteries.includes(prop.name as WeaponProperty))
      .map((prop) => prop.name)

    const versatileProp = weaponBehavior.properties.find((prop) => prop.name === WeaponProperty.Versatile)
    if (versatileProp) {
      const versatileIndex = this.propertyNames.findIndex((name) => name === WeaponProperty.Versatile)
      if (versatileIndex !== -1) {
        this.propertyNames[versatileIndex] = `${WeaponProperty.Versatile} (${this.versatileDice})`
      }
    }

    const type = data.type ?? ''
    this.attributes.isPolearm = ['Glaive', 'Halberd', 'Quarterstaff', 'Spear'].includes(type)
    this.attributes.is2024Polearm =
      ['Quarterstaff', 'Spear'].includes(type) || (this.attributes.isHeavy() && this.attributes.isReach())

    this.attributes.isBow = ['Longbow', 'Shortbow'].includes(type)
    this.attributes.isCrossbow = type.includes('Crossbow')

    const grantedMods = data.grantedModifiers
    for (const grantedMod of grantedMods) {
      const type = grantedMod.type
      const subtype = grantedMod.subType
      if (type === 'bonus' && subtype === 'magic') {
        this.magicBonus += grantedMod.value
      }

      if (type === 'damage') {
        let bonusDice: Dice = Dice.flatAmountDie(0)

        if (grantedMod.dice) {
          bonusDice = new Dice(grantedMod.dice)
        } else if (grantedMod.fixedValue) {
          bonusDice = Dice.flatAmountDie(grantedMod.fixedValue)
        } else {
          console.warn('Could not find crit damage dice for weapon ' + this.name)
        }

        // Ugh, greatsword has no restriction.
        if (this.name.includes('Vicious') && bonusDice.diceString() === '2d6') {
          this.critDamageDice = Dice.flatAmountDie(7)
        } else if (grantedMod.restriction === '20 on the Attack Roll') {
          if (this.name.includes('Vicious') && bonusDice.diceString() === '2d6') {
            // json hands down 2d6, description says 7
            bonusDice = Dice.flatAmountDie(7)
          } else {
            bonusDice = new Dice(grantedMod.dice)
            console.warn(`${this.name} has unknown restriction for granted damage modifier: ${grantedMod.restriction}`)
          }

          this.critDamageDice = bonusDice ?? null
        } else if (grantedMod.restriction === '') {
          this.bonusDamageDice = new DiceCollection()
          this.bonusDamageDice.addDice(bonusDice)
        } else {
          const approvedRestrictions = [
            'While the sword is ablaze', // Flame Tongue
            'While Flaming', // Also Flame Tongue
            'Against Sworn Enemy', // Oath bow
            '20 on the Attack Roll, Not Construct or Undead' // Greatsword of Life Stealing
            // 'When you hit an Undead with this weapon', // Sun Blade
            // 'Against Undead Targets', // Sun Blade also
            // 'Power Strike - 1d6 per Charge' // Staff of Power
          ]
          if (approvedRestrictions.includes(grantedMod.restriction)) {
            this.bonusDamageCondition = grantedMod.restriction
            this.bonusDamageDice = new DiceCollection()
            this.bonusDamageDice.addDice(bonusDice)
          } else {
            console.warn(`${this.name} has unknown restriction for granted damage modifier: ${grantedMod.restriction}`)
          }
        }
      }
    }

    if (this.name.startsWith('Vorpal')) {
      this.critDamageDice = Dice.Create(6, 8)
    }

    // TODO I think this is a bad assumption, attackType: 2 seems to be ranged.
    // It just so happens to map to STR and DEX?
    const attackStat = data.attackType
    if (attackStat !== null) {
      this.attackStatIndex = attackStat - 1
    }

    if (this.attributes.isFinesse()) {
      if (character.dexterity() > character.strength()) {
        this.attackStatIndex = ABILITY_NAMES.indexOf(ABILITY.DEXTERITY)
      } else if (character.strength() > character.dexterity()) {
        this.attackStatIndex = ABILITY_NAMES.indexOf(ABILITY.STRENGTH)
      }
    }

    const customizationValues: CustomizationValues = customizations[customizationId]
    if (customizationValues && customizationValues.isCustomized()) {
      if (customizationValues.bonusDamage) {
        if (!this.bonusDamageDice) {
          this.bonusDamageDice = new DiceCollection()
        }
        this.bonusDamageDice.addDice(Dice.flatAmountDie(customizationValues.bonusDamage))
      }

      if (customizationValues.toHitOverride && customizationValues.toHitOverride !== 0) {
        this.toHitOverride = customizationValues.toHitOverride
      }
      this.toHitBonus += customizationValues.toHitBonus ?? 0
      this.attributes.isOffHand = customizationValues.isOffhand ?? false
      this.attributes.isPact = customizationValues.isPact ?? false
      this.attributes.isHex = customizationValues.isHex ?? false
    }

    if (
      this.attributes.isVersatile() &&
      !(character.hasOffHand() || character.hasShieldEquipped()) &&
      this.versatileDice !== undefined
    ) {
      if (this.versatileDice === '1d8') {
        this.baseDice = Dice.Create(1, 8)
      } else if (this.versatileDice === '1d10') {
        this.baseDice = Dice.Create(1, 10)
      } else if (this.versatileDice === '1d12') {
        this.baseDice = Dice.Create(1, 12)
      } else {
        console.error('Unknown versatile dice: ' + this.versatileDice)
      }
    }

    const monkLevel = character.classLevel(Class.MONK)

    if (data.isMonkWeapon && monkLevel > 0) {
      const monkDieSize = character.monkDieSize()
      const weaponDamageAverage = this.baseDice.avg()
      const monkDieAverage = (monkDieSize + 1) / 2
      if (monkDieAverage > weaponDamageAverage) {
        this.baseDice = Dice.Create(1, monkDieSize)
      }

      if (character.dexterity() > character.strength()) {
        this.attackStatIndex = ABILITY_NAMES.indexOf(ABILITY.DEXTERITY)
      }
    }

    if (this.type === 'Double-Bladed Scimitar') {
      this.range = Range.makeWeaponRange(5)
    }
  }

  // TODO - later just have a structure with attributes, then return the json blob
  weaponAttributes() {
    return {
      id: this.id,
      type: AttackType.WEAPON,
      weaponType: this.type,
      canBeUsedAsMonkWeapon: this.canBeUsedAsMonkWeapon,
      damageType: this.damageType,
      dealsDamage: this.dealsDamage, // Nets don't deal damage
      damageTypes: [this.damageType],
      critDamageDice: this.critDamageDice,
      range: this.range,
      requiresAttackRoll: true,
      isHexWeapon: this.attributes.isHex,
      isPactWeapon: this.attributes.isPact,
      isRanged: this.attributes.isRanged,
      isBow: this.attributes.isBow,
      isCrossbow: this.attributes.isCrossbow,
      isFinesse: this.attributes.isFinesse(),
      isHeavy: this.attributes.isHeavy(),
      isThrown: this.attributes.isThrown(),
      isOffHand: this.attributes.isOffHand,
      isLight: this.attributes.isLight(),
      isVersatile: this.attributes.isVersatile(),
      isTwoHanded: this.attributes.isTwoHanded(),
      isMonkWeapon: this.attributes.isMonkWeapon,
      isAmmunition: this.attributes.isAmmunition(),
      bonusDamageDice: this.bonusDamageDice,
      attackStatIndex: this.attackStatIndex,
      isCustomized: this.attributes.isCustomized,
      isMagical: this.attributes.isMagical,
      isAttuned: this.attributes.isAttuned,
      rarityString: this.rarityString,
      magicBonus: this.magicBonus,
      simulated: this.simulated
    }
  }
}

export interface WeaponBehaviorOrInventoryCommon {
  damage?: Dice
  damageType: string
  range: number
  longRange: number
  isMonkWeapon: boolean
  properties: InventoryProperties[]
}
