import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'
import { ABILITY } from '../../Common/Constants'

export class FeatureParserFightingStyle {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action, character } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.FightingStyle) return false

    only.weapon = true
    feature.defaultEnabled = true

    if (name === 'Great Weapon Fighting') {
      only.meleeWeapon = true
      only.twoHandedOrVersatile = true

      if (action.id === 1789148) {
        // 2024 version
        effects.minimumDamageDieRoll = 3
        feature.notes = 'Treat 1 or 2 as 3 for 2H/versatile weapons'
      } else {
        effects.rerollDamageDiceThreshold = 2
        feature.name = `${feature.name} (2014)`
        feature.notes = 'Reroll 1 or 2 dmg for 2H/versatile weapons'
      }
      return true
    }

    if (name === 'Unarmed Fighting') {
      // Handled by FeatureLoader.createUnarmedFightingFeatures
      return feature.skip()
    }

    // also create the (armed) version, and the :Grapple Damage version
    if (name.startsWith('Unarmed Fighting (')) {
      const isArmed = name.includes('(Armed)')
      const strMod = character.modifierForAbility(ABILITY.STRENGTH)
      const dieSize = isArmed ? 6 : 8
      only.unarmed = true

      only.weapon = false
      effects.additionalDamageOnHitDice = Dice.Create(1, dieSize, -1)
      feature.defaultEnabled = true
      feature.isUnarmedFightingDamage = true
      feature.notes = `Unarmed strikes deal 1d${dieSize} + ${strMod}`

      return true
    }

    if (name === 'Unarmed Fighting: Grapple Damage') {
      only.unarmed = true
      only.weapon = false
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 4)
      feature.defaultEnabled = false
      feature.notes = `At start of turn, +1d4 damage to a grappled creature`
      return true
    }

    if (name === 'Archery') {
      only.rangedWeapon = true
      effects.additionalToHitDice = Dice.flatAmountDie(2)
      return true
    }

    if (name === 'Dueling') {
      only.meleeWeapon = true
      only.singleWielding = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(2)
      feature.notes = '+2 dmg per hit for single one-handed weapon'
      return true
    }

    if (name === 'Thrown Weapon Fighting') {
      only.thrownWeapon = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(2)
      if (action.id !== 1789205 && action.id !== '1789205') {
        feature.name = `${feature.name} (2014)`
      }
      return true
    }

    if (name === 'Two-Weapon Fighting') {
      only.offHand = true
      only.lightWeapon = true
      effects.addWeaponStatModifier = true
      feature.notes = 'Add ability modifier to off-hand attack'
      feature.tooltip = `Two-Weapon Fighting requires you to have a weapon marked as "Dual Wield" in DDB.`
      return true
    }

    const nonBuffActions = ['Protection', 'Interception']
    if (nonBuffActions.includes(name)) return feature.skip()

    // parser.logUnknownAction()
    return false
  }
}
