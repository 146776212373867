import { Td, Tr, HStack } from '@chakra-ui/react'
import React from 'react'
import { ActionLevelMap } from '../../Common/Interfaces'
import { Dispatcher } from '../../Common/Types'
import { TableActionButton, RangeText } from '../../Common/UIComponents'
import { AttackAction } from '../../DDB/AttackAction'
import { ActionBox } from './ActionBox'

export function AttackRow({
  attack,
  actionIdList,
  setActionIdList,
  level,
  showRange,
  url
}: {
  attack: AttackAction
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  level: number
  showRange: boolean
  url?: string
}) {
  if (!attack) {
    return null
  }

  const { name, attributes } = attack
  const { range, id } = attributes
  const toHit = attack.attackModString()

  const toHitElement = toHit ? <Td>{toHit}</Td> : <Td />
  const damage = attack.diceStringForLevel(level)
  const spellLevel = attack.attributes.level
  const isCantrip = attack.attributes.isCantrip

  const handleAddButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const clickedId: number = parseInt(event.currentTarget.value)
    const newList: ActionLevelMap[] = [...actionIdList]
    const levelToAdd = isCantrip ? 0 : Math.max(level, spellLevel | 0)
    const newMap: ActionLevelMap = { actionId: clickedId, level: levelToAdd | 0 }
    newList.push(newMap)
    setActionIdList(newList)
  }

  return (
    <Tr fontSize={'medium'}>
      <Td whiteSpace="normal" wordBreak="break-word">
        <HStack spacing={0}>
          <TableActionButton id={id} value={id} add={true} onClick={handleAddButtonClick} />
          <ActionBox
            name={name}
            attributes={attributes}
            level={level}
            showLevel={false}
            url={url}
            ps={2}
            tooltip={attack.description}
            fontStyle={attack.simulated ? 'italic' : undefined}
          />
        </HStack>
      </Td>
      {showRange && (
        <Td>
          <RangeText range={range} />
        </Td>
      )}
      {toHitElement}
      <Td>{damage}</Td>
    </Tr>
  )
}
