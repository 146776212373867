import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'
import { ABILITY } from '../../Common/Constants'
import * as Parsers from './FeatureParsers'

export class FeatureParserClassOptions {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action, character } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.ClassOption) return false

    if (Parsers.Druid.parseClassOptions(parser)) return true
    if (Parsers.Warlock.parseClassOptions(parser)) return true

    if (name === 'Potent Spellcasting' && (action.id === 4496847 || action.id === '4496847')) {
      // 2024 Cleric version
      const wisdomMod = character.modifierForAbility(ABILITY.WISDOM)
      only.cantrip = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      feature.defaultEnabled = true
      feature.notes = `+${wisdomMod} dmg to any Cleric cantrip`
      return true
    }

    const nonBuffActions = [
      'Beast of the Land',
      'Divine Strike',
      'Colossus Slayer',
      'Potent Spellcasting',
      'Magician',
      'Law',
      'Fire Rune'
    ]
    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
