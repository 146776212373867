import React from 'react'
import { IconButton, HStack, Badge, Text, useNumberInput } from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Dispatcher } from '../../Common/Types'

export function LevelStepper({
  level,
  levelControls,
  highestLevelSlot,
  onLevelChange
}: {
  level: number
  levelControls: boolean
  highestLevelSlot: number
  onLevelChange: Dispatcher<number>
}) {
  const { value, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    value: level.toString(),
    min: 1,
    max: highestLevelSlot,
    precision: 0,
    onChange: (valueAsString, valueAsNumber) => {
      onLevelChange(valueAsNumber)
    }
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()

  return (
    <HStack>
      <Badge
        color="white"
        bgColor="brand"
        borderRadius="full"
        px={0}
        py={0}
        maxH="24px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {levelControls && (
          <IconButton
            {...dec}
            color="white"
            aria-label="Decrement"
            size="xs"
            bg="transparent"
            icon={<ChevronLeftIcon fontSize={'large'} />}
          />
        )}
        <Text px={levelControls ? 0 : 4}>Level {value}</Text>
        {levelControls && (
          <IconButton
            {...inc}
            color="white"
            aria-label="Increment"
            size="xs"
            bg="transparent"
            icon={<ChevronRightIcon fontSize={'large'} />}
          />
        )}
      </Badge>
    </HStack>
  )
}
