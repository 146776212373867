import React from 'react'
import { Box, HStack, Input, InputGroup, Spacer, VStack } from '@chakra-ui/react'
import { IconButton, Button, ButtonGroup } from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { DPRCalcLogo } from './CustomIcons'
import { handleCharacterButtonClick } from '../Common/Handlers'
import { useState } from 'react'
import { DISCORD_URL } from '../Common/Constants'
import { IconBrandDiscordFilled } from '@tabler/icons-react'

export enum DprTab {
  HOME,
  ABOUT,
  FAQ,
  CHARACTER
}

export function MainNavBar({ tab, body }: { tab: DprTab; body: React.ReactElement }) {
  const navigate = useNavigate()
  const [urlFieldValue, setUrlFieldValue] = useState('')
  function handleButtonClick() {
    handleCharacterButtonClick(navigate, urlFieldValue)
  }

  return (
    <VStack width="100%">
      <Box borderBottomWidth="1px" bg="bg.surface" position="relative" width="100%" py={{ base: 2, md: 4 }} px={4}>
        <HStack justify="space-between" spacing="8">
          <HStack spacing="8">
            <HStack spacing="3">
              {/* <MobileDrawer /> */}
              <DPRCalcLogo />
            </HStack>
            <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing="6" display={{ base: 'flex', md: 'flex' }}>
              <Button
                isActive={tab === DprTab.HOME}
                onClick={() => {
                  navigate('/')
                }}
              >
                Home
              </Button>
              <Button
                justifyContent="center"
                isActive={tab === DprTab.FAQ}
                onClick={() => {
                  navigate('/faq')
                }}
              >
                FAQ
              </Button>
              <Button
                leftIcon={<IconBrandDiscordFilled />}
                onClick={() => {
                  window.open(DISCORD_URL, '_blank')
                }}
              >
                Discord
              </Button>
            </ButtonGroup>
          </HStack>
          <Spacer />
          <HStack display={{ base: 'none', md: 'inline-flex' }}>
            <InputGroup maxW="2xs">
              <Input
                id="topnav-input"
                placeholder="DDB URL"
                size={'lg'}
                maxW={'xl'}
                value={urlFieldValue}
                onChange={(event) => {
                  setUrlFieldValue(event.target.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleButtonClick()
                  }
                }}
              />
            </InputGroup>
            <ButtonGroup variant="tertiary" spacing="1">
              <IconButton icon={<FiSearch />} aria-label="Search" isRound onClick={handleButtonClick} />
            </ButtonGroup>
            {/* <Avatar boxSize="10" src="https://i.pravatar.cc/300" /> */}
          </HStack>
        </HStack>
      </Box>

      <Box width="100%">{body}</Box>
    </VStack>
  )
}
