import { Json } from '../../Common/Types'
import { Activation } from './Activation'

export class Feat {
  componentId: number
  componentTypeId: number
  definitionId: number
  definition: FeatDefinition
  friendlySubtypeName?: string

  constructor(json: Json) {
    this.componentId = json.componentId
    this.componentTypeId = json.componentTypeId
    this.definitionId = json.definitionId
    this.friendlySubtypeName = json.friendlySubtypeName
    this.definition = new FeatDefinition(json.definition)
  }
}

export class FeatCategory {
  tagName: string
  id: number

  constructor(json: Json) {
    this.tagName = json.tagName
    this.id = json.id
  }
}

export class FeatDefinition {
  id: number
  activation: Activation
  entityTypeId: number
  name: string
  categories: FeatCategory[]
  type: string
  subType: string

  constructor(json: Json) {
    this.id = json.id
    this.activation = new Activation(json.activation)
    this.entityTypeId = json.entityTypeId
    this.name = json.name
    this.categories = json.categories.map((category: Json) => new FeatCategory(category))
    this.type = json.type
    this.subType = json.subType
  }
}

export class GrantedFeat {
  id: number
  name: string
  featIds: number[]

  constructor(json: Json) {
    this.name = json.name
    this.id = json.id
    this.featIds = json.featIds
  }
}
