export enum WeaponProperty {
  TwoHanded = 'Two-Handed',
  Heavy = 'Heavy',
  Range = 'Range',
  Finesse = 'Finesse',
  Versatile = 'Versatile',
  Light = 'Light',
  Thrown = 'Thrown',
  Ammunition = 'Ammunition',
  Loading = 'Loading',
  AmmunitionFirearms = 'Ammunition (Firearms)',
  Special = 'Special',
  Reach = 'Reach',
  Nick = 'Nick',
  Vex = 'Vex',
  Cleave = 'Cleave',
  Graze = 'Graze',
  Push = 'Push',
  Sap = 'Sap',
  Topple = 'Topple',
  Slow = 'Slow'
}

// A bit clumsy…
export const knownMasteries = [
  WeaponProperty.Reach,
  WeaponProperty.Nick,
  WeaponProperty.Vex,
  WeaponProperty.Cleave,
  WeaponProperty.Graze,
  WeaponProperty.Push,
  WeaponProperty.Sap,
  WeaponProperty.Topple,
  WeaponProperty.Slow
]

export class WeaponAttributes {
  isPolearm: boolean = false
  is2024Polearm: boolean = false // Quarterstaff, a Spear, or a weapon that has the Heavy and Reach properties
  isBow: boolean = false
  isCrossbow: boolean = false
  isRanged: boolean = false
  isOffHand: boolean = false
  isAttuned: boolean = false
  isHex: boolean = false
  isPact: boolean = false
  isCustomized: boolean = false
  isMonkWeapon: boolean = false
  isMagical: boolean = false

  weaponMastery?: WeaponProperty
  properties: WeaponProperty[] = []

  isTwoHanded(): boolean {
    return this.properties.includes(WeaponProperty.TwoHanded)
  }

  isHeavy(): boolean {
    return this.properties.includes(WeaponProperty.Heavy)
  }

  isRange(): boolean {
    return this.properties.includes(WeaponProperty.Range)
  }

  isFinesse(): boolean {
    return this.properties.includes(WeaponProperty.Finesse)
  }

  isVersatile(): boolean {
    return this.properties.includes(WeaponProperty.Versatile)
  }

  isLight(): boolean {
    return this.properties.includes(WeaponProperty.Light)
  }

  isThrown(): boolean {
    return this.properties.includes(WeaponProperty.Thrown)
  }

  isReach(): boolean {
    return this.properties.includes(WeaponProperty.Reach)
  }

  isNick(): boolean {
    return this.properties.includes(WeaponProperty.Nick)
  }

  isVex(): boolean {
    return this.properties.includes(WeaponProperty.Vex)
  }

  isCleave(): boolean {
    return this.properties.includes(WeaponProperty.Cleave)
  }

  isGraze(): boolean {
    return this.properties.includes(WeaponProperty.Graze)
  }

  isPush(): boolean {
    return this.properties.includes(WeaponProperty.Push)
  }

  isSap(): boolean {
    return this.properties.includes(WeaponProperty.Sap)
  }

  isTopple(): boolean {
    return this.properties.includes(WeaponProperty.Topple)
  }

  isSlow(): boolean {
    return this.properties.includes(WeaponProperty.Slow)
  }

  isAmmunition(): boolean {
    return this.properties.includes(WeaponProperty.Ammunition)
  }

  isLoading(): boolean {
    return this.properties.includes(WeaponProperty.Loading)
  }

  isAmmunitionFirearms(): boolean {
    return this.properties.includes(WeaponProperty.AmmunitionFirearms)
  }

  isSpecial(): boolean {
    return this.properties.includes(WeaponProperty.Special)
  }
}
