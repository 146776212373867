import React from 'react'
import { HStack, Box, VStack, Flex, Link, Text, Badge } from '@chakra-ui/react'
import { Dictionary } from '../../Common/Types'
import { DiamondIcon } from '../CustomIcons'
import { IconTooltip } from '../../Common/UIComponents'

export function ActionBox({
  name,
  attributes,
  level,
  showLevel,
  url,
  activeWeaponMasteries,
  ps,
  tooltip,
  fontStyle
}: {
  name: string
  attributes: Dictionary
  level: number
  showLevel: boolean
  url?: string
  activeWeaponMasteries?: string[] | undefined
  ps?: number
  tooltip?: string
  fontStyle?: string
}) {
  const { requiresConcentration, ritual, effectCountLabel, effectCountsForLevels } = attributes
  let effectCount = attributes.effectCount
  if (!attributes.isCantrip && effectCountsForLevels && effectCountsForLevels.length > 0) {
    effectCount = effectCountsForLevels[Math.max(0, level - 1)]
  }

  const { isPactWeapon, isHexWeapon, isMonkWeapon, weaponMastery, weaponType, canBeUsedAsMonkWeapon } = attributes
  const masteryStar = activeWeaponMasteries && activeWeaponMasteries.includes(weaponType) ? '★' : '☆'

  const paddingStart = ps ? ps : 0
  return (
    <HStack>
      <Box display="flex" alignItems="center" ps={paddingStart}>
        <VStack align="left">
          <Flex wrap="wrap" gap="1" alignItems="center">
            {url ? (
              <Link href={url} isExternal textDecoration="underline">
                {name}
              </Link>
            ) : (
              <Text textAlign="left" variant={attributes.rarityString} fontStyle={fontStyle}>
                {name}
                {tooltip && <IconTooltip tooltip={tooltip} />}
              </Text>
            )}

            {requiresConcentration && <DiamondIcon letter="C" />}

            {weaponMastery && <Text>{masteryStar}</Text>}

            {ritual && <DiamondIcon letter="R" />}
            {showLevel && (
              <Badge
                bgColor="brand"
                color="white"
                borderRadius="full"
                px={2}
                py={0}
                maxH={'20px'}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                L{level}
              </Badge>
            )}
          </Flex>
          {effectCount > 1 && (
            <Text fontSize="xs" marginTop="-4px">
              {effectCountLabel}: {effectCount}
            </Text>
          )}
          {isPactWeapon && isHexWeapon && (
            <Text fontSize="xs" marginTop="-4px">
              Pact weapon, Hex weapon
            </Text>
          )}
          {isPactWeapon && !isHexWeapon && (
            <Text fontSize="xs" marginTop="-4px">
              Pact weapon
            </Text>
          )}
          {isHexWeapon && !isPactWeapon && (
            <Text fontSize="xs" marginTop="-4px">
              Hex weapon
            </Text>
          )}
          {isMonkWeapon && canBeUsedAsMonkWeapon && (
            <Text fontSize="xs" marginTop="-4px">
              Monk Weapon
            </Text>
          )}
        </VStack>
      </Box>
    </HStack>
  )
}
