import React, { useState } from 'react'
import { useToast, IconButton, Tooltip } from '@chakra-ui/react'
import { Dictionary } from '../Common/Types'
import { StatUpArrow } from '@chakra-ui/icons'

export const CacheCharacterButton = ({ id, data }: { id: string; data: Dictionary }) => {
  const [loading, setLoading] = useState(false) // Button loading state
  const toast = useToast() // Chakra UI toast

  console.log('caching', id)

  // Handle API call
  const handleShare = async () => {
    setLoading(true)
    try {
      const response = await fetch('/api/cache-character', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, data })
      })

      if (!response.ok) {
        throw new Error('Failed to save data')
      }

      const result = await response.json()

      await navigator.clipboard.writeText(result.path)

      toast({
        title: 'Character cached',
        description: `${result.path}`,
        status: 'success',
        colorScheme: 'gray',
        position: 'top',
        duration: 3000,
        isClosable: true
      })
    } catch (err) {
      toast({
        title: 'Error caching character',
        description: (err as Error).message,
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip label={loading ? 'Caching character…' : 'Cache character'}>
      <IconButton
        isLoading={loading}
        isDisabled={loading}
        spinner={<StatUpArrow boxSize={5} ms={1} />}
        color={'white'}
        top={0}
        right={0}
        id={'cache'}
        aria-label="Cache character"
        onClick={handleShare}
        icon={<StatUpArrow boxSize={5} ms={1} />}
      />
    </Tooltip>
  )
}
