import { Json } from '../../Common/Types'

export class CharacterValues {
  typeId: number
  value: number | string | boolean
  valueId: string

  constructor(json: Json) {
    this.typeId = json.typeId
    this.value = json.value
    this.valueId = json.valueId
  }
}
