import React from 'react'
import {
  Container,
  Heading,
  Stack,
  VStack,
  Box,
  Text,
  ListItem,
  UnorderedList,
  HStack,
  Link,
  Image
} from '@chakra-ui/react'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Code } from '@chakra-ui/react'
import { TrackPageView, PAGE_FAQ } from '../Common/AppAnalytics'
import { DISCORD_URL } from '../Common/Constants'

const accentColor = 'blue.200'

function FAQList({ items }: { items: string[] }) {
  return (
    <UnorderedList>
      {items.map((item) => (
        <ListItem key={item}>{item}</ListItem>
      ))}
    </UnorderedList>
  )
}

function FAQSection({ title, content }: { title: string; content: React.ReactNode }) {
  return (
    <AccordionItem>
      <AccordionButton fontSize={'xl'}>
        <Box as="span" flex="1" textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel pb={4} fontSize={'md'}>
        <HStack>
          <Box px={8}>{content}</Box>
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

export function FAQPage() {
  const title = 'Frequently Asked Questions'
  const description = ''

  TrackPageView(PAGE_FAQ)

  const element = (
    <Container py={{ base: '8', md: '12' }}>
      <VStack>
        <Stack>
          <Heading size={{ base: 'sm', md: 'md' }} color={accentColor}>
            {title}
          </Heading>
        </Stack>
        <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
          {description}
        </Text>

        <Accordion allowMultiple width="100%">
          <FAQSection
            title="What is this for?"
            content="This is a tool that helps you understand your character better, specifically through the lens of dealing damage. 
                For new players, or even seasoned ones, it can often be intimidating to understand all of the features of a character and how they interact.
                It can also sometimes be unintuitive to know which feat, stat increase, choice of equipment, fighting style would be most impactful.
                This tool lets you quickly try out various builds and options and simulate what sort of damage you can do with each."
          />
          <FAQSection
            title="What are the currently known issues?"
            content={
              <FAQList
                items={[
                  "Don't support most reaction skills",
                  "Doesn't yet show customized spell names",
                  "Doesn't support creature multi-attack, and sometimes can't parse more complex attacks",
                  "Doesn't yet support all magical item effects (but does support many)",
                  'With high level builds, features with limited resources like superiority dice can be used more than the maximum available',
                  'And probably a whole lot more :)'
                ]}
              />
            }
          />
          <FAQSection
            title="What are some examples of things I can do with this?"
            content={
              <VStack align="left">
                <Text>Ultimately, this helps answer all those burning questions you may have. For example:</Text>
                <FAQList
                  items={[
                    'Is Flames of Phlegethos is worth it for my Tiefling Sorcerer?',
                    'Should my Rogue take the Duel Wielder or Piercer feat?',
                    'Will +2 STR or Great Weapon Fighting be more impactful?',
                    'How often will I completely miss with two Sharpshooter attacks per turn?',
                    'I have a level 4 spell slot, which spell will do most single target damage when upcast?',

                    'How likely am I to hit that AC 20 enemy with Elven Accuracy versus without?'
                  ]}
                />
              </VStack>
            }
          />
          <FAQSection title="Is this open source?" content="Not yet!" />
          <FAQSection
            title="Is this mathematically accurate?"
            content={
              <Box>
                <Text>
                  It should be for any given single attack, but it&apos;s *not* 100% accurate for full turns in certain
                  circumstances.
                </Text>
                <Text>
                  For example, if you have an effect like sneak attack, which could trigger on the first or second
                  attack, this tool will under-estimate the damage. This is because this tool creates a series of
                  actions that make up a turn, then creates a formula for that specific action which is used to
                  determine damage probabilities and averages. Each action is entirely independent from the previous, so
                  it can&apos;t know if you hit or missed.
                </Text>
                <Text>
                  If you are looking for something more mathematically rigorous, you can check out{' '}
                  <Link
                    color={accentColor}
                    href="https://docs.google.com/spreadsheets/d/14WlZE_UKwn3Vhv4i8ewVOc-f2-A7tMW_VRum_p3YNHQ/edit#gid=151780215"
                    isExternal
                  >
                    LudicSavant and AureusFulgen&apos;s DPR Calculator
                  </Link>{' '}
                  and the{' '}
                  <Link
                    color={accentColor}
                    href="https://docs.google.com/document/d/11eTMZPPxWXHY0rQEhK1msO-40BcCGrzArSl4GX4CiJE/edit#heading=h.5qcgsqvtvf8v"
                    isExternal
                  >
                    math behind it all! Initial tests show DPRcalc matches their tools results for basic attacks, but I
                    haven&apos;t done a comprehensive comparison yet.
                  </Link>
                </Text>
              </Box>
            }
          />
          <FAQSection
            title="How do I see the math behind the calculations?"
            content={
              <Text>
                You can see the breakdown of the dice math if you hover over the average numbers in the actions table in
                the top left. You can even tap it to copy it, then try it out yourself at{' '}
                <Link color={accentColor} href="https://dice.clockworkmod.com/" isExternal>
                  dice.clockworkmod.com
                </Link>
                .
              </Text>
            }
          />
          <FAQSection
            title="Does this support every class feature, feat, racial trait, etc?"
            content="Not yet. Much of the logic has been manually coded and I'm sure I've missed a ton of them. 
                    There will also undoubtedly be a handful that just won't work in this model."
          />
          <FAQSection
            title="Do you use any open source libraries?"
            content={
              <VStack>
                <Text>
                  Yes! This couldn&apos;t be possible without the amazing{' '}
                  <Link color={accentColor} href="http://dice.clockworkmod.com/" isExternal>
                    http://dice.clockworkmod.com/
                  </Link>{' '}
                  , which was the inspiration for this whole site. This uses an unmodified copy of{' '}
                  <Link color={accentColor} href="https://github.com/koush/dice.clockworkmod.com" isExternal>
                    https://github.com/koush/dice.clockworkmod.com
                  </Link>{' '}
                  which is licensed under the Apache 2.0 License.
                </Text>
                <Code p={2} colorScheme="gray">
                  Copyright 2013 Koushik Dutta (2013) Licensed under the Apache License, Version 2.0 (the
                  &quot;License&quot;); you may not use this file except in compliance with the License. You may obtain
                  a copy of the License at http://www.apache.org/licenses/LICENSE-2.0 Unless required by applicable law
                  or agreed to in writing, software distributed under the License is distributed on an &quot;AS IS&quot;
                  BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for
                  the specific language governing permissions and limitations under the License.
                </Code>
              </VStack>
            }
          />
          <FAQSection
            title="How can I remove my character from this site?"
            content="Mark your character as private or 'campaign only' on D&D Beyond. This site can only pull public characters."
          />

          <FAQSection
            title="Does this store my character on your servers?"
            content="No, this site does not store any character data at all, all the caching happens client-side (on your computer)."
          />

          <FAQSection
            title="What features are coming in the future?"
            content="Lots of charts, power attack calculators, party-views, auto-optimizer button, and more. It depends on what users are interested in."
          />

          <FAQSection
            title="Where do I send feedback, feature requests, bug reports, or hate mail? Do you have a Discord?"
            content={
              <Text>
                Yep, you can{' '}
                <Link color={accentColor} target="_blank" href={DISCORD_URL}>
                  join the official Discord
                </Link>
                .
              </Text>
            }
          />

          <FAQSection
            title="This is great, can I buy you a coffee?"
            content={
              <HStack alignContent={'left'}>
                <Text>Sure, why not? I&apos;m taking time off from the 9-5 grind so every little bit helps.</Text>
                <Link href="https://ko-fi.com/M4M5YHTAV" target="_blank" isExternal>
                  <Image
                    height={'36px'}
                    src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
                    alt="Buy Me a Coffee at ko-fi.com"
                  />
                </Link>
              </HStack>
            }
          />
        </Accordion>
      </VStack>
    </Container>
  )

  return <MainNavBar tab={DprTab.FAQ} body={element} />
}
