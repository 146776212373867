import { Dice } from '../Dice'
import { Class } from '../../Common/Constants'
import { SneakAttack } from './FeatureParsers'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserRogue {
  static parse(parser: FeatureParserBase) {
    const thisClass = Class.ROGUE
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)

    if (SneakAttack.parse(parser)) return true

    if (name === 'Eye for Weakness') {
      effects.additionalDamageOnHitDice = Dice.Create(3, 6)
      feature.notes = 'Additional 3d6 dmg on sneak attack'
      only.sneakAttack = true
      return true
    }

    if (name === 'Steady Aim') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.advantage = true
      return true
    }

    if (name === 'Ambush Master') {
      effects.advantage = true
      return true
    }

    if (name === 'Psychic Blades') {
      // This is converted to an action
      return feature.skip()
    }

    if (name === 'Psychic Blades: Homing Strikes') {
      // 2024 Soulknife
      const energieDie = character.soulknifeEnergyDieSize(thisClass)
      effects.additionalToHitDice = Dice.Create(1, energieDie)
      feature.usesLimitedResource = true
      only.psychicBlade = true
      return true
    }

    if (name === 'Soul Blades: Homing Strikes') {
      const energieDie = character.psionicEnergyDieSize(thisClass)
      effects.additionalToHitDice = Dice.Create(1, energieDie)
      feature.usesLimitedResource = true
      return true
    }

    // 2014 Assassinate
    if (name === 'Assassinate') {
      if (action.id === 10292916) return feature.skip()

      only.weapon = true
      effects.advantage = true
      return true
    }

    if (name === 'Assassinate: Surprised') {
      // http://localhost:3000/character/127674273?features=1%2C42%2C89%2C133%2C360%2C1021%2C1118%2C1131%2C53484%2C71185%2C1306490%2C2438252&actions=1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0%2C1453258212%2C0&overrides=%7B%7D&ac=23
      only.weapon = true
      effects.autoCrit = true
      feature.notes = 'Attacks against surprised creatures are crits'
      return true
    }

    if (name === `Thief’s Reflexes`) {
      if (requiredLevel) return feature.skip()
      effects.doubleDamage = true
      feature.notes = 'Two turns during first round of combat (this just doubles dpr)'
      return true
    }

    if (name === 'Stroke of Luck') {
      if (requiredLevel) feature.skip()
      only.attackRoll = true
      only.oncePerTurn = true
      effects.autoHit = true
      feature.notes = 'Next attack roll automatically hits'
      return true
    }

    if (name === 'Psychic Blades: Rend Mind') {
      only.psychicBlade = true
      only.oncePerTurn = true
      effects.nextPsychicBladeAttacksHaveAdvantage = true
      feature.notes = 'Psychic Blades stun on failed Wis save'
      return true
    }

    if (name === 'Vex (Psychic Blades)') {
      only.psychicBlade = true
      effects.nextPsychicBladeAttackHasAdvantage = true
      feature.defaultEnabled = true
      feature.notes = 'Psychic Blades get the Vex weapon mastery'
      return true
    }

    if (name === 'Wails from the Grave' || name === 'Tokens of the Departed: Sneak Attack') {
      if (isSubclassFeature) return feature.skip()
      if (name === 'Tokens of the Departed: Sneak Attack') {
        feature.name = 'Tokens of the Departed'
      }
      // Death’s Friend

      const sneakAttackDiceCount = Math.ceil(classLevel / 2)
      const diceCount = classLevel >= 17 ? sneakAttackDiceCount : Math.ceil(sneakAttackDiceCount / 2)
      only.finesseWeapon = true
      effects.additionalDamageOnHitDice = Dice.Create(diceCount, 6)
      if (classLevel >= 17) {
        feature.name = `${feature.name} (Death's Friend)`
        const halfDice = Dice.Create(Math.ceil(sneakAttackDiceCount / 2), 6)
        feature.notes = `+${halfDice.diceString()} dmg to first and second target`
      } else {
        feature.notes = `+${effects.additionalDamageOnHitDice!.diceString()} necrotic dmg to second target`
      }

      return true
    }

    if (name === 'Sudden Strike') {
      if (isSubclassFeature) return feature.skip()
      effects.extraBonusAttacksThisAction = 1
      feature.name = 'Sudden Strike (TODO)'
      // Todo: Also can sneak ttack on one bonus action attack
      return true
    }

    if (name === 'Versatile Trickster') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.advantage = true
      // TODO - uses a bonus action
      return true
    }

    if (name === 'Master Duelist') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.advantage = true
      feature.usesLimitedResource = true
      feature.notes = 'If you miss an attack, roll again with advantage'
      return true
    }

    const nonBuffActions = [
      '10: Ability Score Improvement',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      '6: Expertise',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Assassin’s Tools',
      'Blindsense',
      'Core Rogue Traits',
      'Cunning Action',
      'Cunning Strike',
      'Death Strike',
      'Devious Strikes',
      'Elegant Maneuver',
      'Elusive',
      'Envenom Weapons',
      'Epic Boon',
      'Equipment',
      'Evasion',
      'Expertise',
      'Fancy Footwork',
      'Fast Hands',
      'Fast Hands: Sleight of Hand',
      'Fast Hands: Use Magic Item',
      'Fast Hands: Utilize',
      'Hit Points',
      'Improved Cunning Strike',
      'Infiltration Expertise',
      'Masterful Mimicry',
      'Panache',
      'Proficiencies',
      'Psionic Power',
      'Psionic Power: Psi-Bolstered Knack',
      'Psionic Power: Psionic Energy',
      'Psionic Power: Psionic Energy Dice',
      'Psionic Power: Psychic Whispers',
      'Psionic Power: Recovery',
      'Psychic Blades: Attack (DEX)',
      'Psychic Blades: Attack (STR)',
      'Psychic Blades: Bonus Attack (DEX)',
      'Psychic Blades: Bonus Attack (STR)',
      'Psychic Teleportation',
      'Psychic Veil',
      'Rakish Audacity',
      'Reliable Talent',
      'Rend Mind',
      'Rogue Subclass',
      'Roguish Archetype',
      'Second-Story Work',
      'Slippery Mind',
      'Spellcasting',
      'Soul Blades',
      'Soul Blades: Psychic Teleportation',
      'Steady Aim: Roving Aim',
      'Supreme Sneak',
      "Thieves' Cant",
      'Thieves’ Cant',
      'Uncanny Dodge',
      'Use Magic Device',
      'Use Magic Device: Charges',
      'Use Magic Device: Scroll',
      'Weapon Mastery'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
