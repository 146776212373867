import { Container, Heading, HStack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { CheckCircleIcon, CloseIcon, SpinnerIcon } from '@chakra-ui/icons'
import { Utility } from '../Common/Utility'
import { TurnEngine } from '../Data/TurnEngine'
import { sumDamageArrays } from '../Data/ChartData'
import { Link } from '@chakra-ui/react'
import { LoadDPRTestSlugs } from '../Test/DPRTestData'
import { CharacterLoader } from '../Loader/CharacterLoader'
import { Character } from '../DDB/Character'
// import { FeatureParserBase } from '../DDB/FeatureParsers/FeatureParserBase'
// import { NON_BUFF_ACTIONS } from '../Common/Constants'

enum DPRStatus {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  PASS = 'PASS',
  FAIL = 'FAIL'
}

export function TestPage() {
  const forceReload = false
  const [testResults, setTestResults] = useState<(CharacterTestResult | null)[]>([])

  const timeSinceStart = 0
  useEffect(() => {
    async function loadCharacter(cid: string, reloadCache: boolean): Promise<Character | null> {
      return await CharacterLoader.load(cid, reloadCache)
    }

    const testSlugs = LoadDPRTestSlugs()
    const loadCharacters = () => {
      // Initialize array with loading placeholders
      setTestResults(testSlugs.map((slug, index) => new CharacterTestResult(slug, undefined, index)))

      // Load each character independently
      testSlugs.forEach(async (slug, index) => {
        try {
          const character = await loadCharacter(slug, forceReload)
          if (!character) {
            console.log(`Could not load ${slug}`)
            setTestResults((prev) => {
              const newResults = [...prev]
              newResults[index] = new CharacterTestResult(slug, undefined, index, true)
              return newResults
            })
          } else {
            setTestResults((prev) => {
              const newResults = [...prev]
              newResults[index] = new CharacterTestResult(slug, character, index)
              return newResults
            })

            // if (index === testSlugs.length - 1) {
            //   const strings = FeatureParserBase.logNonBuffClassFeatureMap()
            //   const nonBuffActions = NON_BUFF_ACTIONS.filter((action) => !strings.includes(action))
            //   console.log('total actions:', NON_BUFF_ACTIONS.length)
            //   console.log('Non-buff actions:', strings.length)
            //   console.log('remaining actions:', nonBuffActions.length)
            //   console.log(nonBuffActions)
            // }
          }
        } catch (error) {
          setTestResults((prev) => {
            const newResults = [...prev]
            newResults[index] = new CharacterTestResult(slug, undefined, index, true)
            return newResults
          })
        }
      })
    }

    loadCharacters()
  }, [forceReload])

  const currentResults: CharacterTestResult[] = testResults.filter((result) => result !== null) as CharacterTestResult[]
  const completedTestResults: CharacterTestResult[] = currentResults.filter(
    (result) => result!.DPRStatus() !== DPRStatus.LOADING
  )

  const sortedTestResults = currentResults.sort((a, b) => {
    const statusOrder = {
      [DPRStatus.ERROR]: 0,
      [DPRStatus.FAIL]: 1,
      [DPRStatus.PASS]: 2,
      [DPRStatus.LOADING]: 3
    }
    return statusOrder[a.DPRStatus()] - statusOrder[b.DPRStatus()]
  })
  const successfulTestResults = completedTestResults.filter((result) => result.DPRStatus() === DPRStatus.PASS)
  const failedTestResults = completedTestResults.filter(
    (result) => result.DPRStatus() === DPRStatus.FAIL || result.DPRStatus() === DPRStatus.ERROR
  )

  return (
    <Container>
      <Heading size="sm">
        {`Testing ${completedTestResults.length}/${currentResults.length} - `}
        <CheckCircleIcon color="green.500" ps={3} />
        {` ${successfulTestResults.length}`}
        <CloseIcon color="red.500" ps={3} />
        {` ${failedTestResults.length} `}

        {` - ${timeSinceStart.toFixed(2)}ms`}
      </Heading>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Character</Th>
            <Th>Status</Th>
            <Th>Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedTestResults.map((result) => {
            return (
              <Tr key={result.slug!}>
                <Td>
                  {result?.character ? (
                    <Link href={`http://dprcalc.com/c/${result.slug}`} isExternal>
                      {result.character.name()} ({result.id})
                    </Link>
                  ) : (
                    result.name
                  )}
                </Td>
                <Td>{result.DPRStatusText()}</Td>
                <Td whiteSpace="normal">{result?.character ? result.character.testDescriptorForDisplay() : '…'}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Container>
  )
}

class CharacterTestResult {
  id: number
  index: number
  name: string
  slug: string

  character?: Character
  duration?: number
  error?: boolean

  targetDPR?: string
  calculatedDPR?: string

  private turnEngine?: TurnEngine

  constructor(
    slug: string,
    character: Character | undefined,
    index: number,
    error: boolean | undefined = undefined,
    duration: number | undefined = undefined
  ) {
    this.character = character
    this.id = index // Backup in case there is an error
    this.name = slug
    this.error = error
    this.duration = duration
    this.index = index
    this.slug = slug

    // TODO what happens when charater OR share data are missing?
    if (this.character) {
      // console.log(`Creating CharacterTestResult for ${character.id()}`, character)
      this.id = this.character.id()
      this.targetDPR = this.character.shareData()?.dpr || '0'
      this.name = this.character.name()

      const shareData = this.character.shareData()
      if (shareData) {
        const { checkedFeatures, actionIdList, advantageOverrides, targetAC, shareKey } = shareData

        if (shareKey) {
          this.slug = shareKey
        }

        const acs = Utility.range(targetAC, targetAC)
        this.turnEngine = new TurnEngine(
          this.character,
          checkedFeatures,
          actionIdList,
          advantageOverrides,
          acs,
          targetAC
        )

        const allTurns = this.turnEngine.allTurnActions
        const damageTotals = sumDamageArrays(allTurns, [targetAC])
        this.calculatedDPR = damageTotals[0].toFixed(2)
      }
    }
  }

  shareURL(): string {
    return this.turnEngine?.shareableURL() || ''
  }

  DPRStatus(): DPRStatus {
    if (this.error) return DPRStatus.ERROR
    if (!this.calculatedDPR) return DPRStatus.LOADING
    if (this.targetDPR === this.calculatedDPR) return DPRStatus.PASS
    return DPRStatus.FAIL
  }

  DPRStatusText(): JSX.Element {
    switch (this.DPRStatus()) {
      case DPRStatus.ERROR:
        return (
          <HStack>
            <CloseIcon color="red.500" />
            <Text>{`Error`}</Text>
          </HStack>
        )
      case DPRStatus.LOADING:
        return (
          <HStack>
            <SpinnerIcon color="grey.500" />
            <Text>Loading…</Text>
          </HStack>
        )
      case DPRStatus.PASS:
        return (
          <HStack>
            <CheckCircleIcon color="green.500" />
            <Text>{this.targetDPR}</Text>
          </HStack>
        )
      case DPRStatus.FAIL:
        return (
          <HStack>
            <CloseIcon color="red.500" />
            <Text>{`${this.targetDPR} != ${this.calculatedDPR}`}</Text>
          </HStack>
        )
    }
  }
}
