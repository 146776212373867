import { Modifier } from './CharacterJSON/Modifiers'
export class WeaponMastery {
  id: number
  name: string // Cleave
  displayName: string // Cleave (Greataxe)
  weaponType: string // Greataxe

  constructor(feat: Modifier) {
    const [name, ...weapon] = feat.subType
      .split('-')
      .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))

    this.id = feat.id
    this.name = name
    this.weaponType = weapon.join(' ')
    this.displayName = feat.friendlySubtypeName || `${name} (${this.weaponType})`
  }
}
