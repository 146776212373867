import React from 'react'
import { CharacterSheet } from './CharacterSheet'
import { Box, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { Dispatcher } from '../Common/Types'
import { useEffect } from 'react'
import { FailureToLoadPage } from './FailureToLoadPage'
import { EmptyCharacterPage } from './EmptyCharacterPage'
import { TrackCharacter } from '../Common/AppAnalytics'
import { CharacterLoader } from '../Loader/CharacterLoader'
import { Character } from '../DDB/Character'
import { Campaign } from '../DDB/CharacterJSON/Campaign'
import { BlobCache } from '../Data/BlobCache'
import { IncompleteCharacterSheet } from './IncompleteCharacterSheet'
import { Utility } from '../Common/Utility'

export function CharacterPage({
  id,
  isPrimary,
  setCampaign,
  setCharacterName
}: {
  id: string
  isPrimary: boolean
  setCampaign: Dispatcher<Campaign | undefined>
  setCharacterName: Dispatcher<string>
}) {
  const [reload, setReload] = useState(false)
  const [character, setCharacter] = useState<Character | null>(null)
  const [loadError, setLoadError] = useState(false)
  const toast = useToast()

  useEffect(() => {
    const fetchCharacter = async (isReloading: boolean) => {
      try {
        const loadedCharacter: Character | null = await CharacterLoader.load(id, reload)
        setReload(false)
        if (!loadedCharacter) {
          setLoadError(true)
          return
        }

        if (isPrimary) {
          // Hack for now - redirect back to /s/. This keeps the share URL working.
          if (!Utility.isDevelopment && !BlobCache.isKeyNumeric(id)) {
            const newPath = `/s/${id}`
            const newUrl = `${window.location.protocol}//${window.location.host}${newPath}`

            // Change the URL without reloading the page
            window.history.pushState({ path: newUrl }, '', newUrl)
          }

          setCampaign(loadedCharacter.campaign())
          setCharacterName(loadedCharacter.name())
          document.title = `DPR Calculator${loadedCharacter ? ` – ${loadedCharacter.name()}` : ''}`
          TrackCharacter(loadedCharacter)
        }

        setCharacter(loadedCharacter)
        setLoadError(false)
        if (isReloading) {
          toast({
            title: loadedCharacter.name(),
            description: `Character successfully reloaded`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top'
          })
        }
      } catch (error) {
        console.error('Error fetching character info:', error)
        setLoadError(true)
      }
    }

    if (reload || !character) {
      fetchCharacter(reload)
    }
  }, [reload, character, id, isPrimary, setCampaign, setCharacterName])

  return (
    <Box alignItems="flex-start" key={character ? character.name() : 'Loading'} p={2}>
      {loadError && <FailureToLoadPage id={id} setReload={setReload} />}
      {character && character.totalLevel() === 0 && !loadError && (
        <IncompleteCharacterSheet character={character} id={id} setReload={setReload} />
      )}
      {character && character.totalLevel() > 0 && !loadError && (
        <CharacterSheet
          character={character}
          key={character.ddbURL()}
          reload={reload}
          setReload={setReload}
          isPrimary={isPrimary}
        />
      )}
      {!character && !loadError && <EmptyCharacterPage />}
    </Box>
  )
}
