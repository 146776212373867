import { Dice } from '../Dice'
import { Class } from '../../Common/Constants'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserBarbarian {
  static parse(parser: FeatureParserBase) {
    const thisClass = Class.BARBARIAN
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)
    const is2024Class = parser.is2024Class(thisClass)

    if (classLevel === 0) return false
    if (feature.skipped()) return false
    if (requiredLevel && classLevel < requiredLevel) return feature.skip()

    if (name === 'Rage (Enter)') {
      const damageBonus: number = character.rageBonusDamage()
      only.attackRoll = true
      only.weaponOrUnarmed = true // TODO: technically strength based attack rolls only
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      effects.raging = true
      feature.usesLimitedResource = true
      feature.name = 'Rage'
      return true
    }

    if (name === 'Frenzy') {
      const rageBonus: number = character.rageBonusDamage()
      only.meleeAttack = true
      only.oncePerTurn = true
      only.raging = true // TODO implement
      feature.usesLimitedResource = true
      effects.additionalDamageOnHitDice = Dice.Create(rageBonus, 6)
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next melee attack while raging`
      return true
    }

    if (name === 'Reckless Attack') {
      only.meleeAttack = true
      effects.advantage = true
      return true
    }

    if (name === 'Brutal Critical') {
      const levelDamageMap: Record<number, number> = { 9: 1, 13: 2, 17: 3 }
      for (const level in levelDamageMap) {
        const lvlInt = parseInt(level)
        if (classLevel >= lvlInt && classLevel < lvlInt + 4 && requiredLevel === lvlInt) {
          effects.additionalDamageDiceOnCrit = levelDamageMap[lvlInt]
          feature.defaultEnabled = true
          return true
        }
      }

      return feature.skip()
    }

    if (name === 'Totemic Attunement - Tiger') {
      effects.extraAttackThisTurn = true
      feature.notes = 'Extra bonus action melee attack'
      return true
    }

    if (name === 'Divine Fury' && is2024Class) {
      only.raging = true
      only.meleeAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6, Math.floor(classLevel / 2))
      return true
    }

    if (name.startsWith('Brutal Strike:')) {
      only.recklessAttack = true
      only.meleeAttack = true // TODO: Technically any strength based attack rolls
      only.oncePerTurn = true
      effects.forgoAdvantageNextAttack = true
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 17 ? 2 : 1, 10)
      feature.notes = `Forgo adv while reckless for +${effects.additionalDamageOnHitDice.diceString()} dmg next attack`
      return true
    }

    if (name === 'Giant’s Havoc: Crushing Throw') {
      const damageBonus: number = character.rageBonusDamage()
      only.weapon = true
      only.thrownWeapon = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      feature.name = 'Crushing Throw'
      return true
    }

    if (name === 'Giant’s Havoc: Giant Stature') {
      effects.damageMultiplier = 2 // TODO this sould be double weapon dice, not double damage
      effects.additionalMeleeRange = 10
      feature.name = 'Giant Stature'
      feature.notes = 'Grow Large and (todo) increase melee range by 5ft'
      return true
    }

    if (name === 'Form of the Beast: Claws') {
      only.weapon = true
      only.oncePerTurn = true
      effects.extraAttackThisAction = true
      feature.notes = 'Gain 1 additional claw attack if you attack with a claw'
      return true
    }

    if (name === 'Bolstering Magic') {
      effects.additionalDamageOnHitDice = Dice.Create(1, 3)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Rage') {
      if (isSubclassFeature) return feature.skip()
      const damageBonus: number = character.rageBonusDamage()
      only.meleeAttack = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(damageBonus)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Elemental Cleaver') {
      if (isSubclassFeature) return feature.skip()
      const barbarianLevel = character.classLevel(Class.BARBARIAN)

      // TODO: Melee or thrown… but now a bow or crossbow, similar to twoHandedOrVersatileOnly
      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(barbarianLevel >= 14 ? 2 : 1, 6)
      return true
    }

    if (name === 'Frenzy Attack') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.extraBonusAttacksThisAction = 1
      return true
    }

    if (feature.name === 'Divine Fury') {
      if (isSubclassFeature) return feature.skip()
      feature.usesLimitedResource = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      only.weapon = true
      return true
    }

    if (name === 'Infectious Fury') {
      if (isSubclassFeature) return feature.skip()

      feature.usesLimitedResource = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    const nonBuffActions = [
      'Relentless Rage',
      'Instinctive Pounce',
      'Battlerager Charge',
      'Armor Spike Attack',
      'Armor Spike Grapple',
      'Unarmored Defense',
      'Danger Sense',
      'Primal Path',
      'Ability Score Improvement',
      'Extra Attack',
      'Fast Movement',
      'Feral Instinct',
      'Persistent Rage',
      'Indomitable Might',
      'Primal Champion',
      'Proficiencies',
      'Hit Points',
      'Equipment',
      'Restriction: Dwarves Only',
      'Battlerager Armor',
      'Reckless Abandon',
      'Spiked Retribution',
      'Rage (Instinctive Pounce)',
      'Brutal Strike',
      'Rage: Relentless Rage',
      'Rage: Regain Expended Uses',
      'Warrior of the Gods: Expend Dice',
      'Zealous Presence',
      'Unarmored Defense',
      'Weapon Mastery',
      'Danger Sense',
      'Barbarian Subclass',
      'Primal Knowledge',
      'Ability Score Improvement',
      'Extra Attack',
      'Fast Movement',
      'Feral Instinct',
      'Instinctive Pounce',
      '8: Ability Score Improvement',
      'Relentless Rage',
      '12: Ability Score Improvement',
      'Improved Brutal Strike',
      'Persistent Rage',
      '16: Ability Score Improvement',
      'Indomitable Might',
      'Epic Boon',
      'Primal Champion',
      'Core Barbarian Traits',
      '4: Weapon Mastery',
      '10: Weapon Mastery',
      'Warrior of the Gods',
      'Fanatical Focus',
      'Rage of the Gods',
      'Rage (Instinctive Pounce)',
      'Brutal Strike',
      'Rage: Relentless Rage',
      'Rage: Regain Expended Uses',
      'Intimidating Presence',
      'Retaliation',
      'Unarmored Defense',
      'Weapon Mastery',
      'Danger Sense',
      'Barbarian Subclass',
      'Primal Knowledge',
      'Ability Score Improvement',
      'Extra Attack',
      'Fast Movement',
      'Feral Instinct',
      'Instinctive Pounce',
      '8: Ability Score Improvement',
      'Relentless Rage',
      '12: Ability Score Improvement',
      'Improved Brutal Strike',
      'Persistent Rage',
      '16: Ability Score Improvement',
      'Indomitable Might',
      'Epic Boon',
      'Primal Champion',
      'Core Barbarian Traits',
      '4: Weapon Mastery',
      '10: Weapon Mastery',
      'Mindless Rage',
      'Rage (Instinctive Pounce)',
      'Brutal Strike',
      'Rage: Relentless Rage',
      'Topple',
      'Push',
      'Unarmored Defense',
      'Weapon Mastery',
      'Danger Sense',
      'Barbarian Subclass',
      'Primal Knowledge',
      'Ability Score Improvement',
      'Extra Attack',
      'Fast Movement',
      'Feral Instinct',
      'Instinctive Pounce',
      '8: Ability Score Improvement',
      'Relentless Rage',
      '12: Ability Score Improvement',
      'Improved Brutal Strike',
      'Persistent Rage',
      '16: Ability Score Improvement',
      'Indomitable Might',
      'Epic Boon',
      'Primal Champion',
      'Core Barbarian Traits',
      '4: Weapon Mastery',
      '10: Weapon Mastery',
      'Vitality of the Tree',
      'Branches of the Tree',
      'Battering Roots',
      'Travel along the Tree'
    ]
    if (nonBuffActions.includes(name)) return feature.skip()
    return false
  }
}
