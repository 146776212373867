import { Json } from '../../Common/Types'

export class Stat {
  id: number
  name?: string
  value: number

  constructor(json: Json) {
    this.id = json.id
    this.name = json.name
    this.value = json.value
  }
}
