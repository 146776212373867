import { Utility } from '../Common/Utility'

export type CustomizationValueMap = { [key: string]: CustomizationValues }

export class CustomizationValues {
  characterName: string

  // Common
  id: string
  name?: string
  notes?: string
  loadedData?: boolean

  // Character
  armorClass?: number

  // Spells
  toHitOverride?: number
  toHitBonus?: number
  bonusDamage?: number
  dcOverride?: number
  dcBonus?: number

  // Weapons
  displayAsAttack?: boolean
  costOverride?: number
  weightOverride?: number
  silvered?: boolean
  adamantine?: boolean
  isOffhand?: boolean
  isPact?: boolean
  isHex?: boolean

  // Companions / Extras
  companionSize?: number
  companionTyoe?: number
  companionAlignment?: number
  companionArmorClass?: number
  companionHitPoints?: number
  companionNotes?: string

  // Sacks
  capacity?: number

  // Debugging
  unknownTypeId?: number

  constructor(characterName: string, id: string) {
    this.id = id
    this.characterName = characterName
  }

  isCustomized() {
    return this.loadedData
  }

  addValue(valueId: string, typeId: number, value: number | string | boolean | undefined) {
    if (valueId !== this.id) {
      Utility.isDevelopment &&
        console.error(`${this.characterName} - mismatched customization valueId. ${valueId} ≠ ${this.id}`)
      return
    }

    let loaded = true

    const typeMapping: Record<number, keyof CustomizationValues> = {
      1: 'armorClass',
      8: 'name',
      9: 'notes',
      10: 'bonusDamage',
      12: 'toHitBonus',
      13: 'toHitOverride',
      14: 'dcBonus',
      15: 'dcOverride',
      16: 'displayAsAttack',
      18: 'isOffhand',
      19: 'costOverride',
      20: 'silvered',
      21: 'adamantine',
      22: 'weightOverride',
      28: 'isPact',
      29: 'isHex',
      42: 'companionArmorClass',
      43: 'companionHitPoints',
      44: 'companionTyoe',
      45: 'companionAlignment',
      46: 'companionSize',
      47: 'companionNotes',
      50: 'capacity'
    }

    const property = typeMapping[typeId]
    if (property) {
      ;(this[property] as string | number | boolean | undefined) = value
      loaded = true
    } else {
      Utility.isDevelopment &&
        console.warn(`${this.characterName} - Unhandled customization ${valueId}, type ${typeId}, value ${value}`)
      this.unknownTypeId = typeId
      loaded = false
    }

    if (loaded) {
      this.loadedData = true
    }
  }
}
