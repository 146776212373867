import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'
import { ABILITY } from '../../Common/Constants'

export class FeatureParserRacialTrait {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action, character } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.RacialTrait) return false

    feature.defaultEnabled = true

    // 2024 Racial Actions & Traits

    if (name === 'Frost’s Chill (Frost Giant)') {
      only.attackRoll = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (name === 'Fire’s Burn (Fire Giant)') {
      only.oncePerTurn = true
      only.attackRoll = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      return true
    }

    if (name === 'Hill’s Tumble (Hill Giant)') {
      only.attackRoll = true
      effects.nextAttacksHaveAdvantage = true
      feature.notes = 'Give target prone condition on attack roll'
      return true
    }

    if (name === 'Luck') {
      effects.rerollToHit = true
      feature.defaultEnabled = true
      return true
    }

    // 2014 Racial Traits
    if (name === 'Surprise Attack') {
      if (feature.id !== 8429380) return feature.skip()
      only.attackRoll = true
      effects.additionalDamageOnHitDice = Dice.Create(2, 6)
      feature.usesLimitedResource = true
      feature.notes = '+2d6 dmg to next attack on surprised enemy'
      return true
    }

    if (name === 'Celestial Revelation') {
      if (action.id !== 8429359) return feature.skip()

      // 8429359 is the 2014 version, which does Radiant Soul
      const chaMod = character.modifierForAbility(ABILITY.CHARISMA)
      only.fireOrRadiantDamage = true
      only.spell = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(chaMod)
      feature.name = 'Radiant Soul'
      feature.notes = `+${chaMod} dmg to fire or radiant spells`
      return true
    }

    if (name === 'Fury of the Small') {
      if (!action.activation) return false
      only.oncePerTurn = true
      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(character.proficiencyBonus())
      return true
    }

    if (name === 'Savage Attacks') {
      only.meleeWeapon = true
      effects.additionalDamageDiceOnCrit = 1
      return true
    }

    if (name === 'Sunlight Sensitivity') {
      only.weapon = true
      effects.disadvantage = true
      feature.defaultEnabled = false
      return true
    }

    if (name === 'Draconic Cry') {
      only.attackRoll = true
      effects.advantage = true
      feature.usesLimitedResource = true
      feature.defaultEnabled = false
      return true
    }

    if (name === 'Goring Rush') {
      effects.convertActionToBonusAction = true
      only.horns = true
      only.weapon = true
      feature.notes = 'Bonus action Horns attack after Dash action'
      return true
    }

    if (!NON_BUFF_RACIAL_TRAITS.includes(feature.name)) {
      parser.logUnknownAction()
    }
    return false
  }
}

export const NON_BUFF_RACIAL_TRAITS = [
  'Magic Resistance',
  'Mirthful Leaps',
  'Reveler',
  'Nimble Escape: Disengage',
  'Nimble Escape: Hide',
  'Mimicry',
  'Fairy Magic',
  'Expert Duplication',
  'Kenku Recall',
  'Valenar Elf',
  'Cantrip',
  'Extra Langauge',
  'Changeling Instincts',
  'Shapechange',
  'Shapechanger',
  'Astral Fire',
  'Starlight Step',
  'Aereni Elf',
  'Tree Native',
  'Born Acrobat',
  'Natural Athlete',
  'Astral Trance',
  'Fury of the Small',
  'Glide',
  'Nimble Escape',
  'Stone’s Endurance',
  'Variable Trait',
  'Mountain Born',
  'Fey Step',
  'Darkvision',
  'Eladrin Season',
  'Keen Senses',
  'Large Form',
  'Relentless Endurance',
  'Gnome Cunning',
  'Artificer’s Lore',
  'High Elf Descent',
  'Elf Weapon Training',
  'Fleet of Foot',
  'Mask of the Wild',
  'Medical Intuition',
  'Spells of the Mark',
  'Cunning Intuition',
  'Shape Shadows',
  'Menacing',
  'Savage Attacks',
  'Blessing of the Raven Queen',
  'Necrotic Resistance',
  'Astral Knowledge',
  'Githyanki Psionics',
  'Psychic Resilience',
  'Tool Proficiency',
  'Healing Touch',
  'Light Bearer',
  'Hare-Trigger',
  'Leporine Senses',
  'Lucky Footwork',
  'Rabbit Hop',
  'Healing Hands',
  'Draconic Ancestry',
  'Celestial Resistance',
  'Lucky',
  'Brave',
  'Halfling Nimbleness',
  'Tinker',
  'Hellish Resistance',
  'Infernal Legacy',
  'Wood Elf Descent',
  'Trance',
  'Gnomish Cunning',
  'Gnomish Lineage',
  'Gnomish Lineage Spells',
  'Elven Lineage',
  'Elven Lineage Spells',
  'Age',
  'Fey Ancestry',
  'Ability Score Increase',
  'Resourceful',
  'Skillful',
  'Versatile',
  'Breath Weapon',
  'Damage Resistance',
  'Draconic Flight',
  'Activate Large Form',
  'Celestial Revelation',
  'Giant Ancestry',
  'Bite',
  'Alignment',
  'Evasive',
  'Dwarven Resilience',
  'Bewitching Guile',
  'Dwarven Combat Training',
  'Skills',
  'Stonecunning',
  'Stonecunning (Tremorsense)',
  'Superior Darkvision',
  'Drow Weapon Training',
  'Dwarven Toughness',
  'Drow Magic',
  'Feat',
  'Specialized Design',
  'Integrated Protection',
  'Sentry’s Rest',
  'Constructed Resilience',
  'Claws',
  'Shell Defense',
  'Natural Armor',
  'Nature’s Intuition',
  'Hold Breath',
  'Skill Versatility',
  'Creature Type',
  'Size',
  'Speed',
  'Flight',
  'Wind Caller',
  'Ability Score Increases',
  'Languages',
  'Adrenaline Rush',
  'Powerful Build',
  'Breath Weapon (Acid)',
  'Breath Weapon (Fire)',
  'Breath Weapon (Lightning)',
  'Breath Weapon (Poison)',
  'Breath Weapon (Cold)',
  'Armored Casing',
  'Healing Machine',
  'Build for Succcess',
  'Thri-kreen Telepathy',
  'Sleepless',
  'Mechanical Nature',
  'Cat’s Talent',
  'Feline Agility',
  'Built for Success',
  'Hadozee Dodge',
  'Dexterous Feet',
  'Glide (Reaction)',
  'Long-Limbed',
  'Earth Walk',
  'Merge with Stone',
  'Naturally Stealthy',
  'Luck',
  'Kobold Legacy',
  'Chameleon Carapace',
  'Secondary Arms',
  'Blessing of the Raven Queen (Resistance)',
  'Shell Defense: Withdraw',
  'Shell Defense: Emerge',
  'Chameleon Carapace (Change Color)',
  'Ram',
  'Sneaky',
  'Surprise Attack',
  'Fey Step (Autumn)',
  'Draconic Cry'
]
