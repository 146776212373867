import { Json } from '../../Common/Types'

export class Modifier {
  fixedValue: number
  value: number
  type: string
  subType: string
  isGranted: boolean
  componentId: number
  friendlySubtypeName?: string
  id: number

  constructor(json: Json) {
    this.id = json.id
    this.fixedValue = json.fixedValue
    this.value = json.value
    this.type = json.type
    this.subType = json.subType
    this.isGranted = json.isGranted
    this.componentId = json.componentId
    this.friendlySubtypeName = json.friendlySubtypeName
  }
}

export class Modifiers {
  class: Modifier[]
  feat: Modifier[]
  race: Modifier[]
  background: Modifier[]
  item: Modifier[]
  condition: Modifier[]

  constructor(json: Json) {
    function createModifierList(inJson: Json[]) {
      return inJson
        .filter((modifier: Json) => modifier.type !== 'proficiency' && modifier.type !== 'language')
        .map((modifier: Json) => new Modifier(modifier))
    }
    this.class = createModifierList(json.class)
    this.feat = createModifierList(json.feat)
    this.race = createModifierList(json.race)
    this.background = createModifierList(json.background)
    this.item = createModifierList(json.item)
    this.condition = createModifierList(json.condition)
  }
}
