import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserExternalEffects {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, character } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.Effect) return false

    if (name === 'Bardic Inspiration die') {
      // Assume the bardic inspiration dice was granted at your level.
      // Later make it configurable
      const characterLevel = character.totalLevel()
      const dieValue = character.bardicInspirationDieSize(characterLevel)
      only.oncePerTurn = true
      effects.additionalToHitDice = Dice.Create(1, dieValue)
      return true
    }

    if (name === 'Bless') {
      effects.additionalToHitDice = Dice.Create(1, 4)
      feature.alsoAppliesToCompanionAttacks = true
      return true
    }

    if (name === 'Advantage on next attack' || name === 'Heroic Inspiration') {
      only.attackRoll = true
      only.oncePerTurn = true
      effects.advantage = true
      feature.alsoAppliesToCompanionAttacks = true
      return true
    }

    if (name === 'Bonus Actions first') {
      feature.bonusActionFirst = true
      feature.notes = 'Bonus Actions are taken before Actions'
      return true
    }

    if (name === 'Advantage this turn') {
      only.attackRoll = true
      effects.advantage = true
      feature.alsoAppliesToCompanionAttacks = true
      return true
    }

    if (name === 'Haste') {
      effects.extraAttackThisTurn = true
      return true
    }

    if (name === 'Perkins crit') {
      effects.critDiceDealMaxDamage = true
      feature.alsoAppliesToCompanionAttacks = true
      feature.notes = 'Crit dice deal maximum damage'
      return true
    }

    // parser.logUnknownAction()
    return false
  }
}
