import { Dice } from '../Dice'
import { Class } from '../../Common/Constants'
import { FeatureParserBase } from './FeatureParserBase'
import { ABILITY } from '../../Common/Constants'

export class FeatureParserDruid {
  static parse(parser: FeatureParserBase) {
    const thisClass = Class.DRUID
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, requiredLevel, isSubclassFeature } = parser

    if (name === 'Moonlight Step') {
      if (action.id !== 9414273) return feature.skip()
      if (requiredLevel) return false
      only.attackRoll = true
      only.oncePerTurn = true
      effects.advantage = true
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Wild Shape: Improved Lunar Radiance') {
      only.wildshapeAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(2, 10)
      feature.name = 'Improved Lunar Radiance'
      return true
    }

    if (name === 'Symbiotic Entity') {
      if (isSubclassFeature) return feature.skip()
      only.meleeAttack = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6)
      feature.usesLimitedResource = true
      return true
    }

    const nonBuffActions = [
      'Primal Strike',
      'Circle of the Land Spells',
      'Land’s Aid',
      'Land’s Aid: Heal',
      'Tropical Land',
      'Arid Land',
      'Polar Land',
      'Magician',
      'Wild Shape',
      'Wild Companion',
      'Combat Wild Shape',
      'Druidic',
      'Spellcasting',
      'Wild Shape Improvement',
      'Druid Circle',
      'Ability Score Improvement',
      'Timeless Body',
      'Beast Spells',
      'Archdruid',
      'Proficiencies',
      'Hit Points',
      'Equipment',
      'Circle Forms',
      'Elemental Wild Shape',
      'Thousand Forms',
      'Leave Druidic Message',
      'Wild Shape',
      'Wild Resurgence: Regain Wild Shape',
      'Wild Resurgence: Regain Spell Slot',
      'Evergreen Wildshape',
      'Nature Magician',
      'Manifest Wrath of the Sea',
      'Grant Wrath of the Sea',
      'Core Druid Traits',
      'Spellcasting',
      'Druidic',
      'Primal Order',
      'Wild Companion',
      'Druid Subclass',
      'Ability Score Improvement',
      'Wild Resurgence',
      'Elemental Fury',
      '8: Ability Score Improvement',
      '12: Ability Score Improvement',
      'Improved Elemental Fury',
      '16: Ability Score Improvement',
      'Beast Spells',
      'Epic Boon',
      'Archdruid',
      'Circle of the Sea Spells',
      'Wrath of the Sea',
      'Aquatic Affinity',
      'Stormborn',
      'Oceanic Gift',
      'Warden',
      'Leave Druidic Message',
      'Wild Shape',
      'Wild Resurgence: Regain Wild Shape',
      'Wild Resurgence: Regain Spell Slot',
      'Evergreen Wildshape',
      'Nature Magician',
      'Wild Shape: Circle Forms',
      'Moonlight Step: Regain Uses',
      'Core Druid Traits',
      'Spellcasting',
      'Druidic',
      'Primal Order',
      'Wild Companion',
      'Druid Subclass',
      'Ability Score Improvement',
      'Wild Resurgence',
      'Elemental Fury',
      '8: Ability Score Improvement',
      '12: Ability Score Improvement',
      'Improved Elemental Fury',
      '16: Ability Score Improvement',
      'Beast Spells',
      'Epic Boon',
      'Archdruid',
      'Circle Forms',
      'Circle of the Moon Spells',
      'Improved Circle Forms',
      'Lunar Form',
      'Warden',
      'Leave Druidic Message',
      'Wild Shape',
      'Wild Resurgence: Regain Wild Shape',
      'Wild Resurgence: Regain Spell Slot',
      'Star Map: Ceremony',
      'Assume Starry Form',
      'Evergreen Wildshape',
      'Nature Magician',
      'Change Starry Form',
      'Archer: Luminous Arrow',
      'Cosmic Omen - Weal',
      'Core Druid Traits',
      'Spellcasting',
      'Druidic',
      'Primal Order',
      'Wild Companion',
      'Druid Subclass',
      'Ability Score Improvement',
      'Wild Resurgence',
      'Elemental Fury',
      '8: Ability Score Improvement',
      '12: Ability Score Improvement',
      'Improved Elemental Fury',
      '16: Ability Score Improvement',
      'Beast Spells',
      'Epic Boon',
      'Archdruid',
      'Star Map',
      'Starry Form',
      'Cosmic Omen',
      'Twinkling Constellations',
      'Full of Stars',
      'Weal (Even)',
      'Archer',
      'Archer: Full of Stars',
      'Leave Druidic Message',
      'Wild Shape',
      'Wild Resurgence: Regain Wild Shape',
      'Wild Resurgence: Regain Spell Slot',
      'Evergreen Wildshape',
      'Nature Magician',
      'Wild Shape: Circle Forms',
      'Moonlight Step: Regain Uses',
      'Core Druid Traits',
      'Spellcasting',
      'Druidic',
      'Primal Order',
      'Wild Companion',
      'Druid Subclass',
      'Ability Score Improvement',
      'Wild Resurgence',
      'Elemental Fury',
      '8: Ability Score Improvement',
      '12: Ability Score Improvement',
      'Improved Elemental Fury',
      '16: Ability Score Improvement',
      'Beast Spells',
      'Epic Boon',
      'Archdruid',
      'Circle Forms',
      'Circle of the Moon Spells',
      'Improved Circle Forms',
      'Lunar Form',
      'Halo of Spores',
      'Circle Spells',
      'Fungal Infestation',
      'Spreading Spores',
      'Fungal Body',
      'Land’s Aid: Damage'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }

  static parseClassOptions(parser: FeatureParserBase): boolean {
    const thisClass = Class.DRUID
    const { feature, action } = parser
    const { only, effects, name } = feature

    const classLevel = parser.classLevel(thisClass)

    if (!parser.validate(thisClass)) return false

    if (name === 'Primal Strike') {
      if (action.id !== 4496851) return feature.skip()

      only.attackRoll = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 15 ? 2 : 1, 8) // feature ID 44968553 increases to 2d8
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next hit with weapon or wildshape attack`
      return true
    }

    if (name === 'Potent Spellcasting' && (action.id === 4496850 || action.id === '4496850')) {
      // 2024 Druid Version
      const wisdomMod = parser.character.modifierForAbility(ABILITY.WISDOM)
      only.cantrip = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      feature.defaultEnabled = true
      feature.notes = `+${wisdomMod} dmg to next Druid cantrip`
      return true
    }

    return false
  }
}
