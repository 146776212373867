import { Tr, Td, Checkbox, Tbody, Th } from '@chakra-ui/react'
import React from 'react'
import { CheckMap } from '../../Common/Interfaces'
import { Dispatcher } from '../../Common/Types'
import { WrappingTd } from '../../Common/UIComponents'
import { Feature } from '../../DDB/Feature'
import { TABLE_HEADER_COLOR } from '../CharacterSheet'
import { FeatureBox } from '../Controls/FeatureBox'

export function FeatureTable({
  title,
  features,
  checkedFeatures,
  setCheckedFeatures
}: {
  title: string
  features: Feature[]
  checkedFeatures: CheckMap
  setCheckedFeatures: Dispatcher<CheckMap>
}) {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedId: number = parseInt(event.target.value)
    const newFeatures: CheckMap = {
      ...checkedFeatures,
      [checkedId]: !checkedFeatures[checkedId]
    }

    setCheckedFeatures(newFeatures)
  }

  if (features.length === 0) return null

  const concentrationFeatures = features
    .filter((feature: Feature) => checkedFeatures[feature.id])
    .filter((feature) => feature.requiresConcentration)
  const firstConcentrationFeature = concentrationFeatures.length > 0 ? concentrationFeatures[0] : undefined

  const buffRows = features.map((feature: Feature) => {
    if (feature.isBuff) {
      return (
        <Tr key={feature.id}>
          <Td>
            <form>
              <Checkbox
                colorScheme={'blue'}
                id={feature.id.toString()}
                value={feature.id}
                isChecked={checkedFeatures[feature.id]}
                onChange={handleCheckboxChange}
              >
                <FeatureBox
                  feature={feature}
                  warnConcentration={
                    firstConcentrationFeature &&
                    firstConcentrationFeature !== feature &&
                    concentrationFeatures.includes(feature)
                  }
                  ps={0}
                />
              </Checkbox>
            </form>
          </Td>
          <WrappingTd>{feature.generatedNotes()}</WrappingTd>
        </Tr>
      )
    } else {
      return (
        <Tr key={feature.id}>
          <Td colSpan={2}>
            <FeatureBox feature={feature} ps={0} />
          </Td>
        </Tr>
      )
    }
  })

  return (
    <Tbody>
      <Tr textColor={TABLE_HEADER_COLOR}>
        <Th fontSize={'medium'} colSpan={2} pt={4}>
          {title}
        </Th>
      </Tr>
      {buffRows}
    </Tbody>
  )
}

export function FeatureTableSection({
  title,
  features,
  checkedFeatures,
  setCheckedFeatures
}: {
  title: string
  features: Feature[]
  checkedFeatures: CheckMap
  setCheckedFeatures: Dispatcher<CheckMap>
}) {
  if (features.length === 0) {
    return null
  }
  return (
    <FeatureTable
      title={title}
      features={features}
      checkedFeatures={checkedFeatures}
      setCheckedFeatures={setCheckedFeatures}
    />
  )
}
