import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Dice } from '../Dice'
import { Class } from '../../Common/Constants'
import { Action } from '../CharacterJSON/Actions'

export class ActionParserBard {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]) {
    const name = action.name
    const character = parser.character
    if (character.classLevel(Class.BARD) === 0) return false

    if (name === 'Animating Performance: Dancing Item') {
      const dice = Dice.Create(1, 10, parser.proficiencyBonus)
      const slam = 'Dancing Item: Force-Empowered Slam'
      const sam = parser.spellAttackMod
      const attack = new AttackAction(action.id, slam, sam, dice, parser.companionAttrs, parser.bonusAction)
      attack.attributes.spellAttackModCompanion = true
      attackActions.push(attack)
      return true
    }

    return false
  }
}
