import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Heading, Icon, Input, SimpleGrid, Square } from '@chakra-ui/react'
import { Stack, Text, HStack, Spacer, Box } from '@chakra-ui/react'
import { DprTab, MainNavBar } from './MainNavBar'
import { handleCharacterButtonClick } from '../Common/Handlers'
import { FaDice, FaChartBar, FaMagic, FaFileAlt } from 'react-icons/fa'
import { SettingsIcon } from '@chakra-ui/icons'
import { FaPeopleCarry } from 'react-icons/fa'
import { TrackPageView, PAGE_WELCOME } from '../Common/AppAnalytics'

import { CharacterStubCard } from './CharacterStubCard'
import { DISCORD_URL } from '../Common/Constants'
import { Link } from '@chakra-ui/react'

const accentColor = 'blue.200'

export const features = [
  {
    name: 'Smart Rules Engine',
    description:
      "Uses a custom rules engine to apply multiple D&D 5e rules to your turn actions, so you don't have to memorize them all",
    icon: SettingsIcon
  },
  {
    name: 'Dice Calculation',
    description: 'Does all of the dice math for you, so you can focus on building fun characters',
    icon: FaDice
  },
  {
    name: 'Charts',
    description: "Powerful interactive charts to help you visualize your character's damage output",
    icon: FaChartBar
  },
  {
    name: 'External Effect Simulation',
    description: 'Try out what your character can do if they have advantage, bless, haste, and more',
    icon: FaMagic
  },
  {
    name: 'Comprehensive',
    description:
      'Supports both 2014 and 2024 PHB and understands your class features, feats, racial traits, spells, weapon masteries, fighting styles, and more',
    icon: FaFileAlt
  },
  {
    name: 'Community',
    tsElement: (
      <Text>
        Join our{' '}
        <Link color={accentColor} target="_blank" href={DISCORD_URL}>
          Discord
        </Link>{' '}
        to share and discuss builds, report bugs, and suggest new features
      </Text>
    ),
    icon: FaPeopleCarry
  }
]

export function WelcomePage() {
  const [urlFieldValue, setUrlFieldValue] = useState('')
  const navigate = useNavigate()
  function handleButtonClick() {
    handleCharacterButtonClick(navigate, urlFieldValue)
  }
  const title = 'DPRcalc'
  const description = 'Explore the damage options of your D&D 5e character'

  TrackPageView(PAGE_WELCOME)

  const element = (
    <Container py={{ base: '4', md: '8' }}>
      <Stack spacing={{ base: '8', md: '12' }} justifyContent="center" textAlign={'center'}>
        <Stack spacing={{ base: '4', md: '5' }}>
          <Stack>
            <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="semibold" color={accentColor}>
              Introducing
            </Text>
            <Heading size={{ base: 'sm', md: 'md' }}>{title}</Heading>
          </Stack>
          <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
            {description}
          </Text>
        </Stack>

        <Stack spacing="3">
          <HStack direction={{ base: 'column', md: 'row' }} spacing="3" width="100%">
            <Spacer />
            <Input
              placeholder="Enter your dndbeyond.com character URL"
              size={'lg'}
              maxW={'xl'}
              value={urlFieldValue}
              onChange={(event) => {
                setUrlFieldValue(event.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleButtonClick()
                }
              }}
            />
            <Button size={'lg'} colorScheme="blue" onClick={handleButtonClick}>
              Explore
            </Button>
            <Spacer />
          </HStack>
        </Stack>

        <Heading size={{ base: 'sm', md: 'md' }}>Explore some example builds</Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} columnGap={8} rowGap={{ base: 4, md: 6 }}>
          <CharacterStubCard
            stub="Reya-Lightbringer-LHtOV"
            description="This lucky Paladin with a pact weapon never misses, even against heavily armored foes."
          />

          <CharacterStubCard
            stub="The-Angry-Monk-2QKGu"
            description="Stun, grapple, and dish out reliable damage every round with this strength-based dagger-wielding monk."
          />
          <CharacterStubCard
            stub="Nezza-n6Qap"
            description="A shadowy assassin who peppers their enemies with blows in the darkness."
          />
          <CharacterStubCard
            stub="Tikram-Ainik-L1LCn"
            description="A simple yet effective Orcish berserker swinging their greatsword as hard as they can."
          />
          <CharacterStubCard
            stub="Barnaby-Moonfall-Bwnbh"
            description="This dual-wielding rogue stacks sneak attacks with hunters mark every round thanks to their vow of enmity."
          />
          <CharacterStubCard
            stub="Lucien-Blackfyre-WZ6vP"
            description="Eldritch Smiting Hexblade Warlock with Elven Accuracy results in frequent and brutal critical hits."
          />
        </SimpleGrid>

        <Heading size={{ base: 'sm', md: 'md' }}>Features</Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 4, md: 8 }}>
          {features.map((feature) => (
            <Stack key={feature.name} spacing={{ base: '4', md: '5' }}>
              <Square
                size={{ base: '10', md: '12' }}
                bg={accentColor}
                color="fg.inverted"
                borderRadius="lg"
                alignSelf={'center'}
              >
                <Icon as={feature.icon} boxSize={{ base: '5', md: '6' }} />
              </Square>
              <Stack spacing={{ base: '1', md: '2' }} flex="1">
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium">
                  {feature.name}
                </Text>
                {feature.description && <Text color="fg.muted">{feature.description}</Text>}
                {feature.tsElement && feature.tsElement}
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  )

  return (
    <Box>
      {/* <WeeklyAlert /> */}
      <MainNavBar tab={DprTab.HOME} body={element} />
    </Box>
  )
}
