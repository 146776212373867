import { Dictionary } from '../../Common/Types'
import { Range } from '../CharacterJSON//Range'
import { AttackAction } from '../AttackAction'
import { Activation } from '../CharacterJSON/Activation'
import { Dice } from '../Dice'
import { Utility } from '../../Common/Utility'
import { ABILITY, ABILITY_NAMES, AttackType } from '../../Common/Constants'
import { Class } from '../../Common/Constants'
import { Creature } from '../CharacterJSON/Creature'
import { Spell } from '../Spell'
import { Character } from '../Character'
import { CustomizationValueMap } from '../CustomizationValues'
import { CharacterJSON } from '../CharacterJSON/CharacterJSON'
import { Action } from '../CharacterJSON/Actions'
import { Weapon } from '../Weapon'
import * as ActionParsers from './ActionParsers'

export class ActionParser {
  character: Character
  companionAttrs: Dictionary
  proficiencyBonus: number
  spellAttackMod: number
  spellcastingAbilityMod: number
  bonusAction: Activation
  action: Activation
  spellSaveDC: number
  wisMod: number

  constructor(character: Character) {
    this.character = character

    const meleeAttackRange = Range.makeWeaponRange(5)
    this.companionAttrs = { range: meleeAttackRange, type: AttackType.COMPANION }
    this.proficiencyBonus = character.proficiencyBonus()
    this.spellAttackMod = character.spellAttackModifier()
    this.spellcastingAbilityMod = character.spellcastingAbilityModifier()
    this.bonusAction = Activation.BonusAction()
    this.action = Activation.Action()
    this.spellSaveDC = character.spellSaveDC()
    this.wisMod = character.modifierForAbility(ABILITY.WISDOM)
  }

  parse(
    characterData: CharacterJSON,
    weapons: Weapon[],
    creatures: Creature[],
    spells: Spell[],
    customizations: CustomizationValueMap,
    spellIDsToPrune: number[]
  ): AttackAction[] {
    const attackActions: AttackAction[] = []
    const fakeIDBaseList: number[] = [100000000, 1] // WHAT A NIGHTMARE HACK LOL

    ActionParsers.Racial.parse(this, characterData.actions.race, attackActions)
    ActionParsers.WeaponFeat.parse(this, characterData.actions.feat, weapons, attackActions)
    ActionParsers.RacialTrait.parse(this, characterData.race.racialTraits, attackActions)
    ActionParsers.Spell.parse(this, spells, spellIDsToPrune, fakeIDBaseList, attackActions)
    ActionParsers.UnarmedStrike.createActions(this, characterData.actions.class, fakeIDBaseList, attackActions)

    this.parseCreatureAttackActions(creatures, attackActions)
    this.parseWeaponAttackActions(this.character, weapons, attackActions)
    this.parseClassAttackActions(this.character, characterData.actions.class, attackActions)

    this.renameAttackActions(attackActions, customizations)

    return attackActions
  }

  parseClassAttackActions(character: Character, classActions: Action[], attackActions: AttackAction[]) {
    const parsers = [
      ActionParsers.Druid,
      ActionParsers.Monk,
      ActionParsers.Artificer,
      ActionParsers.Cleric,
      ActionParsers.Rogue,
      ActionParsers.Ranger,
      ActionParsers.Warlock,
      ActionParsers.Bard,
      ActionParsers.Barbarian,
      ActionParsers.Sorcerer
    ]

    for (const action of classActions) {
      for (const parser of parsers) {
        if (parser.parse(this, action, attackActions)) break
      }
    }

    return attackActions
  }

  createSpellAttackWithSaveAction(id: number, action: Dictionary, range: Range, saveDcValue: number) {
    const saveDcAbility = Utility.shortNameForAbilityID(action.saveStatId - 1)
    const type = AttackType.SPELL_ATTACK
    const requiresSavingThrow = true
    const requiresAttackRoll = false
    const attributes = { range, type, requiresSavingThrow, requiresAttackRoll, saveDcAbility, saveDcValue }
    return new AttackAction(id, action.name, 0, new Dice(action.dice), attributes, new Activation(action.activation))
  }

  createAoeSpellAction(
    name: string,
    action: Dictionary,
    activation: Activation,
    saveDcValue: number,
    requiresSavingThrow: boolean = true
  ) {
    const range = Range.makeWeaponRange(parseInt(action.range.range))
    range.aoeSize = parseInt(action.range.aoeSize)
    range.aoeType = action.range.aoeType
    const saveDcAbility = requiresSavingThrow ? Utility.shortNameForAbilityID(action.saveStatId - 1) : null
    const type = AttackType.WEAPON
    const requiresAttackRoll = false
    const attributes = { range, type, requiresSavingThrow, requiresAttackRoll, saveDcAbility, saveDcValue }
    return new AttackAction(parseInt(action.id), name, 0, new Dice(action.dice), attributes, activation)
  }

  thrownfinesseWeaponAttrs(range: Range): Dictionary {
    return { range, type: AttackType.WEAPON, isFinesse: true, isThrown: true }
  }

  createWeaponAttackAction(
    character: Character,
    name: string,
    action: Dictionary,
    range: number,
    diceOverride: Dice | undefined = undefined
  ): AttackAction {
    const { abilityModifierStatId, dice, id, activation } = action
    const attackRange = Range.makeWeaponRange(range)
    const abilityMod = character.modifierForAbilityIndex(parseInt(abilityModifierStatId) - 1)
    const toHit = character.proficiencyBonus() + abilityMod
    const damageDice = diceOverride ? diceOverride : new Dice(dice)
    damageDice.fixedValue += abilityMod
    const attributes = { range: attackRange, type: AttackType.WEAPON }
    return new AttackAction(id, name, toHit, damageDice, attributes, new Activation(activation))
  }

  createUnarmedStrikeAttackAction(
    character: Character,
    name: string,
    activation: Activation,
    id: number,
    dice: Dice,
    modMultiplier: number = 1,
    dexOverride: boolean = false
  ): AttackAction {
    const strIndex = ABILITY_NAMES.indexOf(ABILITY.STRENGTH)
    const dexIndex = ABILITY_NAMES.indexOf(ABILITY.DEXTERITY)
    let abilityModifierStatId = dexOverride ? dexIndex : strIndex
    if (character.classLevel(Class.MONK) > 0 && character.dexterity() > character.strength()) {
      abilityModifierStatId = ABILITY_NAMES.indexOf(ABILITY.DEXTERITY)
    }

    const attackRange = Range.makeWeaponRange(5) // TODO: Racial modifier for reach would go here, if we ever care about reach
    const abilityMod = character.modifierForAbilityIndex(abilityModifierStatId)
    const toHit = character.proficiencyBonus() + abilityMod
    const damageDice = new Dice(dice)
    damageDice.fixedValue += abilityMod * modMultiplier
    const attributes = { range: attackRange, type: AttackType.WEAPON, subType: 'unarmed' }
    const attack = new AttackAction(id, name, toHit, damageDice, attributes, new Activation(activation))
    attack.attributes.subType = 'unarmed'
    return attack
  }

  parseWeaponAttackActions(character: Character, weapons: Weapon[], attackActions: AttackAction[]) {
    for (const weapon of weapons) {
      if (!weapon.attributes.isOffHand) {
        continue
      }
      const dualWielder = character.hasFeatNamed('Dual Wielder')
      if (weapon.attributes.isLight() || (dualWielder && !weapon.attributes.isTwoHanded)) {
        const offhand = true
        const attackAction = AttackAction.CreateFromWeapon(weapon, character, offhand)
        attackActions.push(attackAction)
      }
    }
  }

  parseCreatureAttackActions(creatures: Creature[], attackActions: AttackAction[]) {
    creatures.forEach((creature) => attackActions.push(...creature.attacks))
  }

  renameAttackActions(attackActions: AttackAction[], customizations: CustomizationValueMap) {
    attackActions.forEach((action) => {
      const customization = customizations[action.id]
      if (customization?.name) action.name = customization.name
    })
  }
}
