import { Character } from '../DDB/Character'
import { Feature } from '../DDB/Feature'
import { TurnAction } from '../DDB/TurnAction'
import { ActionLevelMap, NumberMap, CheckMap } from '../Common/Interfaces'

import { calculateAverageDamageForTurns } from '../Data/ChartData'
import { URLUtility } from '../Common/Utility'
import { TurnActionEngine } from '../DDB/TurnActionEngine'
import { ActivationType } from '../DDB/CharacterJSON/Activation'
import { AttackAction } from '../DDB/AttackAction'

export class TurnEngine {
  cid: number
  checkedFeatures: CheckMap
  targetAC: number
  actionIdList: ActionLevelMap[]
  advantageOverrides: NumberMap
  turnActions: TurnAction[]
  bonusTurnActions: TurnAction[]
  reactionTurnActions: TurnAction[]
  allTurnActions: TurnAction[]
  elvenAccuracy: boolean
  actionRequiresSave: boolean
  bonusActionRequiresSave: boolean
  reactionRequireSave: boolean
  anyActionRequiresSave: boolean
  bonusActionFirst: boolean
  activeWeaponMasteryWeaponTypes: string[]
  simulatedAttackActions: AttackAction[]
  totalAttackCount: number
  totalBonusActionAttackCount: number
  averageDamageMaps: NumberMap[] = [new NumberMap(), new NumberMap(), new NumberMap(), new NumberMap()]

  constructor(
    character: Character,
    checkedFeatures: CheckMap,
    actionIdList: ActionLevelMap[],
    advantageOverrides: NumberMap,
    acs: number[],
    targetAC: number,
    simulatedAttackActions: AttackAction[] = []
  ) {
    this.targetAC = targetAC
    this.checkedFeatures = checkedFeatures
    this.actionIdList = actionIdList
    this.advantageOverrides = advantageOverrides
    this.cid = character.id()
    this.simulatedAttackActions = simulatedAttackActions

    const checkedFeatureList = character.features().filter((feature: Feature) => checkedFeatures[feature.id])

    const features = checkedFeatureList
      .filter((feature) => !feature.effects.isManeuver)
      .filter((feature) => !feature.requiresConcentration)
      .filter((feature) => !feature.isUnarmedFightingDamage)

    // Only inclue the first maneuver and the first concentration effect
    const maneuverFeatures = checkedFeatureList.filter((feature) => feature.effects.isManeuver)
    if (maneuverFeatures.length > 0) {
      features.push(maneuverFeatures[0])
    }

    const concentrationFeatures = checkedFeatureList.filter((feature) => feature.requiresConcentration)
    if (concentrationFeatures.length > 0) {
      features.push(concentrationFeatures[0])
    }

    const unarmedFightingFeatures = checkedFeatureList.filter((feature) => feature.isUnarmedFightingDamage)
    if (unarmedFightingFeatures.length > 0) {
      features.push(unarmedFightingFeatures[0])
    }

    this.bonusActionFirst = checkedFeatureList.some((feature) => feature.bonusActionFirst)

    const allActions = this.getCharacterActionsFromIDs(character, actionIdList)

    const actions = allActions.filter((action) => action.activation.activationType === ActivationType.ACTION)
    const bonusActions = allActions.filter((action) => action.activation.activationType === ActivationType.BONUS_ACTION)
    const reactions = allActions.filter((action) => action.activation.activationType === ActivationType.REACTION)

    this.totalBonusActionAttackCount =
      1 + features.reduce((sum, feature) => sum + (feature.effects.extraBonusAttacksThisAction || 0), 0)

    this.actionRequiresSave = actions.length > 0 && actions[0].attributes.requiresSavingThrow
    this.bonusActionRequiresSave = bonusActions.length > 0 && bonusActions[0].attributes.requiresSavingThrow
    this.reactionRequireSave = reactions.length > 0 && reactions[0].attributes.requiresSavingThrow
    this.anyActionRequiresSave = this.actionRequiresSave || this.bonusActionRequiresSave || this.reactionRequireSave
    this.turnActions = this.createTurnsFromActions(actions)
    this.bonusTurnActions = this.createTurnsFromActions(bonusActions)
    this.reactionTurnActions = this.createTurnsFromActions(reactions)
    this.allTurnActions = this.bonusActionFirst
      ? [this.bonusTurnActions, this.turnActions, this.reactionTurnActions].flat()
      : [this.turnActions, this.bonusTurnActions, this.reactionTurnActions].flat()

    const generalMasteries = features
      .map((feature) => feature.only.specificWeaponMasteryType)
      .filter((name) => name !== undefined)
      .map((name) => name || '')

    // Also look up nick
    const nickMasteries = features
      .map((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped)
      .filter((name) => name !== undefined)
      .map((name) => name || '')

    this.activeWeaponMasteryWeaponTypes = [...generalMasteries, ...nickMasteries]

    this.totalAttackCount = this.totalMeleeActionCount(character, features, this.allTurnActions) // + freeAction count

    const turnActionEngine: TurnActionEngine = new TurnActionEngine()
    turnActionEngine.applyFeaturesToTurnActions(features, this.allTurnActions, character)
    this.elvenAccuracy = features.some((thisFeature) => thisFeature.effects.rerollOnAdvantage)

    // Once this is done, some actions may have converted to bonus actions (Heightened Spell for example)
    const newBonusActionTurns = this.allTurnActions.filter(
      (turnAction) => turnAction.activation?.activationType === ActivationType.BONUS_ACTION
    )

    // And some may be actions (Nick)
    const newActionTurns = this.allTurnActions.filter(
      (turnAction) => turnAction.activation?.activationType === ActivationType.ACTION
    )

    // BONUS ACTIONS
    if (newBonusActionTurns.length > 0 || newActionTurns.length > 0) {
      for (const turn of newBonusActionTurns) {
        const index = this.turnActions.findIndex((action) => action.id === turn.id)
        if (index !== -1) {
          this.turnActions.splice(index, 1)
          this.bonusTurnActions.push(turn)
        }
      }

      for (const turn of newActionTurns) {
        const index = this.bonusTurnActions.findIndex((action) => action.id === turn.id)

        if (index !== -1) {
          this.bonusTurnActions.splice(index, 1)
          this.turnActions.push(turn)
        }
      }

      // We also have to renumber all of the actions and bonus actions. This is clumsy.
      let attackNumber = 1
      for (const turn of this.turnActions) {
        turn.attackNumber = attackNumber++
      }

      attackNumber = 1
      for (const turn of this.bonusTurnActions) {
        turn.attackNumber = attackNumber++
      }
    }

    const start = performance.now()
    for (const turn of this.allTurnActions) {
      let { advantage, disadvantage } = turn
      const overriddenAdvantage = this.advantageOverrides[turn.id]
      if (overriddenAdvantage !== undefined) {
        if (overriddenAdvantage >= 0) {
          advantage = overriddenAdvantage
        } else if (overriddenAdvantage < 0) {
          disadvantage = true
        }
      }
      turn.asSpecifiedDamageData = turn.calculateAverageDamageArray(acs, advantage, disadvantage)
      turn.extendedNormalDamageData = turn.calculateAverageDamageArray(acs, 0, false)
      turn.extendedDisadvantageDamageData = turn.calculateAverageDamageArray(acs, 0, true)
      turn.extendedAdvantageDamageData = turn.calculateAverageDamageArray(acs, this.elvenAccuracy ? 2 : 1, false)

      turn.extendedDamageDataACs = acs
    }

    this.averageDamageMaps = calculateAverageDamageForTurns(this.allTurnActions, acs)

    const end = performance.now()
    if (end - start > 250) {
      console.warn(`Damage data calculations took too long: ${end - start}ms`)
    }

    if (this.turnActions.length === 0 || this.turnActions[0].isWeapon() || this.turnActions[0].isUnarmed()) {
      while (this.turnActions.length < this.totalAttackCount) {
        this.turnActions.push(new TurnAction(this.turnActions.length + 1))
      }
    }

    if (this.bonusTurnActions.length > 0 || this.totalBonusActionAttackCount > 1) {
      while (this.bonusTurnActions.length < this.totalBonusActionAttackCount) {
        this.bonusTurnActions.push(new TurnAction(this.bonusTurnActions.length + 1))
      }
    }
  }

  shareableURL(): string {
    return URLUtility.shareURLForParams(this.cid, this.urlSearchParams())
  }

  private urlSearchParams(): URLSearchParams {
    const featureNumbers = Object.keys(this.checkedFeatures).filter(
      (key: string) => this.checkedFeatures[Number(key)] === true
    )
    const actionIdListObject = this.actionIdList.map((map) => [map.actionId, map.level])

    const jsonDictionary = {
      features: featureNumbers.join(','),
      actions: actionIdListObject.toString(),
      overrides: JSON.stringify(this.advantageOverrides),
      ac: String(this.targetAC)
    }
    return new URLSearchParams(jsonDictionary)
  }

  getCharacterActionsFromIDs(character: Character, actionIdList: ActionLevelMap[]): AttackAction[] {
    const actions: AttackAction[] = []
    const fullList = character
      .attackActions()
      .concat(character.attackBonusActions())
      .concat(character.damagingSpellActions()) // TODO… reactions
      .concat(this.simulatedAttackActions)

    for (const actionIdMap of actionIdList) {
      for (const attackAction of fullList) {
        const attrId = Number(attackAction.attributes.id)
        const actionId = Number(actionIdMap.actionId)

        if (attrId === actionId) {
          const newAttackAction = attackAction.copy()
          newAttackAction.turnLevel = actionIdMap.level
          actions.push(newAttackAction)
        }
      }
    }
    return actions
  }

  isNickEnabled(features: Feature[], turnActions: TurnAction[]): boolean {
    if (features.some((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped)) {
      const nickTurns = features
        .filter((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped)
        .map((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped || '')
        .filter((value) => value !== '')
      const lightWeaponTurns = turnActions.filter((turn) => turn.isLightWeapon)
      const baNickWeapons = lightWeaponTurns
        .filter((turn) => turn.isBonusAction())
        .filter((turn) => nickTurns.includes(turn.weaponType()))
      const aNickWeapons = lightWeaponTurns
        .filter((turn) => !turn.isBonusAction())
        .filter((turn) => nickTurns.includes(turn.weaponType()))
      return baNickWeapons.length > 0 || aNickWeapons.length > 0
    }
    return false
  }

  extraAttacksThisTurn(features: Feature[], turnActions: TurnAction[]): number {
    return (
      features.filter((feature) => feature.effects.extraAttackThisTurn).length +
      turnActions.filter((turn) => turn.hasFreeAction()).length
    )
  }

  extraAttacksThisAction(features: Feature[], nickEnabled: boolean): number {
    return features.filter((feature) => feature.effects.extraAttackThisAction).length + (nickEnabled ? 1 : 0)
  }

  totalMeleeActionCount(character: Character, features: Feature[], turnActions: TurnAction[]): number {
    const actionSurge: boolean = features.some((feature) => feature.effects.actionSurge)

    const nickEnabled = this.isNickEnabled(features, turnActions)
    const extraAttacksThisTurn = this.extraAttacksThisTurn(features, turnActions)
    const extraAttacksThisAction = this.extraAttacksThisAction(features, nickEnabled)
    const secondAttack: boolean = features.some((feature) => feature.effects.secondAttack)
    const extraTurn: boolean = features.some((feature) => feature.effects.extraTurn)

    let totalAttackCount = character.attackCount() + extraAttacksThisAction
    if (totalAttackCount === 1 && secondAttack) totalAttackCount = 2
    if (actionSurge) totalAttackCount *= 2
    totalAttackCount += extraAttacksThisTurn
    if (extraTurn) totalAttackCount *= 2
    return totalAttackCount
  }

  createTurnsFromActions(actions: AttackAction[]): TurnAction[] {
    if (!actions.length) return []
    const turns = []
    const addedTurnCount = 0
    for (let index = 0; index < actions.length; index++) {
      const action = actions[index]
      const turnAction = new TurnAction(index + addedTurnCount + 1, action)
      turns.push(turnAction)

      // WOrk in progress for multiple attacks
      //   if (turnAction.attributes().multipleAttacks && turnAction.attributes().effectCount) {
      //     const effectCount = turnAction.attributes().effectCount
      //     for (let i = 1; i < effectCount; i++) {
      //       // start at 1, we already added the first
      //       addedTurnCount++
      //       const newTurn = new TurnAction(index + addedTurnCount + 1, action.copy())
      //       turns.push(newTurn)

      //       // We have to create unique IDs
      //       // We have to mark the fake ones as dependent on the original somehow, then have the UI not show the number or delete button
      //       // We have to stop multiplying damage by effectCount
      //       // Maybe we get rid of effectCount for these and store the number in multipleAttacks? Is it redundant? Maybe we ditch chain lightning here, and just have it be a single property
      //       // Test Eldritch Blast and all the other +1s
      //       // Quickening Scorching Ray needs to quicken ALL of these... not just one of the beams
      //     }
      //   }
    }
    return turns
  }
}
