import React from 'react'
import { HStack, Icon, Text } from '@chakra-ui/react'
import { FaDiceD20 } from 'react-icons/fa'

export const DiamondIcon = ({ letter, color = 'AAA' }: { letter: string; color?: string }) => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0L20 10L10 20L0 10L10 0Z" fill={color} />

    <text x="50%" y="55%" textAnchor="middle" fill="#000" fontSize="10px" fontWeight="bold" dy=".3em">
      {letter}
    </text>
  </svg>
)

export function DPRCalcText({ smallSize, largeSize }: { largeSize: string; smallSize: string }) {
  return (
    <Text as="span">
      <Text as="span" fontWeight="bold" fontSize={largeSize}>
        DPR
      </Text>
      <Text as="span" css={{ fontVariant: 'small-caps' }} fontSize={smallSize}>
        calc
      </Text>
    </Text>
  )
}

export function DPRCalcLogo() {
  return (
    <HStack align={'center'} spacing={1}>
      <Icon as={FaDiceD20} />
      <DPRCalcText largeSize="xl" smallSize="lg" />
    </HStack>
  )
}
