import { Json } from '../../Common/Types'
import { JSONUtility, JsonValue } from '../../Common/JSONUtility'
import { ShareDataInterface } from '../../Common/Interfaces'
import { Creature } from './Creature'
import { Inventory } from './Inventory'
import { Campaign } from './Campaign'
import { Race } from './Race'
import { Spell, SpellGroup, SpellList } from './Spell'
import { Stat } from './Stat'
import { Actions } from './Actions'
import { Options } from './Options'
import { Modifiers } from './Modifiers'
import { Background } from './Background'
import { DDBClass } from './DDBClass'
import { Feat } from './Feat'
import { Decorations } from './Decorations'
import { CharacterValues } from './CharacterValues'

// This could have been just an interface, but i want to be able to prune data out as well
// This also helps prevent me from cheating and just reaching into the raw data

export class CharacterJSON {
  id: number
  name: string
  creatures: Creature[]
  decorations: Decorations
  inventory: Inventory[]
  background: Background
  classes: DDBClass[]
  options: Options
  modifiers: Modifiers
  feats: Feat[]
  characterValues: CharacterValues[]
  campaign?: Campaign
  actions: Actions
  race: Race
  spells: SpellGroup
  classSpells: Spell[]
  subclassSpells?: Spell[]
  stats: Stat[]
  overrideStats: Stat[]
  bonusStats: Stat[]
  shareData?: ShareDataInterface
  loadedFromCache: boolean // Only set by the backend. This is for 'hard coded' characters that can't be force updated.

  constructor(rootData: Json) {
    if (!rootData.race || rootData.race === null) {
      // Workaround if character isn't ready, avoid a crash.
      // TODO: Later filter this out
      rootData.race = { racialTraits: [] }
    }

    const logSize = false

    this.id = rootData.id
    // logSize && console.log(`id size: ${JSON.stringify(this.id).length}`)

    this.name = rootData.name
    // logSize && console.log(`name size: ${JSON.stringify(this.name).length}`)

    this.creatures = rootData.creatures.map((creature: Json) => new Creature(creature))
    // logSize && console.log(`creatures size: ${JSON.stringify(this.creatures).length}`)

    this.decorations = new Decorations(rootData.decorations)
    // logSize && console.log(`decorations size: ${JSON.stringify(this.decorations).length}`)

    this.inventory = rootData.inventory.map((inv: Json) => new Inventory(inv))
    // logSize && console.log(`inventory size: ${JSON.stringify(this.inventory).length}`)

    this.background = new Background(rootData.background)
    // logSize && console.log(`background size: ${JSON.stringify(this.background).length}`)

    this.classes = rootData.classes.map((c: Json) => new DDBClass(c))
    // logSize && console.log(`classes size: ${JSON.stringify(this.classes).length}`)

    this.options = new Options(rootData.options)
    // logSize && console.log(`options size: ${JSON.stringify(this.options).length}`)

    this.modifiers = new Modifiers(rootData.modifiers)
    // logSize && console.log(`modifiers size: ${JSON.stringify(this.modifiers).length}`)

    this.feats = rootData.feats.map((feat: Json) => new Feat(feat))
    // logSize && console.log(`feats size: ${JSON.stringify(this.feats).length}`)

    this.characterValues = rootData.characterValues.map((value: Json) => new CharacterValues(value))
    // logSize && console.log(`characterValues size: ${JSON.stringify(this.characterValues).length}`)

    this.campaign = rootData.campaign ? new Campaign(rootData.campaign) : undefined
    // logSize && this.campaign && console.log(`campaign size: ${JSON.stringify(this.campaign).length}`)

    this.actions = new Actions(rootData.actions)
    // logSize && console.log(`actions size: ${JSON.stringify(this.actions).length}`)

    this.race = new Race(rootData.race)
    // logSize && console.log(`race size: ${JSON.stringify(this.race).length}`)

    this.spells = new SpellGroup(rootData.spells)
    // logSize && console.log(`spells size: ${JSON.stringify(this.spells).length}`)

    this.classSpells = rootData.classSpells.map((list: Json) => new SpellList(list))
    // logSize && console.log(`classSpells size: ${JSON.stringify(this.classSpells).length}`, this.classSpells)

    if (rootData.subclassSpells) {
      // IF we've already loaded and merged these as a flat list, we can just use it straight
      this.subclassSpells = rootData.subclassSpells.map((spell: Json) => new Spell(spell))
    } else {
      this.subclassSpells = undefined
    }

    // logSize && console.log(`subclassSpells size: ${JSON.stringify(this.subclassSpells).length}`)

    this.stats = rootData.stats.map((stat: Json) => new Stat(stat))
    // logSize && console.log(`stats size: ${JSON.stringify(this.stats).length}`)

    this.overrideStats = rootData.overrideStats.map((stat: Json) => new Stat(stat))
    // logSize && console.log(`overrideStats size: ${JSON.stringify(this.overrideStats).length}`)

    this.bonusStats = rootData.bonusStats.map((stat: Json) => new Stat(stat))
    // logSize && console.log(`bonusStats size: ${JSON.stringify(this.bonusStats).length}`)

    this.shareData = rootData.shareData

    this.loadedFromCache = rootData.loadedFromCache || false

    if (logSize) {
      const sizeInBytes = JSON.stringify(rootData).length
      const prunedSizeInBytes = JSON.stringify(this.prunedData()).length

      console.log(
        `Character data ${this.name} reduced from ${sizeInBytes} to ${prunedSizeInBytes}, ${Number((1 - prunedSizeInBytes / sizeInBytes) * 100).toFixed(2)}% reduction`
      )
    }
  }

  prunedData(): Json {
    // Remove any undefined or null values
    return JSON.parse(JSONUtility.stringifyWithoutUndefined(this as unknown as JsonValue))
  }
}
