import React from 'react'
import { Box, Button, Center, Link, ListItem, OrderedList, Text } from '@chakra-ui/react'
import { Dispatcher } from '../Common/Types'
import { Character } from '../DDB/Character'

export function IncompleteCharacterSheet({
  character,
  id,
  setReload
}: {
  character: Character
  id: string
  setReload: Dispatcher<boolean>
}) {
  const url = `https://www.dndbeyond.com/characters/${id}`
  const manageUrl = `https://www.dndbeyond.com/characters/${id}/builder/class/manage`
  const accentColor = 'blue.200'

  return (
    <Center>
      <Box m="5%" mx="auto" maxWidth="90%">
        <Text>
          Failed to load {character.name()} from{' '}
          <Link color={accentColor} href={url} isExternal>
            {url}
          </Link>
          .
        </Text>
        <Text>The character is most likely not accessible because it is not complete.</Text>
        <Text py={4} />
        <Text>To complete your character:</Text>
        <OrderedList ps={4}>
          <ListItem>
            {`Visit your character's page `}
            <Link color={accentColor} href={manageUrl} isExternal>
              here
            </Link>
            .
          </ListItem>
          <ListItem>Make sure your character has a Species, Class, and Ability Scores set.</ListItem>
          <ListItem>
            <Text>
              Verify your
              <Link color={accentColor} href={url} isExternal>
                {' '}
                character
              </Link>{' '}
              properly loads on DDB.
            </Text>
          </ListItem>
          <ListItem>
            Come back to this page and tap{' '}
            <Button
              colorScheme={'blue'}
              size={'sm'}
              onClick={() => {
                setReload(true)
              }}
            >
              Reload
            </Button>{' '}
            to try to load your character again.
          </ListItem>
        </OrderedList>
      </Box>
    </Center>
  )
}
