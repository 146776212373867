import React from 'react'

import { Card, CardBody, CardHeader, Heading, Text, VStack, Avatar, HStack } from '@chakra-ui/react'
import { Character } from '../DDB/Character'
import { CharacterLoader } from '../Loader/CharacterLoader'

export function CompactCharacterCard({ character, dpr }: { character: Character; dpr: number }) {
  return (
    <Card borderRadius="lg" overflow="hidden" boxShadow="md" p={4} bg="gray.700" color="white">
      <CardHeader>
        <HStack>
          {character.avatarUrl() && <Avatar size="md" src={character.avatarUrl()} />}
          <VStack align="start" spacing={0}>
            <Heading size="sm">{character.name()}</Heading>
            <Text fontSize="xs">{character.classNamesForDisplay()}</Text>
          </VStack>
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack align="start" spacing={2}>
          <Text fontSize="sm">
            URL:{' '}
            <a href={character.ddbURL()} target="_blank" rel="noopener noreferrer">
              {character.ddbURL()}
            </a>
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            DPR: {dpr.toFixed(2)}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  )
}

export function UIPlaygroundPage() {
  const [character, setCharacter] = React.useState<Character | null>(null)

  React.useEffect(() => {
    async function loadCharacter(id: string) {
      return await CharacterLoader.load(id, false)
    }

    loadCharacter('The-Angry-Monk-B30ir').then((char) => {
      setCharacter(char)
    })
  }, [])

  if (!character) return <Text>Loading...</Text>

  return <CompactCharacterCard character={character} dpr={5} />
}
