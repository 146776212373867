import { SpellSchool } from '../Common/Constants'
import { Feature } from './Feature'
import { TurnAction } from './TurnAction'

export class FeatureConstraints {
  oncePerTurn: boolean = false // favored foe

  attackRoll: boolean = false
  bonusAction: boolean = false
  weapon: boolean = false
  magicWeapon: boolean = false
  rangedWeapon: boolean = false
  ammunitionWeapons: boolean = false
  bow: boolean = false // Arcane Shot
  crossbow: boolean = false // Crossbow Expert
  thrownWeapon: boolean = false
  meleeWeapon: boolean = false
  lightWeapon: boolean = false
  meleeAttack: boolean = false // melee weapon OR unarmed strikes
  heavyWeapon: boolean = false
  finesseWeapon: boolean = false
  weaponOrUnarmed: boolean = false
  finesseOrRangedWeapon: boolean = false // TODO FIX TYPO
  twoHandedOrVersatile: boolean = false // GWF
  singleWielding: boolean = false // Dueling
  offHand: boolean = false
  horns: boolean = false
  psychicBlade: boolean = false
  piercingDamage: boolean = false
  wildshapeAttack: boolean = false
  spell: boolean = false
  elementalDamageType?: string = undefined
  lightningOrThunderDamage: boolean = false
  fireOrRadiantDamage: boolean = false
  singleTargetSpell: boolean = false
  tollTheDead: boolean = false
  pactWeapon: boolean = false
  mundaneWeapon: boolean = false
  hexWeapon: boolean = false
  hexWarrior: boolean = false
  eldritchBlast: boolean = false
  oneBeam: boolean = false
  companionAttacks: boolean = false
  nonTwoHandedWeapon: boolean = false
  huntersMark: boolean = false
  sneakAttack: boolean = false
  cunningStrikePoison: boolean = false
  recklessAttack: boolean = false
  raging: boolean = false
  cantripsOrWeapons: boolean = false
  cantrip: boolean = false
  spellAttack: boolean = false
  spellWithSave: boolean = false
  divineSmite: boolean = false
  unarmed: boolean = false
  unarmedOrWildshape: boolean = false
  flurryOfBlows: boolean = false
  radiantSunBolt: boolean = false
  astralArms: boolean = false
  monkWeaponOrUnarmed: boolean = false
  spellSchool: SpellSchool | undefined = undefined
  appliesToCompanionAttacks: boolean = false

  maxSpellLevel: number = 0
  specificWeaponMasteryType?: string = undefined // TODO - could be used for stuff that only effects eldritch blast, etc

  stringForConstraints(): string | undefined {
    if (this.finesseWeapon) return 'finesse weapon'
    if (this.meleeWeapon) return `${this.heavyWeapon ? 'heavy ' : ''} melee weapon`
    if (this.lightWeapon) return 'light weapon'
    if (this.meleeAttack) return 'melee'
    if (this.weaponOrUnarmed) return 'weapon or unarmed strike'
    if (this.monkWeaponOrUnarmed) return 'monk weapon or unarmed strike'
    if (this.unarmedOrWildshape) return 'wildshape or unarmed strike'
    if (this.rangedWeapon) return 'ranged weapon'
    if (this.bow) return 'bow'
    if (this.crossbow) return 'crossbow'
    if (this.ammunitionWeapons) return 'ammunition weapon'
    if (this.thrownWeapon) return 'thrown weapon'
    if (this.spell) return 'spell'
    if (this.spellAttack) return 'spell attack'
    if (this.unarmed) return 'unarmed strike'
    if (this.pactWeapon) return 'pact weapon'
    if (this.hexWeapon) return 'hex weapon'
    if (this.psychicBlade) return 'psychic blades'
    if (this.wildshapeAttack) return 'wildshape attack'
    if (this.attackRoll) return 'attack roll'
    if (this.magicWeapon) return 'magic weapon'
    if (this.weapon) return 'weapon'
    return undefined
  }

  featureAppliesToTurn(feature: Feature, turn: TurnAction, features: Feature[], turnActions: TurnAction[]): boolean {
    const { only } = feature
    // Skip features that modify other features
    if (only.sneakAttack || only.cunningStrikePoison) return false
    if (only.divineSmite) return false
    if (only.huntersMark) return false

    // Other features
    if (only.specificWeaponMasteryType !== undefined && !turn.isWeaponType(only.specificWeaponMasteryType)) return false
    if (!feature.alsoAppliesToCompanionAttacks && !only.appliesToCompanionAttacks && turn.isCompanion()) return false // Careful - the only "also"…
    if (only.appliesToCompanionAttacks && !turn.isCompanion()) return false
    if (only.weapon && !turn.isWeapon()) return false
    if (only.unarmed && !turn.isUnarmed()) return false
    if (only.unarmedOrWildshape && !(turn.isUnarmed() || turn.isWildShapeAttack())) return false
    if (only.bow && !turn.isBow()) return false
    if (only.crossbow && !turn.isCrossbow()) return false
    if (only.finesseOrRangedWeapon && !(turn.isFinesseWeapon() || turn.isRangedWeapon())) return false
    if (only.ammunitionWeapons && !turn.isAmmunitionWeapon()) return false
    if (only.rangedWeapon && !turn.isRangedWeapon()) return false
    if (only.magicWeapon && !turn.isMagicWeapon()) return false
    if (only.meleeWeapon && !turn.isMeleeWeapon()) return false
    if (only.lightWeapon && !turn.isLightWeapon()) return false
    if (only.meleeAttack && !turn.isMeleeAttack()) return false
    if (only.mundaneWeapon && !turn.isMundaneWeapon()) return false
    if (only.cantripsOrWeapons && !(turn.isWeapon() || turn.isCantrip())) return false
    if (only.cantrip && !turn.isCantrip()) return false
    if (only.maxSpellLevel > 0 && (turn.isCantrip() || turn.spellLevel() > only.maxSpellLevel)) return false
    if (only.finesseWeapon && !turn.isFinesseWeapon()) return false
    if (only.horns && !turn.isHorns()) return false
    if (only.heavyWeapon && !turn.isHeavyWeapon()) return false
    if (only.spellAttack && !turn.isSpellAttack()) return false
    if (only.spellWithSave && !turn.isSpellWithSave()) return false
    if (only.attackRoll && !turn.isAttackRoll()) return false
    if (only.twoHandedOrVersatile && !(turn.isVersatileWeapon() || turn.isTwoHandedWeapon())) return false
    if (only.weaponOrUnarmed && !turn.isWeapon() && !turn.isUnarmed()) return false
    if (only.monkWeaponOrUnarmed && !turn.isMonkWeapon() && !turn.isUnarmed()) return false
    if (only.singleWielding && (turn.isTwoHandedWeapon() || turnActions.some((t) => t.isOffHand()))) return false
    if (only.offHand && !turn.isOffHand()) return false
    if (only.spell && !turn.isSpell()) return false
    if (only.spellSchool && !turn.isSpellSchool(only.spellSchool)) return false
    if (only.fireOrRadiantDamage && !(turn.isFireDamage() || turn.isRadiantDamage())) return false
    if (only.piercingDamage && !turn.isPiercingDamage()) return false
    if (only.lightningOrThunderDamage && !(turn.isThunderDamage() || turn.isLightningDamage())) return false
    if (only.pactWeapon && !turn.isPactWeapon()) return false
    if (only.hexWeapon && !turn.isHexWeapon()) return false
    if (only.nonTwoHandedWeapon && !turn.isNonTwoHandedWeapon()) return false
    if (only.eldritchBlast && !turn.isEldritchBlast()) return false
    if (only.singleTargetSpell && !turn.isSingleTargetSpell()) return false
    if (only.tollTheDead && !turn.isTollTheDead()) return false
    if (only.thrownWeapon && !turn.isThrownWeapon()) return false
    // if (effects.additionalMeleeRange && !turn.isMeleeWeapon()) return false
    if (only.hexWarrior && !features.some((f) => f.name === 'Hex Warrior')) return false
    if (only.flurryOfBlows && !(turn.isUnarmedBonusAction() && features.some((f) => f.name === 'Flurry of Blows')))
      return false
    if (only.radiantSunBolt && !turn.isRadiantSunBolt()) return false
    if (only.psychicBlade && !turn.isPsychicBlades()) return false
    if (only.astralArms && !turn.isAstralArms()) return false
    if (only.wildshapeAttack && !turn.isWildShapeAttack()) return false
    if (only.elementalDamageType && !turn.isDamageType(only.elementalDamageType)) return false
    if (only.recklessAttack && !features.some((feature) => feature.name === 'Reckless Attack')) return false
    if (only.raging && !features.some((feature) => feature.effects.raging)) return false
    if (only.bonusAction && !turn.isBonusAction()) return false

    return true
  }
}

// TODO - maybe move weaponStringForNotes here!
