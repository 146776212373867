import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Range } from '../CharacterJSON/Range'
import { Action } from '../CharacterJSON/Actions'

export class ActionParserRacial {
  static parse(actionParser: ActionParser, racialActions: Action[], attackActions: AttackAction[]) {
    for (const action of racialActions) {
      if (action.name.startsWith('Breath Weapon')) {
        const coneRange: Range = Range.makeConeAoeRange(15)
        const coneId = action.id
        const dc = actionParser.spellSaveDC
        const attackAction = actionParser.createSpellAttackWithSaveAction(coneId, action, coneRange, dc)
        attackActions.push(attackAction)

        const lineRange: Range = Range.makeLineAoeRange(30)
        const lineId = coneId + 1000001
        const lineAttackAction = actionParser.createSpellAttackWithSaveAction(lineId, action, lineRange, dc)
        attackActions.push(lineAttackAction)
      }
    }
  }
}
