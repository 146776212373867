import { Dice } from '../Dice'
import { Class } from '../../Common/Constants'
import { FeatureParserBase } from './FeatureParserBase'
import { ABILITY } from '../../Common/Constants'

export class FeatureParserRanger {
  static parse(parser: FeatureParserBase) {
    const thisClass = Class.RANGER
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const is2024Class = parser.is2024Class(thisClass)
    const classLevel = parser.classLevel(thisClass)

    if (name === 'Hunter’s Mark: Superior Hunter’s Prey') {
      only.oncePerTurn = true
      effects.applyHuntersMarkDamageTwice = true
      feature.name = 'Superior Hunter’s Prey'
      feature.notes = 'Deal Hunter’s Mark dmg to a 2nd creature once per turn'
      return true
    }

    if (feature.name === 'Foe Slayer' && is2024Class) {
      only.huntersMark = true
      effects.replacementDamageDice = Dice.Create(1, 10)
      feature.defaultEnabled = true
      feature.notes = 'Hunter’s Mark now adds 1d10 dmg per hit'
      return true
    }

    if (name === 'Foe Slayer' && !is2024Class) {
      if (isSubclassFeature) return feature.skip()
      const wisdomMod = character.modifierForAbility(ABILITY.WISDOM)
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      // TODO: This is actually TWO features (the other lets you add to hit), we are only doing one half of it.
      return true
    }

    if (feature.name === 'Precise Hunter') {
      only.huntersMark = true
      effects.advantage = true
      feature.defaultEnabled = true
      feature.notes = 'Advantage on attacks against targets marked with Hunter’s Mark'
      return true
    }

    if (name === 'Nature’s Veil') {
      if (requiredLevel) return feature.skip()

      only.oncePerTurn = true
      effects.advantage = true
      return true
    }

    if (name === 'Favored Foe' && action.dice) {
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.usesLimitedResource = true
      feature.requiresConcentration = true
      return true
    }

    if (name === 'Drake Companion: Summon') {
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6) // TODO… scale this with level
      feature.name = 'Drake Companion: Infused Strike' // TODO: Seems hacky, why not use the infused strike?
      return true
    }

    if (name === 'Stalker’s Flurry') {
      only.weapon = true
      effects.extraAttackThisTurn = true
      return true
    }

    if (name === 'Dread Ambusher') {
      only.oncePerTurn = true
      only.weapon = true
      feature.usesLimitedResource = true

      if (is2024Class) {
        effects.additionalDamageOnHitDice = Dice.Create(2, 6)
      } else {
        if (action.entityTypeId === undefined) return feature.skip() // isSubclassFeature
        effects.extraAttackThisAction = true
        effects.extraAttackFirstRoundDice = new Dice(action.dice)
        feature.notes = 'Extra attack first round with +' + effects.extraAttackFirstRoundDice!.diceString() + ' dmg'
      }

      return true
    }

    if (name === 'Beast of the Land') {
      only.appliesToCompanionAttacks = true
      only.oncePerTurn = true
      effects.nextAttacksHaveAdvantage = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6)

      feature.name = 'Land Beast Strike (Charge 20ft)'
      feature.notes = '+1d6 dmg to next Beast attack and knock prone'
      return true
    }

    if (name === 'Dreadful Strikes') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, classLevel >= 11 ? 6 : 4)
      feature.defaultEnabled = true
      return true
    }

    if (name === 'Bestial Fury' && !is2024Class) {
      effects.extraBonusAttacksThisAction = 1
      feature.notes = 'Extra beast attack this turn'
      return true
    }

    if (name === 'Bestial Fury' && is2024Class) {
      // TODO - technically we aren't enforcing hunters mark is up and running, but that's fine for now

      only.appliesToCompanionAttacks = true
      only.oncePerTurn = true
      effects.extraBonusAttacksThisAction = 1
      effects.additionalDamageOnHitDice = Dice.Create(1, classLevel === 20 ? 10 : 6)
      effects.addsHuntersMarkDamage = true
      feature.notes = 'Extra beast attack this turn with hunters mark damage'

      return true
    }
    if (name === 'Gathered Swarm') {
      if (isSubclassFeature) return feature.skip()
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, classLevel >= 11 ? 8 : 6)
      return true
    }

    if (name === 'Giant Killer' || name === 'Hoard Breaker') {
      if (isSubclassFeature) return feature.skip()
      only.oncePerTurn = true
      effects.extraAttackThisTurn = true // TODO Later it's technically an extra reaction for giant killer
      return true
    }

    if (name === 'Colossus Slayer') {
      if (isSubclassFeature) return feature.skip()
      only.oncePerTurn = true
      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 8)
      return true
    }

    if (name === 'Distant Strike') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.extraAttackThisAction = true
      return true
    }

    if (name === 'Planar Warrior') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 11 ? 2 : 1, 8)
      only.oncePerTurn = true
      return true
    }

    if (name === 'Slayer’s Counter') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.extraAttackThisTurn = true
      return true
    }

    if (name === 'Slayer’s Prey') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 6)
      return true
    }

    const nonBuffActions = [
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Beast of the Sea',
      'Beast of the Sky',
      'Beguiling Twist',
      'Canny (1st Level)',
      'Core Ranger Traits',
      'Defensive Tactics',
      'Deft Explorer',
      'Deft Explorer: Tireless',
      'Deft Explorer: Tireless (exhaustion)',
      'Drake Companion',
      'Drake Companion (Acid)',
      'Drake Companion (Cold)',
      'Drake Companion (Fire)',
      'Drake Companion (Lightning)',
      'Drake Companion: Command',
      'Dreadful Strike',
      'Dreadful Strike: Mass Fear',
      'Dreadful Strike: Sudden Strike',
      'Epic Boon',
      'Equipment',
      'Exceptional Training',
      'Expertise',
      'Extra Attack',
      'Favored Enemy',
      'Feral Senses',
      'Fey Reinforcements',
      'Fey Wanderer Spells',
      'Fighting Style',
      'Fighting Style feat',
      'Giants',
      'Gloom Stalker Magic',
      'Gloom Stalker Spells',
      'Hide in Plain Sight',
      'Hit Points',
      'Hunter’s Lore',
      'Hunter’s Mark',
      'Hunter’s Mark: Precise Hunter',
      'Hunter’s Prey',
      'Iron Mind',
      'Land’s Stride',
      'Mighty Swarm',
      'Misty Wanderer',
      'Mountain',
      'Natural Explorer',
      'Otherworldly Glamour',
      'Primal Companion',
      'Primal Companion: Restore Beast',
      'Primal Companion: Summon',
      'Primeval Awareness',
      'Proficiencies',
      'Ranger Archetype',
      'Ranger Subclass',
      'Relentless Hunter',
      'Roving',
      'Roving (6th Level)',
      'Shadowy Dodge',
      'Share Spells',
      'Spellcasting',
      'Superior Hunter’s Defense',
      'Superior Hunter’s Prey',
      'Swarming Dispersal',
      'Swarmkeeper Magic',
      'Temporary Hit Points',
      'Tireless',
      'Tireless (10th Level)',
      'Umbral Sight',
      'Vanish',
      'Weapon Mastery',
      'Writhing Tide'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
