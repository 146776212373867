import { Json } from '../../Common/Types'
import { GrantedFeat } from './Feat'

export class Background {
  hasCustomBackground: boolean
  definition?: BackgroundDefinition

  constructor(json: Json) {
    this.hasCustomBackground = json.hasCustomBackground
    this.definition = json.definition ? new BackgroundDefinition(json.definition) : undefined
  }
}

export class BackgroundDefinition {
  id: number
  entityTypeId: number
  name: string
  grantedFeats?: GrantedFeat[]

  constructor(json: Json) {
    this.entityTypeId = json.entityTypeId
    this.id = json.id
    this.name = json.name
    this.grantedFeats = json.grantedFeats ? json.grantedFeats.map((feat: Json) => new GrantedFeat(feat)) : undefined
  }
}
