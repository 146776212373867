import { Dictionary } from '../Common/Types'
import { DDBClass } from './CharacterJSON/DDBClass'
export class CharacterClass {
  className: string
  subclassName: string | null = null
  subclassId: number | null = null
  pactName?: string | null = null // for Warlock
  level: number
  id: number
  spellCastingAbility: number | null = null
  levelSpellSlots: number[] = []

  constructor(classData: DDBClass, classOptions: Dictionary[]) {
    const { definition, subclassDefinition, level } = classData
    const { spellRules, name, spellCastingAbilityId } = definition

    this.level = level
    this.className = name
    this.id = definition.id

    if (this.className === 'Warlock') {
      const classOption = classOptions.find((option) => option.definition.name.startsWith('Pact of the'))
      if (classOption) {
        this.pactName = classOption.definition.name
      }
    }

    if (subclassDefinition && 'name' in subclassDefinition) {
      this.subclassName = subclassDefinition.name
      this.subclassId = subclassDefinition.id
    }

    if (spellCastingAbilityId !== null) {
      this.spellCastingAbility = spellCastingAbilityId - 1
    }

    if (spellRules && spellRules.levelSpellSlots !== null) {
      this.levelSpellSlots = spellRules.levelSpellSlots[this.level]
    }
  }

  is2024Class(): boolean {
    const classIDsFrom2024 = [
      2190875, 2190876, 2190877, 2190878, 2190879, 2190880, 2190881, 2190882, 2190883, 2190884, 2190885, 2190886
    ]
    return classIDsFrom2024.includes(this.id)
  }

  classDisplayString(): string {
    const className = `${this.className}${this.is2024Class() ? '' : ' 2014'}`
    const subclass = this.subclassName ? ` (${this.subclassName}${this.pactName ? `, ${this.pactName}` : ''})` : ''
    return `${className} ${subclass}`.replace('  ', ' ')
  }

  highestLevelSpellSlot(): number {
    for (let i = this.levelSpellSlots.length - 1; i >= 0; i--) {
      if (this.levelSpellSlots[i] !== 0) {
        return i + 1
      }
    }
    return 0
  }
}
