import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserWeaponMastery {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action } = parser
    const { only, effects } = feature
    const { name, weaponType, displayName } = action
    if (feature.featureSource !== FeatureSource.WeaponMastery) return false

    feature.name = displayName
    only.specificWeaponMasteryType = weaponType

    if (name === 'Nick') {
      only.lightWeapon = true // When you make the extra attack of the Light property.
      only.bonusAction = true // instead of as a Bonus Action.
      only.specificWeaponMasteryType = undefined // just find the first light bonus action weapon…
      only.oncePerTurn = true // You can make this extra attack only once per turn.
      effects.convertBonusActionToAction = true // You can make it as part of the Attack action
      effects.extraAttackThisActionIfWeaponEquipped = weaponType // If you are wielding a light weapon in your off hand.
      feature.notes = `Your extra  bonus action Light weapon attack can be made with your action`
      feature.tooltip = `Nick requires you to have a weapon marked as "Dual Wield" in DDB.`
      return true
    }

    if (name === 'Graze') {
      effects.abilityScoreMinimumDamage = true
      feature.notes = `Deal ability mod dmg instead of 0 if you miss with ${weaponType}`
      // TODO - test with True Strike, which changes ability modifier. And what about Hexblade/pact weapons?
      return true
    }

    if (name === 'Cleave') {
      only.meleeAttack = true // If you hit a creature with a melee attack roll using this weapon,
      only.oncePerTurn = true // You can make this extra attack only once per turn.
      effects.isCleaveAttack = true
      effects.extraAttackThisTurn = true // you can make a melee attack roll with the weapon…
      effects.applyToNextAttack = true // against a second creature within 5 feet of the first that is also within your reach.
      effects.subduePositiveDamageModifier = true //  On a hit, the second creature takes the weapon’s damage, but don’t add your ability modifier to that damage unless that modifier is negative.

      // TODO - scale damage by previous weapon's chance of hitting
      // TODO - this will need to modify percentiles charts. Make it miss more often, and then appropriately scale down all the other values?
      // TODO - display hit percentage chance
      // TODO - always?

      feature.notes = `Extra attack with ${weaponType} on hit, but don't add ability mod to dmg`
      feature.name = `${feature.name}`
      return true
    }

    if (name === 'Topple') {
      // CAVEAT: This assumes the save fails. Otherwise we are modelling crazy dependency trees.
      only.weapon = true
      effects.nextAttacksHaveAdvantage = true
      feature.notes = `Target makes Con save or is knocked prone by ${weaponType}`
      return true
    }

    if (name === 'Vex') {
      only.weapon = true
      effects.advantage = true
      effects.applyToNextAttack = true
      feature.notes = `Gain advantage on attack roll after ${weaponType} attack`
      return true
    }

    if (name === 'Push') {
      feature.notes = 'Push a Large or smaller creature up to 10 feet away from yourself'
      return true
    }

    if (name === 'Sap') {
      feature.notes = 'Target has Disadvantage on its next attack roll'
      return true
    }

    if (name === 'Slow') {
      feature.notes = 'Reduce target speed by 10 feet'
      return true
    }

    feature.notes = 'Unknown weapon mastery'
    return false
  }
}
