import { ActionParser } from './ActionParser'
import { Action } from '../CharacterJSON/Actions'
import { AttackAction } from '../AttackAction'
import { AttackType, Class } from '../../Common/Constants'
import { Utility } from '../../Common/Utility'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'

export class ActionParserMonk {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]): boolean {
    const character = parser.character
    if (character.classLevel(Class.MONK) === 0) return false

    const name = action.name

    if (name === 'Searing Sunburst') {
      // Todo this is save or suck, not save for half
      for (let ki = 1; ki <= 3; ki++) {
        const attributes = {
          range: new Range(action.range),
          type: AttackType.SPELL,
          requiresSavingThrow: true,
          requiresAttackRoll: false,
          saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId! - 1),
          saveDcValue: parser.spellSaveDC
        }
        const dice = new Dice(action.dice)
        dice.diceCount *= ki
        const activation = new Activation(action.activation)
        const actionName = `${name} (${Utility.kiPointsString(ki)})`
        const sunburst = new AttackAction(
          action.id + ki,
          actionName,
          parser.spellAttackMod,
          dice,
          attributes,
          activation
        )

        attackActions.push(sunburst)
      }
    }

    if (name === 'Water Whip') {
      for (let ki = 2; ki <= character.maxKiPointsForMonkSpell(); ki++) {
        const attributes = {
          range: Range.makeSpellAttackRange(action.range.range),
          type: AttackType.SPELL,
          requiresSavingThrow: true,
          requiresAttackRoll: false,
          saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId! - 1),
          saveDcValue: parser.spellSaveDC
        }
        const dice = new Dice(action.dice)
        dice.diceCount += ki - 2
        const activation = new Activation(action.activation)
        const actionName = `${name} (${Utility.kiPointsString(ki)})`
        const whip = new AttackAction(action.id + ki, actionName, parser.spellAttackMod, dice, attributes, activation)
        attackActions.push(whip)
      }
    }

    if (name === 'Radiant Sun Bolt') {
      const unarmedDice = Dice.Create(1, character.monkDieSize())
      const attack = parser.createUnarmedStrikeAttackAction(
        character,
        name,
        parser.action,
        action.id,
        unarmedDice,
        1,
        true
      )
      attack.attributes = {
        ...attack.attributes,
        radiantSunBolt: true,
        effectCount: 1,
        effectCountLabel: 'Bolts'
      }
      attackActions.push(attack)
      return true
    }

    if (name === 'Arms of the Astral Self: Summon') {
      action.range.range = 10
      const attack = parser.createAoeSpellAction(name, action, parser.bonusAction, parser.spellSaveDC)
      attackActions.push(attack)
      return true
    }

    if (name === 'Fist of Unbroken Air') {
      for (let ki = 2; ki <= character.maxKiPointsForMonkSpell(); ki++) {
        const activation = new Activation(action.activation)
        const dice = new Dice(action.dice)
        dice.diceCount += ki - 2
        const actionName = `${name} (${Utility.kiPointsString(ki)})`
        const fist = parser.createUnarmedStrikeAttackAction(
          character,
          actionName,
          activation,
          action.id + ki - 2,
          dice,
          0
        )
        attackActions.push(fist)
      }
    }

    if (name === 'Quivering Palm') {
      const range = Range.makeWeaponRange(5)
      const attack = parser.createSpellAttackWithSaveAction(action.id, action, range, character.spellSaveDC())
      attackActions.push(attack)
      return true
    }

    if (name === 'Arms of the Astral Self (DEX/STR)') {
      const unarmedStrikeDice = Dice.Create(1, character.monkDieSize())
      const attack = parser.createWeaponAttackAction(character, name, action, 5, unarmedStrikeDice)
      attack.attributes.astralArms = true
      attack.attributes.subType = 'unarmed'
      attackActions.push(attack)
      return true
    }

    if (name === 'Arms of the Astral Self (WIS)') {
      const unarmedStrikeDice = Dice.Create(1, character.monkDieSize())
      const attack = parser.createWeaponAttackAction(character, name, action, 5, unarmedStrikeDice)
      attack.attributes.astralArms = true
      attack.attributes.subType = 'unarmed'
      attackActions.push(attack)
      return true
    }

    if (name === 'Sun Shield') {
      const attributes = {
        range: new Range(action.range),
        type: AttackType.SPELL,
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }

      const dice = Dice.flatAmountDie(5 + parser.wisMod)
      const activation = new Activation(action.activation)
      const attack = new AttackAction(action.id, name, parser.spellAttackMod, dice, attributes, activation)
      attackActions.push(attack)
      return true
    }

    if (name === 'Elemental Burst') {
      const attack = parser.createAoeSpellAction(name, action, parser.action, parser.spellSaveDC)
      attackActions.push(attack)
      return true
    }

    if (name === 'Touch of the Long Death') {
      for (let ki = 1; ki <= 10; ki++) {
        const range = Range.makeSpellAttackRange(0)
        const type = AttackType.SPELL
        const requiresSavingThrow = true
        const requiresAttackRoll = false
        const saveDcAbility = Utility.shortNameForAbilityID(action.saveStatId! - 1)
        const saveDcValue = parser.spellSaveDC
        const subType = 'unarmed'
        const attributes = { range, type, requiresSavingThrow, requiresAttackRoll, saveDcAbility, saveDcValue, subType }

        const dice = new Dice(action.dice)
        dice.diceCount *= ki
        const activation = new Activation(action.activation)
        const actionName = `${name} (${Utility.kiPointsString(ki)})`

        const newId = action.id + ki
        const longDeathAction = new AttackAction(newId, actionName, parser.spellAttackMod, dice, attributes, activation)
        longDeathAction.attributes.subType = 'unarmed'
        attackActions.push(longDeathAction)
        return true
      }
    }

    if (name === 'Augment Breath') {
      action.range.range = 60 // TODO: AOE cone
      const newName = 'Breath of the Dragon: Augment Breath'
      const attack = parser.createAoeSpellAction(newName, action, parser.action, parser.spellSaveDC)
      attackActions.push(attack)
      return true
    }

    if (name === 'Breath of the Dragon') {
      action.range.range = 20 // TODO: AOE cone
      const attack = parser.createAoeSpellAction(name, action, parser.action, parser.spellSaveDC)
      attackActions.push(attack)
      return true
    }

    if (name === 'Explosive Fury') {
      console.log('explosive fury not yet implemented', action.range)
      const attack = parser.createAoeSpellAction(name, action, parser.action, parser.spellSaveDC)
      attackActions.push(attack)
      return true
    }

    return false
  }
}
