import { Json } from '../../Common/Types'
import { Activation } from './Activation'

export class Options {
  class: Option[]
  feat: Option[]

  constructor(json: Json) {
    this.class = json.class.map((option: Json) => new Option(option))
    this.feat = json.feat.map((option: Json) => new Option(option))
  }
}

export class Option {
  componentId: number
  componentTypeId: number
  definition: OptionDefinition

  constructor(json: Json) {
    this.componentId = json.componentId
    this.componentTypeId = json.componentTypeId
    this.definition = new OptionDefinition(json.definition)
  }
}

export class OptionDefinition {
  id: number
  entityTypeId: number
  name: string
  activation?: Activation

  constructor(json: Json) {
    this.id = json.id
    this.entityTypeId = json.entityTypeId
    this.name = json.name
    this.activation = json.activation ? new Activation(json.activation) : undefined
  }
}
