import React, { useState } from 'react'
import { useToast, IconButton, Tooltip } from '@chakra-ui/react'
import { Dictionary } from '../Common/Types'
import { LinkIcon } from '@chakra-ui/icons'
import { Dispatcher } from '../Common/Types'

export const NewShareButton = ({
  data,
  setShareKey
}: {
  data: Dictionary
  setShareKey: Dispatcher<string | undefined>
}) => {
  const [loading, setLoading] = useState(false) // Button loading state
  const toast = useToast() // Chakra UI toast

  // shares/119016487.json
  // Handle API call
  const handleShare = async () => {
    setLoading(true)
    try {
      const response = await fetch('/api/create-share-link', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data })
      })

      if (!response.ok) {
        throw new Error('Failed to save data')
      }

      const result = await response.json()
      const shareUrl = `https://dprcalc.com/s/${result.slug}`
      setShareKey(result.key)
      await navigator.clipboard.writeText(shareUrl)

      toast({
        title: 'Share link copied to clipboard!',
        description: `${shareUrl}`,
        status: 'success',
        colorScheme: 'gray',
        position: 'top',
        duration: 3000,
        isClosable: true
      })
    } catch (err) {
      toast({
        title: 'Error creating link',
        description: (err as Error).message,
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip label={loading ? 'Creating share link…' : 'Share link to DPR analysis'}>
      <IconButton
        isLoading={loading}
        isDisabled={loading}
        spinner={<LinkIcon boxSize={5} ms={1} />}
        color={'white'}
        top={0}
        right={0}
        id={'reload'}
        aria-label="Reload character"
        onClick={handleShare}
        icon={<LinkIcon boxSize={5} ms={1} />}
      />
    </Tooltip>
  )
}
