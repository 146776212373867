export function LoadDPRTestSlugs(): string[] {
  return [
    'Artillerist-Rondo-QJbHA',
    'Weslocke-oEEBq',
    'Karlach-Lceue',
    'Reya-Greenleaf-wvrrR',
    'Fake-Bunny-w2jcf',
    'Laezel-SIuCD',
    'Flemin-9ex5Z',
    'Nameroc-WPb3b',
    'Gale-ZCE47',
    'Barnaby-ROFxs',
    'Soulknife-Rogue-iOsm5',
    'Sorc-iPmZd',
    'Shadowheart-rPy3B',
    'Vadania-Amakiir-HODhH',
    '2024-Dance-Bard-AO62x',
    '2024-Rogue-Soulknife-o4IGr',
    '2024-Cleric-Y0SXW',
    '2024-Zealot-Barb-RE4GG',
    '2024-Berserker-Barb-4wEzA',
    '2024-Valor-Bard-KmT3b',
    'D4-Ep-30-The-Needler-W2bDs',
    'D4-171-The-Baseline-y4RLr',
    'DnDShorts-Hexblade-uJAer',
    'D4-170-Crit-Fisher-20-eec5f',
    'Grappla-LQQCZ',
    '2024-Elemental-Glam-Bard-LUsT7',
    'Fighty-McStyles-WT4iE',
    '2024-Sea-Druid-L4Sqs',
    'Thoronn-2024-HKBP0',
    '2024-Light-Cleric-ZhXKI',
    '2024-Moon-Druid-Z0iZb',
    '2024-Evoker-Wiz-ZJHKU',
    '2024-Soulknife-Cunning-OPMkE',
    '2024-Rogue-Thief-47M2Z',
    '2024-Rogue-Assassin-LpFoj',
    '2024-Warlock-Familiars-8oNE7',
    '2024-Warlock-Archfey-gswSX',
    '2024-Warlock-Fiend-orAye',
    '2024-Evoker-Wiz-3-f6ojN',
    '2024-Battle-Master-Fighter-8bclM',
    '2024-Champion-Fighter-swpjV',
    '2024-EK-Fighter-jv6Bq',
    '2024-Psi-Warrior-Fighter-XwuWC',
    '2024-Weapon-Mastery-Fighter-k0hFc',
    '2024-Devotion-Paladin-4GD9M',
    '2024-Glory-Paladin-Gf8E6',
    '2024-Mercy-Monk-aKdnV',
    '2024-Open-Hand-Monk-cJhIH',
    '2024-Elements-Monk-GmZJg',
    '2024-Beast-Master-Ranger-ByUg4',
    '2024-Hunter-Ranger-poH2j',
    '2024-Gloomstalker-Ranger-3J3Hz',
    '2024-Fey-Wanderer-Ranger-sIePV',
    'The-Angry-Monk-byby1',
    'Bear-Hugger-HyCiI',
    '2024-Stars-Druid-p4Pu9',
    'Lucien-Blackfyre-UI8nj',
    'Niko---Death-Cleric-Dt3wj',
    'Reya-Dawnbringer-VhjR2',
    'The-Angry-Monk-EYtNI',
    'Nezza-FfgmR',
    'Tikram-Ainak-WI6GR',
    'Barnaby-Moonfall-z3smY',
    'Lucien-Blackfyre-9Ovzj',
    'Tikram-Ainak-t7mSg'
  ]
}
