export type WeaponProperty =
  | 'Two-Handed'
  | 'Heavy'
  | 'Range'
  | 'Finesse'
  | 'Versatile'
  | 'Light'
  | 'Thrown'
  | 'Ammunition'
  | 'Loading'
  | 'Ammunition (Firearms)'
  | 'Special'
  | 'Reach'
  | 'Nick'
  | 'Vex'
  | 'Cleave'
  | 'Graze'
  | 'Push'
  | 'Sap'
  | 'Topple'
  | 'Slow'

// A bit clumsy…
export const knownMasteries: WeaponProperty[] = [
  'Reach',
  'Nick',
  'Vex',
  'Cleave',
  'Graze',
  'Push',
  'Sap',
  'Topple',
  'Slow'
]

export class WeaponAttributes {
  isPolearm: boolean = false
  is2024Polearm: boolean = false // Quarterstaff, a Spear, or a weapon that has the Heavy and Reach properties
  isBow: boolean = false
  isCrossbow: boolean = false
  isRanged: boolean = false
  isOffHand: boolean = false
  isAttuned: boolean = false
  isHex: boolean = false
  isPact: boolean = false
  isCustomized: boolean = false
  isMonkWeapon: boolean = false
  isMagical: boolean = false

  weaponMastery?: WeaponProperty
  properties: WeaponProperty[] = []

  isTwoHanded(): boolean {
    return this.properties.includes('Two-Handed')
  }

  isHeavy(): boolean {
    return this.properties.includes('Heavy')
  }

  isRange(): boolean {
    return this.properties.includes('Range')
  }

  isFinesse(): boolean {
    return this.properties.includes('Finesse')
  }

  isVersatile(): boolean {
    return this.properties.includes('Versatile')
  }

  isLight(): boolean {
    return this.properties.includes('Light')
  }

  isThrown(): boolean {
    return this.properties.includes('Thrown')
  }

  isReach(): boolean {
    return this.properties.includes('Reach')
  }

  isNick(): boolean {
    return this.properties.includes('Nick')
  }

  isVex(): boolean {
    return this.properties.includes('Vex')
  }

  isCleave(): boolean {
    return this.properties.includes('Cleave')
  }

  isGraze(): boolean {
    return this.properties.includes('Graze')
  }

  isPush(): boolean {
    return this.properties.includes('Push')
  }

  isSap(): boolean {
    return this.properties.includes('Sap')
  }

  isTopple(): boolean {
    return this.properties.includes('Topple')
  }

  isSlow(): boolean {
    return this.properties.includes('Slow')
  }

  isAmmunition(): boolean {
    return this.properties.includes('Ammunition')
  }

  isLoading(): boolean {
    return this.properties.includes('Loading')
  }

  isAmmunitionFirearms(): boolean {
    return this.properties.includes('Ammunition (Firearms)')
  }

  isSpecial(): boolean {
    return this.properties.includes('Special')
  }
}
