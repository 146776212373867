import React from 'react'
import { Box, Button, Center, Link, ListItem, OrderedList, Text } from '@chakra-ui/react'
import { Dispatcher } from '../Common/Types'

export function FailureToLoadPage({ id, setReload }: { id: string; setReload: Dispatcher<boolean> }) {
  const url = `https://www.dndbeyond.com/characters/${id}`
  const permissionsUrl = `https://www.dndbeyond.com/characters/${id}/builder/home/basic`
  const accentColor = 'blue.200'

  return (
    <Center>
      <Box m="5%" mx="auto" maxWidth="90%">
        <Text>
          Failed to load character from{' '}
          <Link color={accentColor} href={url} isExternal>
            {url}
          </Link>
          .
        </Text>
        <Text>If that link loads your character, the character may be private.</Text>
        <Text py={4} />
        <Text>To change your character permissions:</Text>
        <OrderedList ps={4}>
          <ListItem>
            {`Visit your character's page `}
            <Link color={accentColor} href={permissionsUrl} isExternal>
              here
            </Link>
            .
          </ListItem>
          <ListItem>
            Scroll to the bottom to see the{' '}
            <Text as="span" fontWeight={'bold'}>
              Character Privacy
            </Text>{' '}
            section.
          </ListItem>
          <ListItem>
            Select{' '}
            <Text as="span" fontWeight={'bold'}>
              Public
            </Text>{' '}
            (anyone with the link can view your character).
          </ListItem>
          <ListItem>
            Come back to this page and tap{' '}
            <Button
              colorScheme={'blue'}
              size={'sm'}
              onClick={() => {
                setReload(true)
              }}
            >
              Reload
            </Button>{' '}
            to try to load your public character again. If the resulting page is blank, refresh one more time.
          </ListItem>
        </OrderedList>
      </Box>
    </Center>
  )
}
