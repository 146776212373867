import { Class } from '../../Common/Constants'
import { Dice } from '../Dice'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserSneakAttack {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action, character } = parser
    const name = feature.name
    const { only, effects } = feature

    if (!name.startsWith('Sneak Attack')) return false

    if (name === 'Sneak Attack') {
      const isSubclassFeature = action.entityTypeId === undefined
      if (isSubclassFeature) return feature.skip()
      only.finesseOrRangedWeapon = true
      only.weapon = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      effects.sneakAttackDice = effects.additionalDamageOnHitDice.copy()
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next hit with finesse or ranged weapon`
      return true
    }

    if (name === 'Sneak Attack: Assassinate') {
      const rogueLevel = character.classLevel(Class.ROGUE)
      only.sneakAttack = true
      effects.advantage = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(rogueLevel)
      feature.notes = `Advantage on attacks and +${rogueLevel} sneak attack damage`
      return true
    }

    if (name === 'Sneak Attack: Supreme Sneak (Cost: 1d6)') {
      only.sneakAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(-1, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      feature.notes = `Don't lose invisible condition after sneak attack`
      return true
    }
    if (name === 'Sneak Attack: Daze (Cost: 2d6)') {
      only.sneakAttack = true
      effects.additionalDamageOnHitDice = Dice.Create(-2, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      only.oncePerTurn = true
      feature.name = 'Devious Strike: Daze (Cost: 2d6)'
      feature.notes = 'Daze enemy on failed Con save'
      return true
    }

    if (name === 'Sneak Attack: Knock Out (Cost: 6d6)') {
      only.sneakAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(-6, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      effects.attackAfterSneakAttackAutoCrits = true
      effects.attackAfterSneakAttackHasAdvantage = true
      feature.name = 'Devious Strike: Knock Out (Cost: 6d6)'
      feature.notes = 'Knock enemy unconscious on failed Con save'
      return true
    }

    if (name === 'Sneak Attack: Obscure (Cost: 3d6)') {
      only.sneakAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(-3, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      effects.nextAttacksHaveAdvantage = true
      feature.name = 'Devious Strike: Obscure (Cost: 3d6)'
      feature.notes = 'Blind enemy on failed Dex save'
      return true
    }

    if (name === 'Sneak Attack: Poison (Cost: 1d6)') {
      only.sneakAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(-1, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      feature.name = 'Cunning Strike: Poison (Cost: 1d6)'
      feature.notes = 'Poison enemy on failed Con save'
      return true
    }

    if (name === 'Sneak Attack: Trip (Cost: 1d6)') {
      only.sneakAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(-1, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      effects.nextAttacksHaveAdvantage = true
      feature.name = 'Cunning Strike: Trip (Cost: 1d6)'
      feature.notes = 'Knock enemy prone on failed Dex save'
      return true
    }

    if (name === 'Sneak Attack: Withdraw (Cost: 1d6)') {
      only.sneakAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(-1, 6)
      effects.cunningStrikeDice = effects.additionalDamageOnHitDice.copy()
      feature.name = 'Cunning Strike: Withdraw (Cost: 1d6)'
      feature.notes = 'Move half speed after attack without opportunity attacks'
      return true
    }

    if (name === 'Sneak Attack: Poison (Envenom)') {
      only.cunningStrikePoison = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.name = 'Envenom Weapons'
      feature.notes = 'Add 2d6 poison damage to `Cunning Strike: Poison`'
      return true
    }

    if (name === 'Sneak Attack: Death Strike') {
      only.sneakAttack = true
      effects.damageMultiplier = 2
      feature.notes = 'Double damage Sneak Attack on first round (CON save)'
      // TODO - later, this and other similar features should do the math based on CON saves
      return true
    }

    return false
  }
}
