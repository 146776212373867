export enum FeatureSource {
  None,
  Class,
  Feat,
  Effect,
  Spell,
  RacialTrait,
  FightingStyle,
  ClassOption,
  Item,
  WeaponMastery,
  Ammunition
}

export const featureSourceNames: Record<FeatureSource, string> = {
  [FeatureSource.None]: 'none',
  [FeatureSource.Class]: 'class feature',
  [FeatureSource.Feat]: 'feat',
  [FeatureSource.Effect]: 'effect',
  [FeatureSource.Spell]: 'spell',
  [FeatureSource.RacialTrait]: 'racial trait',
  [FeatureSource.FightingStyle]: 'fighting style',
  [FeatureSource.ClassOption]: 'class option',
  [FeatureSource.Item]: 'item',
  [FeatureSource.WeaponMastery]: 'weapon mastery',
  [FeatureSource.Ammunition]: 'ammunition'
}
