import React from 'react'
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react'
import { theme } from '@chakra-ui/pro-theme'
import { Box } from '@chakra-ui/react'
// import { SpeedInsights } from '@vercel/speed-insights/react'
import { Analytics } from '@vercel/analytics/react'
import { CharacterPageTabs } from './UI/CharacterPageTabs'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { WelcomePage } from './UI/WelcomePage'
import { FAQPage } from './UI/FAQPage'
import { AboutPage } from './UI/AboutPage'
import { DPRAppThemeColors, DPRAppThemeComponents } from './Theme/Theme'
import { TestPage } from './UI/TestPage'
import { UIPlaygroundPage } from './UI/UIPlaygroundPage'

export function App() {
  // TODO - why is CheckBox/BaseStyle/ColorScheme not working?
  const proTheme = extendTheme(theme)
  const components = DPRAppThemeComponents()

  const extenstion = {
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false
    },
    colors: { ...proTheme.colors, brand: proTheme.colors.gray[700], ...DPRAppThemeColors() },
    components: components
  }

  const myTheme = extendTheme(extenstion, proTheme)

  return (
    <ChakraProvider theme={myTheme}>
      <Box fontSize="xl" alignContent={'center'}>
        <ColorModeScript initialColorMode="dark" />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/c/:id" element={<CharacterPageTabs />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="/uip" element={<UIPlaygroundPage />} />
            {/* {Todo: Add a 404 page, for now just do welcome page} */}
            <Route path="*" element={<WelcomePage />} />
          </Routes>
        </BrowserRouter>
        {/* <SpeedInsights /> */}
        <Analytics />
      </Box>
    </ChakraProvider>
  )
}
