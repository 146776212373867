import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'
import { Ammunition } from '../Ammunition'

export class FeatureParserAmmunition {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action } = parser
    const { only, effects } = feature
    if (feature.featureSource !== FeatureSource.Ammunition) return false

    const ammo = action as Ammunition
    const { bonus, dice, bloodseeker } = ammo

    // WARNING: Make sure when we customize the name, it doesn't break this
    if (ammo.name.includes('Arrow')) {
      only.bow = true
    } else if (ammo.name.includes('Crossbow Bolts')) {
      only.crossbow = true
    }

    only.ammunitionWeapons = true
    let damageDie
    if (dice) {
      damageDie = new Dice(dice)
    } else {
      damageDie = Dice.flatAmountDie(0)
    }

    if (bonus) {
      effects.additionalToHitDice = Dice.flatAmountDie(bonus)
      damageDie.fixedValue += bonus
    }

    if (bloodseeker) {
      effects.advantage = true
    }

    if (dice || bonus || bloodseeker) {
      feature.isBuff = true
      if (damageDie.diceString() !== '0') {
        effects.additionalDamageOnHitDice = damageDie.copy()
      }

      if (ammo.customizedName) {
        feature.name = ammo.customizedName
      }
      return true
    }

    return false
  }
}
