import { Json } from '../../Common/Types'

export enum PrivacyType {
  Private = 1,
  CampaignOnly = 2,
  Public = 3
}

export class CampaignCharacter {
  characterId: number
  characterName: string
  characterUrl: string
  isAssigned: boolean
  privacyType: number
  userId: number
  username: string

  constructor(json: Json) {
    this.characterId = json.characterId
    this.characterName = json.characterName
    this.characterUrl = json.characterUrl
    this.isAssigned = json.isAssigned
    this.privacyType = json.privacyType
    this.userId = json.userId
    this.username = json.username
  }

  isPublic(): boolean {
    return this.privacyType === PrivacyType.Public
  }
}

export class Campaign {
  id: number
  name: string
  link: string
  characters: CampaignCharacter[]

  constructor(json: Json) {
    this.id = json.id
    this.name = json.name
    this.link = json.link
    this.characters = json.characters.map((character: Json) => new CampaignCharacter(character))
  }
}
