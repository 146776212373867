import { Dice } from '../Dice'
import { Class } from '../../Common/Constants'
import { FeatureParserBase } from './FeatureParserBase'
import { ABILITY } from '../../Common/Constants'
import { SpellSchool } from '../../Common/Constants'

export class FeatureParserCleric {
  static parse(parser: FeatureParserBase) {
    const thisClass = Class.CLERIC
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)

    if (name === 'Channel Divinity: Touch of Death') {
      const extraDamage = 5 + 2 * character.classLevel(Class.CLERIC)
      only.meleeAttack = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(extraDamage)
      feature.notes = `+${extraDamage} dmg to next melee attack`
      return true
    }

    if (name === 'Potent Spellcasting') {
      const wisdomMod = character.modifierForAbility(ABILITY.WISDOM)
      only.cantrip = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(wisdomMod)
      feature.defaultEnabled = true
      feature.notes = `+${wisdomMod} dmg to next cantrip`
      return true
    }

    if (name === 'Blessed Strikes') {
      if (!action.dice) return feature.skip()

      only.cantripsOrWeapons = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      only.oncePerTurn = true
      feature.defaultEnabled = true
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg next hit for weapons or cantrips`
      return true
    }

    if (name === 'Corona of Light') {
      if (requiredLevel) return feature.skip()
      only.fireOrRadiantDamage = true
      only.spell = true
      effects.disadvantage = true
      feature.notes = 'Enemies have disadvantage on saves against fire or radiant spells'
      return true
    }

    if (name === 'War Priest: Bonus Attack') {
      only.weapon = true
      effects.convertActionToBonusAction = true
      only.oncePerTurn = true
      feature.usesLimitedResource = true
      feature.notes = 'Convert your weapon attack into a bonus action attack'
      return true
    }

    if (name === 'Invoke Duplicity: Distract' && action.id === 9414177) {
      // Strangely there are 2 different actions with the same name, just grab one.
      only.attackRoll = true
      effects.advantage = true
      return true
    }

    if (name === 'Invoke Duplicity: Shared Distraction') {
      only.attackRoll = true
      effects.advantage = true
      return true
    }

    if (name === 'Divine Strike') {
      if (isSubclassFeature) return feature.skip()
      //   if (feature.id !== 9414011) return feature.skip()

      only.cantripsOrWeapons = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 14 ? 2 : 1, 8)
      return true
    }

    if (name === 'Cloak of Shadows') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.advantage = true
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'War Priest') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.convertActionToBonusAction = true
      feature.usesLimitedResource = true
      feature.notes = 'Convert your weapon attack into a bonus action attack'
      return true
    }

    if (name === 'Emboldening Bond') {
      if (isSubclassFeature) return feature.skip()
      effects.additionalToHitDice = Dice.Create(1, 4)
      feature.alsoAppliesToCompanionAttacks = true
      feature.usesLimitedResource = true
      only.oncePerTurn = true
      feature.notes = '+1d4 to hit for next attack'
      return true
    }

    if (name === 'Reaper') {
      only.oncePerTurn = true
      only.cantrip = true
      only.singleTargetSpell = true
      effects.damageMultiplier = 2
      feature.notes = 'Single target cantrip targets a second creature'
      return true
    }

    if (name === 'Improved Reaper') {
      only.oncePerTurn = true
      only.spell = true
      only.singleTargetSpell = true
      only.spellSchool = SpellSchool.Necromancy
      only.maxSpellLevel = 5
      effects.damageMultiplier = 2
      feature.notes = 'Single target level 1-5 necromancy spell targets a second creature'
      return true
    }

    if (name === 'Channel Divinity: Guided Strike' || name === 'Channel Divinity: Guided Strike (Self)') {
      feature.name = 'Channel Divinity: Guided Strike'
      only.oncePerTurn = true
      effects.additionalToHitDice = Dice.flatAmountDie(10)
      return true
    }

    if (name === 'Channel Divinity: Destructive Wrath') {
      only.lightningOrThunderDamage = true
      only.oncePerTurn = true
      effects.maxDamage = true // TODO
      feature.name = 'Channel Divinity: Destructive Wrath (TODO)'
      feature.notes = 'Next lightning or thunder attack does max damage'
      return true
    }

    if (name === 'Channel Divinity: Path to the Grave') {
      effects.damageVulnerability = true
      only.oncePerTurn = true
      feature.notes = 'Next attack has vulnerability to all damage'
      return true
    }

    const nonBuffActions = [
      'Improved Blessed Strikes',
      'Warding Flare',
      'Channel Divinity',
      'Channel Divinity: Turn Undead',
      'Destroy Undead',
      'Divine Intervention',
      'Channel Divinity: War God’s Blessing',
      'Harness Divine Power',
      'Spellcasting',
      'Divine Domain',
      'Ability Score Improvement',
      'Proficiencies',
      'Hit Points',
      'Equipment',
      'Bonus Proficiencies',
      'Avatar of Battle',
      'Channel Divinity',
      'Channel Divinity: Divine Spark',
      'Channel Divinity: Turn Undead',
      'Channel Divinity: Sear Undead',
      'Channel Divinity: Guided Strike (Benefit Ally)',
      'Channel Divinity: War God’s Blessing',
      'Core Cleric Traits',
      'Spellcasting',
      'Divine Order',
      'Cleric Subclass',
      'Ability Score Improvement',
      'Sear Undead',
      '8: Ability Score Improvement',
      'Divine Intervention',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      'Epic Boon',
      'Greater Divine Intervention',
      'Guided Strike',
      'War Domain Spells',
      'War God’s Blessing',
      'Avatar of Battle',
      'Protector',
      'Channel Divinity',
      'Channel Divinity: Divine Spark',
      'Channel Divinity: Turn Undead',
      'Channel Divinity: Sear Undead',
      'Channel Divinity: Radiance of the Dawn',
      'Core Cleric Traits',
      'Spellcasting',
      'Divine Order',
      'Cleric Subclass',
      'Ability Score Improvement',
      'Sear Undead',
      '8: Ability Score Improvement',
      'Divine Intervention',
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      'Epic Boon',
      'Greater Divine Intervention',
      'Light Domain Spells',
      'Radiance of the Dawn',
      'Improved Warding Flare',
      'Protector',
      'Bonus Proficiency',
      'Inescapable Destruction',
      'Thaumaturge',
      'Channel Divinity: Preserve Life',
      'Blessed Healer',
      'Disciple of Life',
      'Life Domain Spells',
      'Preserve Life',
      'Supreme Healing',
      'Eyes of Night',
      'Vigilant Blessing',
      'Channel Divinity: Twilight Sanctuary',
      'Steps of Night',
      'Domain Spells',
      'Twilight Shroud',
      'Eyes of Night',
      'Vigilant Blessing',
      'Channel Divinity: Twilight Sanctuary',
      'Steps of Night',
      'Domain Spells',
      'Twilight Shroud',
      'Channel Divinity: Arcane Abjuration',
      'Arcane Initiate',
      'Spell Breaker',
      'Arcane Mastery',
      'Wrath of the Storm'
    ]
    if (nonBuffActions.includes(name)) return feature.skip()
    return false
  }
}
