import { Activation } from './CharacterJSON/Activation'
import { Dictionary } from '../Common/Types'
import { NON_BUFF_ACTIONS } from '../Common/Constants'
import { Utility } from '../Common/Utility'
import { FeatureSource } from './FeatureSource'
import { FeatureConstraints } from './FeatureConstraints'
import { FeatureEffects } from './FeatureEffects'
import { Character } from './Character'
import { Option } from './CharacterJSON/Options'
import * as Parsers from './FeatureParsers/FeatureParsers'
import { FeatureParserBase } from './FeatureParsers/FeatureParserBase'

export class Feature {
  only: FeatureConstraints = new FeatureConstraints()
  effects: FeatureEffects = new FeatureEffects()

  // General
  name: string
  featureSource: FeatureSource
  id: number
  tooltip: string | undefined = undefined
  isBuff: boolean = false
  activation?: Activation
  requiresConcentration: boolean = false
  isUnarmedFightingDamage: boolean = false // to enforce only one at a time

  usesLimitedResource: boolean = false // superiority dice, ki, etc (can't use in sustained round, only nova round). Actions have "limitedUse" object on them?
  notes: string = ''
  defaultEnabled: boolean = false
  snippet: string
  saveStatID: number | undefined = undefined // For later - for skills that require saves!
  bonusActionFirst: boolean = false
  rarityString: string | undefined = undefined

  private foundAndSkipped: boolean = false
  options: Option[]

  // TODO: where does this go…
  alsoAppliesToCompanionAttacks: boolean = false // applies to your attacks and companion attacks
  alsoAppliesToSpellAttackCompanionAttacks: boolean = false // like steel defender

  constructor(action: Dictionary, character: Character, featureSource: FeatureSource, options?: Option[]) {
    this.name = action.name
    this.activation = action.activation
    this.id = action.id
    this.requiresConcentration = action.requiresConcentration
    this.snippet = action.snippet
    this.featureSource = featureSource
    this.rarityString = action.rarityString
    this.options = (options ?? []).filter((opt) => opt.componentId === action.id)
    if (action.saveStatId && action.saveStatId !== null) this.saveStatID = action.saveStatId

    this.isBuff = this.parse(action, character)
    if (Utility.isDevelopment && this.isBuff && NON_BUFF_ACTIONS.includes(this.name)) {
      console.warn(`${character.name()}: ${this.name} is a buff but is in non-buff list`)
    }
  }

  parse(action: Dictionary, character: Character): boolean {
    const parser = new FeatureParserBase(this, action, character)

    if (Parsers.ExternalEffects.parse(parser)) return true
    if (Parsers.SpellEffects.parse(parser)) return true
    if (Parsers.Feat.parse(parser)) return true
    if (Parsers.RacialTrait.parse(parser)) return true
    if (Parsers.FightingStyle.parse(parser)) return true
    if (Parsers.ClassFeature.parse(parser)) return true
    if (Parsers.ItemEffect.parse(parser)) return true
    if (Parsers.ClassOptions.parse(parser)) return true
    if (Parsers.WeaponMastery.parse(parser)) return true
    if (Parsers.Ammunition.parse(parser)) return true

    return false
  }

  skip(): boolean {
    this.foundAndSkipped = true
    return false
  }

  skipped(): boolean {
    return this.foundAndSkipped
  }

  generatedNotes(): string {
    if (!this.notes) {
      this.notes = this.effects.stringForEffects(this.only)
    }

    return this.notes
  }
}
