import { Link, Box, Text, Tr, Td, Tooltip, useClipboard, IconButton, Spinner, useToast } from '@chakra-ui/react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Badge } from '@chakra-ui/react'
import React from 'react'
import { Range } from '../DDB/CharacterJSON/Range'
import { DamageData } from '../Common/Interfaces'
import { AddIcon, ExternalLinkIcon, LinkIcon, MinusIcon, RepeatIcon, DownloadIcon } from '@chakra-ui/icons'
import { AiOutlineReload } from 'react-icons/ai'
import { TurnEngine } from '../Data/TurnEngine'
import { Dictionary } from './Types'
import { InfoIcon } from '@chakra-ui/icons'
import { useTheme } from '@chakra-ui/react'

export function WrappingTd({ children }: { children: React.ReactNode }) {
  return (
    <Td whiteSpace="normal" wordBreak="break-word">
      {children}
    </Td>
  )
}

export function IconTooltip({ tooltip }: { tooltip?: string }) {
  const theme = useTheme()
  const tooltipElement = tooltip ? (
    <Tooltip label={tooltip}>
      <InfoIcon fontSize="sm" ms={1} color={theme.colors.gray[400]} />
    </Tooltip>
  ) : null
  return tooltipElement
}

export function TableActionButton({
  id,
  value,
  add,
  onClick
}: {
  id: string
  value: string
  add: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <IconButton
      bgColor={'brand'}
      color={'white'}
      id={id}
      value={value}
      aria-label="Add attack"
      h={'24px'}
      minH={'24px'}
      w={'24px'}
      minW={'24px'}
      onClick={onClick}
      icon={add ? <AddIcon boxSize="3" /> : <MinusIcon boxSize="3" />}
    />
  )
}

export function BigReloadButton({
  reloading,
  onClick
}: {
  reloading: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <IconButton
      position="absolute"
      bgColor={'brand'}
      color={'white'}
      top={0}
      right={0}
      id={'reload'}
      aria-label="Reload character"
      h="36px"
      w="36px"
      maxW="36px"
      maxH="36px"
      onClick={onClick}
      icon={<Spinner as={AiOutlineReload} size="md" thickness={'0'} speed={reloading ? '1s' : '0s'} />}
    />
  )
}

export function ReloadButton({
  reloading,
  onClick
}: {
  reloading: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <Tooltip label="Reload character">
      <IconButton
        color={'white'}
        top={0}
        right={0}
        id={'reload'}
        aria-label="Reload character"
        onClick={onClick}
        isLoading={reloading}
        icon={<RepeatIcon boxSize={6} />}
      />
    </Tooltip>
  )
}

// TODO can be done without passing in a turn engine lol
export function ShareButton({ turnEngine }: { turnEngine: TurnEngine }) {
  const shareURL = turnEngine.shareableURL()
  const toast = useToast()

  const handleShareButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(shareURL)
      toast({
        title: 'Success',
        description: 'Sharing URL copied to clipboard.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
        colorScheme: 'gray'
      })
    } catch (err) {
      console.log('Failed to copy: ', err)
      toast({
        title: 'Error',
        description: 'Failed to copy sharing URL',
        status: 'error',
        duration: 3000,
        isClosable: true,
        colorScheme: 'red'
      })
    }
  }

  return (
    <Tooltip label="Share link to DPR analysis">
      <IconButton
        color={'white'}
        top={0}
        right={0}
        id={'reload'}
        aria-label="Reload character"
        onClick={handleShareButtonClick}
        icon={<LinkIcon boxSize={5} ms={1} />}
      />
    </Tooltip>
  )
}

export function LinkButton({ url }: { url: string }) {
  return (
    <Tooltip label="Open character sheet">
      <Link href={url} isExternal>
        <ExternalLinkIcon boxSize={6} />
      </Link>
    </Tooltip>
  )
}

export function SpacerRow() {
  return (
    <Tr>
      <Td>
        <Box boxSize={0} />
      </Td>
    </Tr>
  )
}

export function StackedTextBox({ title, subtitle }: { title: string; subtitle?: string }) {
  if (title === 'Self' && subtitle) {
    title = subtitle
    subtitle = ''
  }
  return (
    <Box alignItems="left" padding={0}>
      <Text>{title}</Text>
      {subtitle && <Text fontSize="sm">{subtitle}</Text>}
    </Box>
  )
}

export function BasicTableRow({ name, value }: { name: string; value: string }) {
  if (value === null || value === 'None') return null

  return (
    <Tr>
      <Td style={{ fontWeight: 'bold' }} valign="top">
        {name}
      </Td>
      <WrappingTd>{value}</WrappingTd>
    </Tr>
  )
}

export function RangeText({ range }: { range: Range }) {
  if (!range) {
    return <Text />
  }

  let rangeString = range.origin
  let notesString = undefined

  if (range.origin === 'Weapon') {
    if (range.longRange !== range.range) {
      rangeString = `${range.range} (${range.longRange})`
    } else {
      rangeString = `${range.range} ft`
    }
  } else if (range.origin === 'Spell Attack') {
    if (range.longRange !== range.range) {
      rangeString = `${range.range} (${range.longRange})`
    } else {
      rangeString = `${range.range} ft`
    }
  } else if (range.origin === 'Ranged') {
    rangeString = `${range.rangeValue} ft`
  }

  if (range.aoeType && range.aoeValue) {
    notesString = `${range.aoeValue} ft ${range.aoeType}`
  }

  if (range.aoeType && range.aoeSize) {
    if (parseInt(range.aoeType) === 5) {
      rangeString = `${range.aoeSize} ft Aura`
    } else if (parseInt(range.aoeType) === 1) {
      rangeString = `${range.aoeSize} ft Cone`
    } else {
      console.warn('Unknown AOE type', range.aoeType)
    }
  }

  //   console.log('range', range, rangeString, notesString)
  return <StackedTextBox title={rangeString} subtitle={notesString} />
}

export function DamageCell({ damageData }: { damageData: DamageData }) {
  const diceString = damageData ? damageData.expression : ''
  const { onCopy } = useClipboard(diceString)

  return (
    <Td>
      <Tooltip label={diceString}>
        <Box onClick={onCopy} cursor="pointer">
          {damageData && damageData.average >= 0 ? damageData.average.toFixed(2) : ''}
        </Box>
      </Tooltip>
    </Td>
  )
}

export function CopyDataButton(data: Dictionary) {
  const jsonString = JSON.stringify(data, null, 2) // Convert Dictionary to JSON string

  const { onCopy } = useClipboard(jsonString)

  return (
    <Tooltip label="Download test data">
      <IconButton
        color={'white'}
        top={0}
        right={0}
        id={'download'}
        aria-label="Download character data"
        onClick={onCopy}
        icon={<DownloadIcon boxSize={6} />}
      />
    </Tooltip>
  )
}

export function AccordionView({ title, children, ...props }: { title: string; children: React.ReactNode }) {
  return (
    <Accordion allowToggle pb={2}>
      <AccordionItem bgColor={'bg.surface'}>
        {({ isExpanded }) => (
          <>
            <AccordionButton>
              <AccordionIcon />
              <Text textTransform="uppercase" fontWeight="bold" ps={2} textColor={'gray.400'}>
                {title}
              </Text>
            </AccordionButton>
            <AccordionPanel pb={2}>
              <Box {...props}>{isExpanded && children}</Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}

export function BasicBadge({ text }: { text: string }) {
  return (
    <Badge
      color="white"
      bgColor="brand"
      borderRadius="full"
      px={3}
      py={0}
      maxH="24px"
      alignItems="center"
      justifyContent="center"
    >
      {text}
    </Badge>
  )
}
