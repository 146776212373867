import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'
import { Action } from '../CharacterJSON/Actions'
import { Weapon } from '../Weapon'

export class ActionParserWeaponFeat {
  static parse(actionParser: ActionParser, featActions: Action[], weapons: Weapon[], attackActions: AttackAction[]) {
    const character = actionParser.character
    const poleStrike: Action | undefined = featActions.find((feat) => feat.name === 'Pole Strike')
    const pamBonus: Action | undefined = featActions.find((feat) => feat.name === 'Polearm Master - Bonus Attack')

    if (pamBonus || poleStrike) {
      const strikeId = poleStrike ? poleStrike.id : pamBonus!.id

      for (const weapon of weapons) {
        if ((pamBonus && weapon.attributes.isPolearm) || (poleStrike && weapon.attributes.is2024Polearm)) {
          const polearmAttackAction = AttackAction.CreateFromWeapon(weapon, character)
          const name = `${pamBonus ? 'Polearm Master Bonus' : 'Pole Strike '} (${weapon.name})`

          const toHit = polearmAttackAction.attackMod
          const dice = Dice.Create(1, 4, polearmAttackAction.dice.fixedValue)
          const attributes = { ...weapon.weaponAttributes() }
          if (attributes.isThrown) {
            // The only thrown polearm is a spear, give it 5ft melee range
            attributes.range = Range.makeWeaponRange(5)
          }

          attributes.id = attributes.id + strikeId
          const ba = Activation.BonusAction()
          const pamBonusAttack = new AttackAction(attributes.id, name, toHit, dice, attributes, ba)
          attackActions.push(pamBonusAttack)
        }
      }
    }
  }
}
