import { Json } from '../../Common/Types'

export class RacialTraitDefinition {
  id: number
  name: string

  constructor(json: Json) {
    this.id = json.id
    this.name = json.name
  }
}

export class RacialTrait {
  definition: RacialTraitDefinition

  constructor(json: Json) {
    this.definition = new RacialTraitDefinition(json.definition)
  }
}

export class Race {
  fullName: string
  baseRaceName: string
  racialTraits: RacialTrait[]

  constructor(json: Json) {
    this.fullName = json.fullName
    this.baseRaceName = json.baseRaceName
    this.racialTraits = json.racialTraits.map((trait: Json) => new RacialTrait(trait))
  }
}
