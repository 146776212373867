import { ABILITY } from '../../Common/Constants'
import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserMetamagic {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, character } = parser
    if (!feature.name.startsWith('Metamagic')) return false
    const { only, effects } = feature

    only.spell = true
    feature.usesLimitedResource = true
    const name = feature.name

    if (name.includes('Heightened Spell')) {
      only.oncePerTurn = true
      only.spellWithSave = true
      effects.disadvantage = true
      feature.notes = 'Disadvantage on saves against next spell'
      return true
    }

    if (name.includes('Quickened Spell')) {
      only.oncePerTurn = true
      effects.convertActionToBonusAction = true
      feature.notes = 'Next 1 action spell costs 1 bonus action'
      return true
    }

    if (name.includes('Seeking Spell')) {
      only.spellAttack = true
      only.oncePerTurn = true
      effects.advantage = true
      feature.notes = 'Next spell attack has advantage'
      return true
    }

    if (name.includes('Twinned Spell')) {
      only.singleTargetSpell = true
      only.oncePerTurn = true
      effects.damageMultiplier = 2
      feature.notes = 'Next single target spell has second target'
      return true
    }

    if (name.includes('Empowered Spell')) {
      only.oncePerTurn = true
      only.spell = true
      const chaMod = character.modifierForAbility(ABILITY.CHARISMA)
      effects.rerollDamageDice = chaMod
      feature.notes = `Reroll up to ${chaMod} damage dice for next spell`
      return true
      // When you roll damage for a spell, you can spend 1 Sorcery Point to reroll a number of the damage dice up to your Charisma modifier (minimum of one), and you must use the new rolls.
    }

    const nonBuffActions = ['Metamagic: Subtle Spell', 'Metamagic: Transmuted Spell']
    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
