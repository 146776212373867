import { DictionaryCache, NumberCache } from '../Common/Cache'
import { Dictionary } from '../Common/Types'
import * as ClockworkMod from './ClockworkDice.js'

const averageDamageCache = new NumberCache()
const diceCache = new NumberCache()
const statsCache = new DictionaryCache()

export class DiceCalc {
  static average(expression: string) {
    if (averageDamageCache.has(expression)) {
      return averageDamageCache.get(expression)
    }

    const value = this.evaluate(expression)
    const average = value.average()

    if (isNaN(average)) {
      console.error(`A) Average is NaN for expression: ${expression}`)
      averageDamageCache.set(expression, 0)
      return 0
    }
    averageDamageCache.set(expression, average)
    return average
  }

  static evaluateStatistics(expression: string): Dictionary | undefined {
    if (statsCache.has(expression)) {
      return statsCache.get(expression)
    }

    const values = this.evaluate(expression)
    const average = values.average()
    const percentiles = values.percent()

    if (isNaN(average)) {
      console.error(`B) Average is NaN for expression: ${expression}`)
      averageDamageCache.set(expression, 0)
      return { average: 0, percentiles: {}, expression }
    }

    const stats: Dictionary = { average, percentiles, expression }

    statsCache.set(expression, stats)
    return stats
  }

  static evaluate(expression: string) {
    if (diceCache.has(expression)) {
      return diceCache.get(expression)
    }

    try {
      const value = ClockworkMod.evalDice(expression)
      diceCache.set(expression, value)
      return value
    } catch (error) {
      if (error instanceof TypeError) {
        console.error(`Cannot evaluate dice string [${expression}) due to TypeError: ${error.message}`)
        return 0
      }

      throw error
    }
  }
}
