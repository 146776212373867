import { Activation } from './CharacterJSON/Activation'
import { Spell } from './Spell'
import { Utility } from '../Common/Utility'
import { ABILITY_NAMES, Class, ABILITY, FIGHTING_STYLES } from '../Common/Constants'
import { AttackAction } from './AttackAction'
import { Weapon } from './Weapon'
import { CharacterClass } from './CharacterClass'
import { CharacterFeat } from './Feat'
import { WeaponMastery } from './WeaponMastery'
import { Creature } from './CharacterJSON/Creature'
import { Dictionary } from '../Common/Types'
import { ActionParser } from './ActionParsers/ActionParser'
import { FightingStyle } from './FightingStyle'
import { Character } from './Character'
import { CustomizationValues } from './CustomizationValues'
import { Ammunition } from './Ammunition'
import { Feature } from './Feature'
import { AbilityScoreParser } from './AbilityScoreParser'
import { FeatureLoader } from './FeatureLoader'
import { ShareDataInterface, SpellSource } from '../Common/Interfaces'
import { Inventory, InventoryDefinition } from './CharacterJSON/Inventory'
import { Campaign } from './CharacterJSON/Campaign'
import { CharacterJSON } from './CharacterJSON/CharacterJSON'
import { Feat as FeatJSON } from './CharacterJSON/Feat'
import { DDBClass } from './CharacterJSON/DDBClass'
import { Modifier } from './CharacterJSON/Modifiers'
import { Spell as SpellJSON } from './CharacterJSON/Spell'
import { Feat } from './CharacterJSON/Feat'
import { Option } from './CharacterJSON/Options'

export type CustomizationValueMap = { [key: string]: CustomizationValues }

export class CharacterParser {
  name: string
  classes: CharacterClass[]
  features: Feature[]
  race: string

  attackActions: AttackAction[]
  extraAttackActions: AttackAction[]
  hasShieldEquipped: boolean = false
  hasOffHand: boolean = false
  id: number
  avatarUrl: string
  attackCount: number = 1

  feats: CharacterFeat[]
  weaponMasteries: WeaponMastery[]
  spells: Spell[]
  fightingStyles: FightingStyle[]
  weapons: Weapon[]

  abilityScores: number[]
  proficiencyBonus: number

  highestLevelSpellSlot: number
  spellcastingAbilityModifier: number
  spellcastingStatId: number
  spellAttackModifier: number
  creatures: Creature[] = []
  totalLevel: number
  backgroundId: number
  campaign?: Campaign
  shareData?: ShareDataInterface
  loadedFromCache: boolean

  // For development purposes only
  characterJsonForDevelopment?: CharacterJSON

  constructor(characterData: CharacterJSON) {
    // Load basics

    this.name = characterData.name
    this.id = characterData.id
    this.avatarUrl = characterData.decorations.avatarUrl
    this.backgroundId = characterData.background?.definition?.id || 0
    this.race = characterData.race.fullName || characterData.race.baseRaceName
    this.loadedFromCache = characterData.loadedFromCache
    this.classes = this.loadRealClasses(characterData.classes, characterData.options.class)
    this.totalLevel = this.calculateTotalLevel(this.classes)
    this.highestLevelSpellSlot = this.calculateHighestLevelSpellSlot(this.classes)
    this.proficiencyBonus = this.calculateProficiencyBonus()
    this.feats = this.loadFeats(characterData.feats, characterData.modifiers.feat, characterData.options.feat)
    this.weaponMasteries = this.loadWeaponMasteries(characterData.modifiers.feat)
    this.abilityScores = AbilityScoreParser.loadAbilityScores(characterData)
    this.campaign = characterData.campaign

    // Load custom character values
    const customizations: CustomizationValueMap = this.loadCustomizations(characterData)

    this.hasOffHand = this.hasOffhandWeapon(characterData.inventory, customizations)
    this.fightingStyles = this.loadFightingStyles(characterData)

    // Spellcasting mods
    const pb = this.proficiencyBonus
    this.spellcastingStatId = this.calculateSpellcastingStatId()
    this.spellcastingAbilityModifier = this.calculateSpellcastingAbilityModifier(this.spellcastingStatId)
    this.spellAttackModifier = pb + this.spellcastingAbilityModifier

    // Equipment
    const character = new Character(this) // TODO - When it it safe to create this?
    this.hasShieldEquipped = character.isShieldEquipped(characterData.inventory)

    this.weapons = this.loadWeapons(character, characterData.inventory, customizations)

    const ammunition = this.loadAmmunition(characterData.inventory)

    // Spells
    const spellSources: SpellSource[] = this.loadSpellSources(
      characterData.spells,
      characterData.classSpells,
      characterData.subclassSpells
    )
    this.spells = this.loadSpells(character, spellSources, customizations)

    const spellIDsToPrune: number[] = []
    this.features = FeatureLoader.loadAllFeatures(
      characterData,
      this.weaponMasteries,
      this.feats,
      this.fightingStyles,
      ammunition,
      this.spells,
      spellIDsToPrune,
      character
    )

    this.creatures = characterData.creatures

    const actionParser = new ActionParser(character)
    this.extraAttackActions = actionParser.parse(
      characterData,
      this.weapons,
      this.creatures,
      this.spells,
      customizations,
      spellIDsToPrune
    )
    // Now that we have all spellIDsToPrune, we can remove them from the spells list
    this.spells = this.spells.filter((spell) => !spellIDsToPrune.includes(spell.id))

    this.attackActions = this.loadAttackActions(character, this.weapons, this.extraAttackActions)
    this.applyWeaponMasteriesToAttackActions(this.attackActions, this.weaponMasteries)
    this.applyWeaponMasteriesToAttackActions(this.extraAttackActions, this.weaponMasteries)

    this.attackCount = this.countAttacksPerTurn(characterData.modifiers.class)

    // Share Data for UI
    this.shareData = characterData.shareData

    if (Utility.isDevelopment) {
      this.characterJsonForDevelopment = characterData
    }
  }

  hasOffhandWeapon(inventoryData: Inventory[], customizations: CustomizationValueMap): boolean {
    return inventoryData.some((itemData: Inventory) => {
      const { definition, equipped, id } = itemData
      const { damage, canEquip } = definition
      return damage !== null && canEquip && equipped && id in customizations && customizations[id].isOffhand
    })
  }

  calculateHighestLevelSpellSlot(classes: CharacterClass[]): number {
    const highestLevelSpellSlots = classes.map((characterClass) => characterClass.highestLevelSpellSlot())
    return Math.max(...highestLevelSpellSlots)
  }

  countAttacksPerTurn(data: Modifier[]) {
    let extraAttackCount: number = 0
    for (const classFeature of data) {
      if (classFeature.subType === 'extra-attacks' && classFeature.isGranted) {
        extraAttackCount = Math.max(extraAttackCount, classFeature.value)
      }
    }

    return 1 + extraAttackCount
  }

  loadSpellSources(spells: Dictionary, classSpells: Dictionary[], subclassSpells?: SpellJSON[]) {
    const spellSources: SpellSource[] = []

    for (const charClass of classSpells) {
      spellSources.push({ spells: charClass.spells, forceKnown: false })
    }

    subclassSpells && spellSources.push({ spells: subclassSpells, forceKnown: true })
    spellSources.push({ spells: spells.class, forceKnown: false })
    spellSources.push({ spells: spells.spells, forceKnown: false })
    spellSources.push({ spells: spells.race, forceKnown: true })
    spellSources.push({ spells: spells.feat, forceKnown: true })
    spellSources.push({ spells: spells.background, forceKnown: true })

    return spellSources
  }

  modifierForAbilityIndex(attrIndex: number) {
    const score = this.abilityScores![attrIndex]
    return Utility.modifierForScore(score)
  }

  scoreForAbility(ability: ABILITY) {
    const attrIndex = ABILITY_NAMES.indexOf(ability)
    return this.abilityScores![attrIndex]
  }

  modifierForAbility(ability: ABILITY) {
    const score = this.scoreForAbility(ability)
    return Utility.modifierForScore(score)
  }

  calculateProficiencyBonus(): number {
    return 1 + Math.ceil(this.totalLevel / 4)
  }

  is2024Class(name: Class): boolean {
    return this.classForClassName(name)?.is2024Class() || false
  }

  loadSpells(character: Character, spellSources: SpellSource[], customizations: CustomizationValueMap): Spell[] {
    const spells: Spell[] = []
    for (const spellSource of spellSources) {
      const spellList = spellSource.spells
      if (spellList) {
        for (const spellData of spellList) {
          const spell = new Spell(character, spellData, customizations)
          if (spells.some((s) => s.name === spell.name)) {
            // Skip duplicate spells
            continue
          }

          if (spell.name === 'Crown of Stars') {
            spell.activation = Activation.BonusAction()
          }

          if (spell.isPrepared || spellSource.forceKnown) {
            if (spell.id in customizations) {
              const spellCustomization: CustomizationValues = customizations[spell.id]
              if (spellCustomization.name) {
                spell.displayName = spellCustomization.name
              }
            }

            spells.push(spell)
          }
        }
      }
    }

    return spells
  }

  classForClassName(name: Class): CharacterClass | undefined {
    return this.classes!.find((characterClass) => String(characterClass.className) === String(name))
  }

  classLevel(name: Class): number {
    return this.classForClassName(name)?.level || 0
  }

  calculateSpellcastingStatId(): number {
    for (const characterClass of this.classes!) {
      if (characterClass.spellCastingAbility) {
        return characterClass.spellCastingAbility + 1
      }
    }

    // Fallback - find highest mental stat. This is a hack, but useful for magic initiate.
    const int = this.abilityScores[3]
    const wis = this.abilityScores[4]
    const cha = this.abilityScores[5]

    if (int > wis && int > cha) return 4
    if (wis > int && wis > cha) return 5
    return 6
  }

  calculateSpellcastingAbilityModifier(statId: number): number {
    const score = this.abilityScores![statId - 1]
    return Utility.modifierForScore(score)
  }

  loadCustomizations(characterData: CharacterJSON): CustomizationValueMap {
    const map: CustomizationValueMap = {}

    for (const valueData of characterData.characterValues) {
      let customValues: CustomizationValues = map[valueData.valueId]
      if (customValues === undefined) {
        customValues = new CustomizationValues(characterData.name, valueData.valueId)
        map[valueData.valueId] = customValues
      }

      customValues.addValue(valueData.valueId, valueData.typeId, valueData.value)
    }
    return map
  }

  loadAmmunition(inventoryData: Inventory[]): Ammunition[] {
    const ammo: Ammunition[] = []
    for (const inventoryItemData of inventoryData) {
      const definitionData: InventoryDefinition = inventoryItemData.definition
      const isEquipped = definitionData.canEquip === true && inventoryItemData.equipped === true
      const isAmmo = definitionData.type === 'Ammunition'
      if (isEquipped && isAmmo) {
        ammo.push(new Ammunition(definitionData))
      }
    }
    return ammo
  }

  loadWeapons(character: Character, inventoryData: Inventory[], customizations: CustomizationValueMap): Weapon[] {
    const weapons: Weapon[] = []
    for (const inventoryItemData of inventoryData) {
      const definitionData = inventoryItemData.definition
      const weaponBehaviors: Dictionary[] = definitionData.weaponBehaviors
      const hasDamage = definitionData.damage || weaponBehaviors.some((behavior) => 'damage' in behavior)
      const isEquipped = definitionData.canEquip === true && inventoryItemData.equipped === true
      const isWeapon = (definitionData.filterType === 'Weapon' || hasDamage) && definitionData.type !== 'Ammunition'

      if (isWeapon && isEquipped) {
        weapons.push(new Weapon(character, inventoryItemData, customizations))
      }
    }
    return weapons
  }

  loadFightingStyles(characterData: CharacterJSON): FightingStyle[] {
    const fightingStyles: FightingStyle[] = []

    for (const classOptionData of [
      characterData.feats,
      characterData.options.class,
      characterData.options.feat
    ].flat()) {
      const definitionData = classOptionData.definition
      const optionName = definitionData.name
      if (FIGHTING_STYLES.includes(optionName)) {
        const fightingStyle = new FightingStyle(definitionData)
        fightingStyles.push(fightingStyle)
      }
    }

    return fightingStyles
  }

  calculateTotalLevel(classes: CharacterClass[]): number {
    return classes.reduce((totalLevel, characterClass) => totalLevel + characterClass.level, 0)
  }

  loadRealClasses(classes: DDBClass[], options: Option[]): CharacterClass[] {
    return classes.map((classData: DDBClass) => new CharacterClass(classData, options))
  }

  loadFeats(feats: Feat[], modifiers: Modifier[], options: Option[]): CharacterFeat[] {
    return feats.map((feat: FeatJSON) => new CharacterFeat(feat, modifiers, options))
  }

  loadWeaponMasteries(modifiers: Modifier[]): WeaponMastery[] {
    return modifiers
      .filter((modifier) => modifier.type === 'weapon-mastery')
      .map((modifier) => new WeaponMastery(modifier))
  }

  abilityScoreForIndex(index: number): number {
    return this.abilityScores[index]
  }

  classAnalyticsNames(): Dictionary[] {
    return this.classes.map((characterClass) => {
      return {
        className: characterClass.className,
        subclassName: characterClass.subclassName ?? '',
        level: characterClass.level
      }
    })
  }

  applyWeaponMasteriesToAttackActions(attackActions: AttackAction[], masteries: WeaponMastery[]) {
    attackActions.forEach((attack) => {
      const mastery = masteries.find((mastery) => attack.attributes.weaponType === mastery.weaponType)
      attack.attributes.weaponMastery = mastery ? mastery.name : undefined
    })
  }

  loadAttackActions(character: Character, weapons: Weapon[], extraAttackActions: AttackAction[]): AttackAction[] {
    const allActions: AttackAction[] = weapons.map((weapon) => AttackAction.CreateFromWeapon(weapon, character))
    const attackActions: AttackAction[] = allActions.filter((action) => !action.attributes.isOffHand)
    attackActions.push(...extraAttackActions.filter((action) => action.activation.usesAction()))
    return attackActions
  }
}
