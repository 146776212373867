import { Json } from '../../Common/Types'
import { Activation } from './Activation'
import { Range } from './Range'
import { Dice } from '../Dice'

export class Action {
  name: string
  id: number
  componentId: number
  requiredLevel?: number
  range: Range
  dice?: Dice
  activation: Activation
  saveStatId?: number
  abilityModifierStatId?: number
  entityTypeId?: string
  kiPoints?: number
  upcastLevel?: number

  constructor(json: Json) {
    this.name = json.name
    this.id = parseInt(json.id)
    this.range = new Range(json.range)
    this.dice = json.dice ? new Dice(json.dice) : undefined
    this.activation = new Activation(json.activation)
    this.saveStatId = json.saveStatId
    this.abilityModifierStatId = json.abilityModifierStatId
    this.entityTypeId = json.entityTypeId // Used for isSubclassAction
    this.componentId = json.componentId

    this.requiredLevel = json.requiredLevel ? parseInt(json.requiredLevel) : undefined
    this.kiPoints = json.kiPoints
    this.upcastLevel = json.upcastLevel
  }
}

export class Actions {
  class: Action[]
  feat: Action[]
  item: Action[]
  race: Action[]

  constructor(json: Json) {
    this.class = json.class.map((action: Json) => new Action(action))
    this.feat = json.feat.map((action: Json) => new Action(action))
    this.race = json.race.map((action: Json) => new Action(action))
    this.item = json.item ? json.item.map((action: Json) => new Action(action)) : []
  }
}
