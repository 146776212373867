import React from 'react'

import { Thead, Tr, Th, Td, Flex } from '@chakra-ui/react'
import { ActionLevelMap } from '../../Common/Interfaces'
import { Dispatcher } from '../../Common/Types'
import { AttackAction } from '../../DDB/AttackAction'
import { TABLE_HEADER_COLOR } from '../CharacterSheet'
import { AttackRow } from '../Controls/AttackRow'
import { IconTooltip } from '../../Common/UIComponents'
import { AddWeaponButton } from '../../DDB/WhatIf/AddWeaponButton'
import { Character } from '../../DDB/Character'
import { Weapon } from '../../DDB/Weapon'
import { WeaponProperty } from '../../DDB/WeaponAttributes'

export function AttackTable({
  character,
  title,
  attacks,
  actionIdList,
  setActionIdList,
  activationType,
  showRange,
  tooltip,
  selectedWeapons,
  setSelectedWeapons,
  filterProperties
}: {
  character: Character
  title: string
  attacks: AttackAction[]
  actionIdList: ActionLevelMap[]
  setActionIdList: Dispatcher<ActionLevelMap[]>
  activationType: number
  showRange: boolean
  tooltip: string | null
  selectedWeapons?: Weapon[]
  setSelectedWeapons?: Dispatcher<Weapon[]>
  filterProperties?: WeaponProperty[]
}) {
  if (attacks.length === 0) {
    return null
  }

  // This is redundant since we pass in the right list, but good to filter out stuff just in case
  const filteredAttacks = attacks.filter(
    (attack: { activation: { activationType: number } }) => attack.activation.activationType === activationType
  )

  const selectedWeaponArray = Array.from(selectedWeapons || [])
  const simAttackActions = selectedWeaponArray.map((weapon) => AttackAction.CreateFromWeapon(weapon, character))
  simAttackActions.forEach((action) => (action.simulated = true))
  filteredAttacks.push(...simAttackActions)

  const showAddButton = false
  return (
    <Thead>
      <Tr textColor={TABLE_HEADER_COLOR} whiteSpace="normal" wordBreak="break-word">
        <Th fontSize={'medium'} pt={4} verticalAlign="bottom">
          <Flex align="center">
            {title}
            {tooltip && <IconTooltip tooltip={tooltip} />}
          </Flex>
        </Th>
        <Th verticalAlign="bottom">Range</Th>
        <Th verticalAlign="bottom">To Hit</Th>
        <Th verticalAlign="bottom">Damage</Th>
      </Tr>
      {filteredAttacks.map((attack: AttackAction) => (
        <AttackRow
          key={`${attack.attributes.id}-${attack.name}`}
          attack={attack}
          actionIdList={actionIdList}
          setActionIdList={setActionIdList}
          level={0}
          showRange={showRange}
        />
      ))}
      {showAddButton && selectedWeapons && setSelectedWeapons && (
        <Tr>
          <Td>
            <AddWeaponButton
              character={character}
              filterProperties={filterProperties || []}
              selectedWeapons={selectedWeapons}
              setSelectedWeapons={setSelectedWeapons}
            />
          </Td>
        </Tr>
      )}
    </Thead>
  )
}
