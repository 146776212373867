import { Dice } from './Dice'
import { InventoryDefinition } from './CharacterJSON/Inventory'

export class Ammunition {
  name: string
  customizedName: string | undefined = undefined
  id: number
  bonus: number | undefined = undefined
  dice: Dice | undefined = undefined
  bloodseeker: boolean = false
  restriction: string | undefined = undefined
  rarityString: string | undefined = undefined

  constructor(ammunition: InventoryDefinition) {
    this.name = ammunition.name

    this.id = ammunition.id
    this.rarityString = ammunition.rarity.toLowerCase().replace(' ', '')

    if (ammunition.type !== 'Ammunition') {
      console.error('Trying to create Ammo from non-ammo!')
      return
    }

    // Hack but no other identifying data except ID is passed down
    this.bloodseeker = this.name === 'Bloodseeker Arrows'

    // Shared logic with Weapon.
    for (const grantedMod of ammunition.grantedModifiers) {
      const type = grantedMod.type
      const subtype = grantedMod.subType
      if (type === 'bonus' && subtype === 'magic') {
        if (!this.bonus) this.bonus = 0
        this.bonus += grantedMod.value
      }

      if (type === 'damage' && grantedMod.dice) {
        this.dice = new Dice(grantedMod.dice)
      }
    }

    // TODO - the IDs don't match?
    // if (this.id in customizations) {
    //   const customizationValues: CustomizationValues = customizations[this.id]
    //   if (customizationValues.name) {
    //     this.customizedName = customizationValues.name
    //   }
    // }
  }
}
