import { ActionParser } from './ActionParser'
import { Action } from '../CharacterJSON/Actions'
import { AttackAction } from '../AttackAction'
import { Dice } from '../Dice'
import { Dictionary } from '../../Common/Types'
import { ABILITY, Class } from '../../Common/Constants'

export class ActionParserRanger {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]): boolean {
    const character = parser.character
    if (character.classLevel(Class.RANGER) === 0) return false

    const name = action.name

    if (name === 'Drake Companion') {
      const toHit = 3 + parser.proficiencyBonus

      const level = character.classLevel(Class.RANGER)
      const diceCount = 1 + (level >= 7 ? 1 : 0) + (level >= 15 ? 1 : 0)
      const dice = Dice.Create(diceCount, 6, parser.proficiencyBonus)
      const attack = new AttackAction(
        action.id,
        `${name}: Bite`,
        toHit,
        dice,
        parser.companionAttrs,
        parser.bonusAction
      )
      attackActions.push(attack)

      return true
    }
    const beastTypes: Dictionary = {
      'Beast of the Land': { dice: [8, 2], name: 'Beast Strike (Land)' },
      'Beast of the Sea': { dice: [6, 2], name: 'Beast Strike (Sea)' },
      'Beast of the Sky': { dice: [4, 3], name: 'Beast Strike (Sky)' }
    }

    const beast = beastTypes[action.name]
    if (beast) {
      const wisMod = character.modifierForAbility(ABILITY.WISDOM)
      const dice = Dice.Create(1, beast.dice[0], beast.dice[1] + wisMod)
      const attack = new AttackAction(
        action.id,
        beast.name,
        parser.spellAttackMod,
        dice,
        parser.companionAttrs,
        parser.bonusAction
      )
      attack.attributes.spellAttackModCompanion = true
      attackActions.push(attack)
      return true
    }

    return false
  }
}
