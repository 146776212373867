import { track } from '@vercel/analytics'
import { Dictionary } from './Types'
import { Character } from '../DDB/Character'

export const PAGE_WELCOME = 'welcome'
export const PAGE_CHARACTER = 'character'
export const PAGE_ABOUT = 'about'
export const PAGE_FAQ = 'faq'

const PAGE_VIEW_EVENT = 'page_view'
const PAGE_VIEW_EVENT_KEY = 'page'

export function TrackPageView(page: string) {
  track(PAGE_VIEW_EVENT, {
    [PAGE_VIEW_EVENT_KEY]: page
  })
}

export function TrackCharacter(character: Character) {
  const levelMap: Dictionary[] = character.classAnalyticsNames()
  const sortedLevelMap = levelMap.sort((a, b) => a.totalLevel - b.totalLevel)

  const analyticsDictionary = {
    name: character.name(),
    id: character.id(),
    level: character.totalLevel()
  } as Record<string, string | number | boolean | null>

  for (let i = 0; i < sortedLevelMap.length; i++) {
    analyticsDictionary[`Class ${i + 1}`] = sortedLevelMap[i].className
    analyticsDictionary[`Subclass ${i + 1}`] = sortedLevelMap[i].subclassName
    analyticsDictionary[`Level ${i + 1}`] = sortedLevelMap[i].level
  }

  track('character', analyticsDictionary)
}
