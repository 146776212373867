import { CharacterJSON } from '../DDB/CharacterJSON/CharacterJSON'
import { CharacterParser } from '../DDB/CharacterParser'
import { Character } from '../DDB/Character'

export class CharacterLoader {
  static async load(id: string, reload: boolean = false): Promise<Character | null> {
    const cache = reload ? 'reload' : 'force-cache'
    try {
      let response = await fetch(`/api/c?cid=${id}`, { cache })
      if (!response.ok) {
        // Let's try one more time, without cache…
        response = await fetch(`/api/c?cid=${id}`, { cache: 'no-cache' })
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
      }
      const data = await response.json()
      const characterJSON = new CharacterJSON(data.data)
      if (!characterJSON) {
        throw new Error(`Could not load character JSON for ${id}`)
      }
      const characterParser = new CharacterParser(characterJSON)
      return new Character(characterParser)
    } catch (error) {
      console.error('Error fetching character:', error)
      return null
    }
  }
}
